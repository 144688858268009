import { RPBlockComponentType } from "~/types/views";
import styles from "./PostBlockComponents.module.scss";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import PostBlockComponent from "../PostBlockComponent/PostBlockComponent";

type Props = {
  postBlockComponents: RPBlockComponentType[];
  link: string;
};

const PostBlockComponents = ({ postBlockComponents, link }: Props) => {
  return (
    <div>
      {postBlockComponents.map(function (
        postBlockComponent,
        postBlockComponentIndex
      ) {
        return (
          <div className={styles.ctn} key={postBlockComponentIndex}>
            <div
              key={postBlockComponentIndex}
              className={styles.postBlockComponent}
            >
              <Link
                className={styles.editButton}
                to={`${link}/postBlockComponent/${postBlockComponent.id}`}
              >
                <IconButton aria-label="delete" size="small">
                  <BuildCircleIcon fontSize="inherit" />
                </IconButton>
              </Link>
              <PostBlockComponent
                postBlockComponent={postBlockComponent}
                link={`${link}/postBlockComponent/${postBlockComponent.id}`}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PostBlockComponents;
