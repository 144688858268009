import { HomeType, StoreType } from "~/types/views";
import Block from "~/views/shared/Block/Block";
import Slideshow from "~/views/shared/Slideshow/Slideshow";

type Props = {
  entityModel: string;
  entityId: string;
  entityView: HomeType | StoreType;
};

const HomeAndStoreView = ({ entityView, entityModel, entityId }: Props) => {
  return (
    <>
      <Slideshow
        key={entityId}
        entityModel={entityModel}
        entityId={entityId}
        highlights={entityView.highlights}
      />

      {entityView.blocks.map((block, blockIndex) => {
        return (
          <Block
            entityModel={entityModel}
            entityId={entityId}
            block={block}
            key={blockIndex}
          />
        );
      })}
    </>
  );
};

export default HomeAndStoreView;
