import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "~/app/store";
import { copyEntityToDestination } from "~/api/entitiesAPI";
import { PuxadinhoError } from "~/shared/PuxadinhoError";
import { showSnackbar } from "~/features/interface/Snackbar/snackbarSlice";
import { showErrorDialog } from "../ErrorDialog/errorDialogSlice";
import { fetchList } from "~/features/data/Lists/listsSlice";

interface CopyState {
  model?: string;
  id?: string;
  parentModel?: string;
  parentEntityId?: string;
}

const initialState: CopyState = {};

export const copySlice = createSlice({
  name: "copy",
  initialState,
  reducers: {
    setCopyDestination: (
      state,
      action: PayloadAction<{
        model: string;
        id: string;
        parentModel: string | undefined;
        parentEntityId: string | undefined;
      }>
    ) => {
      const { model, id, parentModel, parentEntityId } = action.payload;

      state.model = model;
      state.id = id;
      state.parentModel = parentModel;
      state.parentEntityId = parentEntityId;
    },
    unsetCopyDestination: (state) => {
      state.model = undefined;
      state.id = undefined;
      state.parentModel = undefined;
      state.parentEntityId = undefined;
    },
    // copySuccess: (
    //   state,
    //   action: PayloadAction<{ modelList: ModelListProps }>
    // ) => {},
  },
});

export const pushEntityToDestination =
  (model: string, id: string): AppThunk =>
  async (dispatch, getState) => {
    const copyState = getState().interface.copy;
    try {
      if (
        typeof copyState.id !== "undefined" &&
        typeof copyState.model !== "undefined"
      ) {
        const response = await copyEntityToDestination(
          id,
          model,
          copyState.id,
          copyState.model
        );
        const { statusCode, result } = response;
        if (statusCode === 200 && typeof result !== "undefined") {
          dispatch(
            showSnackbar({
              message: "Copiado com sucesso",
              type: "success",
            })
          );

          //this probably shouldnt be here;
          result.forEach((modelList) => {
            dispatch(fetchList(modelList, undefined, true));
          });
        }
      }
    } catch (error) {
      if (error instanceof PuxadinhoError) {
        if (error.statusCode === 400 || error.statusCode === 500) {
          dispatch(showErrorDialog(error.result));
        }
        if (error.statusCode === 401 || error.statusCode === 419) {
          // dispatch(setLoggedStatus(false));
        }
      }
      console.log(error);
      // console.log(`fetch entity error ${err}`);
      // dispatch(getCommentsFailure(err));
    }
  };

export const getCopyDestinationEntity = (state: CopyState) => ({
  model: state.model,
  id: state.id,
  parentModel: state.parentModel,
  parentEntityId: state.parentEntityId,
});

export const isCopyDestinationSet = (state: CopyState) =>
  typeof state.id !== "undefined" && typeof state.model !== "undefined";

export const { setCopyDestination, unsetCopyDestination } = copySlice.actions;
export default copySlice.reducer;
