import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import rootReducer from "~/app/rootReducer";
// import * as models from 'app/models';

const initialState = {
  locales: {
    list: ["pt_BR"],
    active: "pt_BR",
  },
  // models: {
  //   // loaded: true,
  //   // menu: models.menu,
  //   // list: models.normalizedList,
  //   // relationships: models.relationships,
  // },
};

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

// if (import.meta.env.NODE_ENV === "development" && module.hot) {
//   module.hot.accept("./rootReducer", () => {
//     const newRootReducer = require("./rootReducer").default;
//     store.replaceReducer(newRootReducer);
//   });
// }

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
