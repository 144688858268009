import { RecipeType } from "~/types/views";
import RecipeHeader from "./RecipeHeader/RecipeHeader";
import RecipeStats from "./RecipeStats/RecipeStats";
import RecipeBlock from "./RecipeBlock/RecipeBlock";
import RecipeRelationships from "./RecipeRelationships/RecipeRelationships";
import RecipeLinks from "./RecipeLinks/RecipeLinks";

type Props = {
  entityModel: string;
  entityId: string;
  entityView: RecipeType;
};

const RecipeView = ({ entityView, entityModel, entityId }: Props) => {
  return entityView ? (
    <>
      <RecipeHeader recipe={entityView} link={`/${entityModel}/${entityId}`} />
      <RecipeStats recipe={entityView} />
      {entityView.blocks.map((block, blockIndex) => {
        return (
          <RecipeBlock
            recipeBlock={block}
            link={`/${entityModel}/${entityId}`}
            entityModel={entityModel}
            key={blockIndex}
          />
        );
      })}

      <RecipeRelationships
        recipeRelationships={entityView.relationships}
        link={`/${entityModel}/${entityId}`}
      />
      <RecipeLinks
        recipeLinks={entityView.links}
        link={`/${entityModel}/${entityId}`}
      />
    </>
  ) : null;
};

export default RecipeView;
