export const getFriendlyModelName = (model: string) => {
  switch (model) {
    case "home":
      return "Home";
    case "recipe":
      return "Receita";
    case "blog":
      return "Blog";
    case "post":
      return "Post";
    case "category":
      return "Categoria";
    case "subhome":
      return "Subhome";
    case "product":
      return "Produto";
    case "store":
      return "Loja";
    case "showcase":
      return "Vitrine";
    case "dictionaryEntry":
      return "Verbete";
    case "author":
      return "Autor";
    case "cookingTool":
      return "Utensílio";
    default:
      return model;
  }
};
