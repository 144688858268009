import { connect, ConnectedProps } from "react-redux";
import { fetchList } from "~/features/data/Lists/listsSlice";
import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import matchSorter from "match-sorter";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { SyntheticEvent } from "react";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface FreeSoloFieldType {
  column: string;
  type: string;
  placeholder: string;
  options: string[];
  rules?: string;
  always?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: FreeSoloFieldType;
  onChange: (column: string, value: string | null, locale?: string) => void;
  locale: string;
};

type FilterInput = {
  inputValue: string;
};

type Props = PropsFromRedux & OwnProps;

const filterOptions = (options: string[], filterInput: FilterInput) => {
  const terms = filterInput.inputValue.split(" ");

  return terms.reduceRight((results, term) => {
    return matchSorter(results, term);
  }, options);
};

const FreeSoloFieldRaw = ({ field, draftValue, onChange, locale }: Props) => {
  return (
    <>
      <Autocomplete
        freeSolo
        options={field.options}
        value={
          typeof draftValue !== "undefined" && draftValue !== null
            ? String(draftValue)
            : ""
        }
        renderInput={(params) => (
          <TextField {...params} label={field.placeholder} />
        )}
        filterOptions={filterOptions}
        fullWidth
        size="small"
        // onChange={(event: SyntheticEvent, newValue: any) => {
        //   onChange(
        //     field.column,
        //     newValue !== null ? String(newValue) : null,
        //     locale
        //   );
        // }}
        onInputChange={(_event: SyntheticEvent, newValue: string) => {
          onChange(field.column, newValue !== "" ? newValue : null, locale);
        }}
      />

      {/* <VirtualizedAutocomplete
      filterOptions={filterOptions}
      value={typeof draftValue !== "undefined" ? String(draftValue) : null}
      onChange={(event: any, newValue: any) => {
        onChange(
          field.column,
          newValue !== null ? String(newValue.id) : null,
          locale
        );
      }}
      // options={list}
      options={
        draftValue === null || typeof draftValue === "undefined"
          ? [{ id: null, title: "" }, ...list]
          : list
      }
      isOptionEqualToValue={(option: any, value: any) =>
        String(option.id) === String(value)
      }
      getOptionLabel={(option: any) => {
        return typeof selectedOption !== "undefined"
          ? selectedOption.px_title
          : "";
        //   if (typeof list !== "undefined") {
        //     const item = list.find(
        //       (item: any) => String(item.id) === String(option)
        //     );

        //     if (typeof item !== "undefined") {
        //       return item.px_title;
        //     }
        //   }

        //   return "";
        // }
        // typeof list !== 'undefined' &&
        // typeof list.find((item: any) => String(item.id) === String(option)) !==
        //   'undefined'
        //   ? list.find((item: any) => String(item.id) === String(option)).px_title
        //   : ''
      }}
      label={field.placeholder}
      fullWidth
      autoComplete={false}
    /> */}
    </>
  );
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId, field }: OwnProps
) => ({
  draftValue: getDraftColumn(state, entityModel, entityId, field.column),
});

const mapDispatchToProps = { fetchList };

const connector = connect(mapStateToProps, mapDispatchToProps);
const FreeSoloField = connector(FreeSoloFieldRaw);
export default FreeSoloField;
