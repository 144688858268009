import ImageThumb from "./ImageThumb/ImageThumb";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { ListedImage } from "~/types";

type OwnProps = {
  imagesList: ListedImage[];
  setSelectedImage: (id: string) => void;
  setDraft: (image: ListedImage) => void;
};

type Props = OwnProps;

const VirtualizedImagesList = ({
  imagesList,
  setSelectedImage,
  setDraft,
}: Props) => {
  const renderRow = (props: ListChildComponentProps) => {
    const { index, style, data } = props;

    const image = data.list[index];

    return (
      <div style={{ ...style, padding: 10 }} key={String(image.id)}>
        <ImageThumb
          setDraft={setDraft}
          image={image}
          setSelectedImage={setSelectedImage}
        />
      </div>
    );
  };

  const cleanImageList = imagesList.filter((image) => image.px_image_url);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          itemData={{ list: cleanImageList }}
          height={height ?? 0}
          width={width ?? 0}
          itemSize={200}
          itemCount={cleanImageList.length}
        >
          {renderRow}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

export default VirtualizedImagesList;
