import { openAppPanel } from "~/features/interface/AppPanel/appPanelSlice";
import { makeStyles } from "tss-react/mui";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
// import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import { selectEntityFromList } from "~/features/data/dataSlice";
import { getCopyDestinationEntity } from "~/features/interface/interfaceSlice";
import { unsetCopyDestination } from "~/features/interface/Copy/copySlice";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
// import PermMediaIcon from "@mui/icons-material/PermMedia";
// import GridViewIcon from "@mui/icons-material/GridView";
// import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import Chip from "@mui/material/Chip";
import { showImageManager } from "~/features/interface/ImageManager/imageManagerSlice";
// import { showMergedContentManager } from "~/features/interface/MergedContentManager/mergedContentManagerSlice";

const useStyles = makeStyles()(() => ({
  button: {
    flexGrow: 1,
  },
  buttonsCtn: {
    display: "flex",
    // flexFlow: ""
    // width: "50%",
    gap: "10px",
    alignItems: "center",
  },
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

export const AppHeaderBarRaw = ({
  openAppPanel,
  showImageManager,
  // showMergedContentManager,
  unsetCopyDestination,
  model,
  id,
  parentModel,
  parentEntityId,
  entity,
}: Props) => {
  const { classes } = useStyles();

  return (
    <AppBar position="static" color="secondary">
      <Toolbar>
        <div className={classes.button}>
          <Button to="/" component={RouterLink}>
            Panelinha
          </Button>
        </div>

        <div className={classes.buttonsCtn}>
          {typeof entity !== "undefined" ? (
            <Chip
              to={
                typeof parentModel !== "undefined" &&
                typeof parentEntityId !== "undefined"
                  ? `/${parentModel}/${parentEntityId}/${model}/${id}`
                  : `/${model}/${id}`
              }
              component={RouterLink}
              size="small"
              label={entity.px_title}
              clickable
              icon={<ShoppingBagOutlinedIcon fontSize="inherit" />}
              color="primary"
              onClick={() => unsetCopyDestination()}
            />
          ) : null}
          {/* <IconButton
          color="inherit"
          aria-label="Gerenciador de imagens"
          onClick={() => {
            showImageManager({});
          }}
          size="large"
        >
          <PermMediaIcon />
        </IconButton> */}
          <Button
            variant="contained"
            size="small"
            aria-label="Gerenciador de imagens"
            onClick={() => {
              showImageManager({});
            }}
          >
            Imagens
          </Button>
          {/* <IconButton
          color="inherit"
          aria-label="Blocos de conteúdo"
          onClick={() => {
            showMergedContentManager({ model: "mergedBlock" });
          }}
          size="large"
        >
          <GridViewIcon />
        </IconButton> */}
          {/* <Button
            variant="contained"
            size="small"
            aria-label="Blocos de conteúdo"
            onClick={() => {
              showMergedContentManager({ model: "mergedBlock" });
            }}
          >
            [Blocos de conteúdo]
          </Button> */}
          {/* <IconButton
          color="inherit"
          aria-label="Destaques"
          onClick={() => {
            showMergedContentManager({ model: "mergedHighlight" });
          }}
          size="large"
        >
          <ViewCarouselIcon />
        </IconButton> */}
          {/* <Button
            variant="contained"
            aria-label="Destaques"
            size="small"
            onClick={() => {
              showMergedContentManager({ model: "mergedHighlight" });
            }}
          >
            [Destaques]
          </Button> */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="Menu"
            onClick={() => {
              openAppPanel();
            }}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state: RootState) => {
  const { model, id, parentModel, parentEntityId } =
    getCopyDestinationEntity(state);
  return {
    model,
    id,
    parentModel,
    parentEntityId,
    entity:
      typeof id !== "undefined" && typeof model !== "undefined"
        ? selectEntityFromList(state, model, id)
        : undefined,
  };
};

const mapDispatchToProps = {
  // fetchList,
  unsetCopyDestination,
  openAppPanel,
  showImageManager,
  // showMergedContentManager,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const AppHeaderBar = connector(AppHeaderBarRaw);
export default AppHeaderBar;
