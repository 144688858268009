import { Descendant, RPBlockComponentType } from "~/types/views";

type RecipeBlockComponentsGroupType = {
  type: string;
  content: RPBlockComponentType[];
  startingComponentsPosition: number;
};

type GroupStartingComponentsPositionType = {
  [key: string]: number;
};

export const parseNodesToSimpleText = (nodes: Descendant[]) => {
  return nodes.map((node) => {
    return getNodeChildren(node);
  });
};

const getNodeChildren = (node: any) => {
  if (typeof node.text !== "undefined") {
    return node.text;
  }

  return node.children.map((n: any) => getNodeChildren(n));
};

export const getRecipeBlockComponentsGroups = (
  recipeBlockComponents: RPBlockComponentType[]
) => {
  let lastGroupType = "";
  const groups: RecipeBlockComponentsGroupType[] = [];
  const groupStartingComponentsPosition: GroupStartingComponentsPositionType =
    {};

  if (typeof recipeBlockComponents[0] !== "undefined") {
    lastGroupType = recipeBlockComponents[0].type;
  }

  recipeBlockComponents.forEach((recipeBlockComponent) => {
    if (lastGroupType !== recipeBlockComponent.type) {
      groups.push({
        type: recipeBlockComponent.type,
        content: [recipeBlockComponent],
        startingComponentsPosition: 1,
      });
    } else {
      const groupToBeUpdated = groups.pop();
      let newContent;

      if (typeof groupToBeUpdated !== "undefined") {
        groupToBeUpdated.content.push(recipeBlockComponent);
        newContent = groupToBeUpdated.content;
      } else {
        newContent = [recipeBlockComponent];
      }

      groups.push({
        type: recipeBlockComponent.type,
        content: newContent,
        startingComponentsPosition: 1,
      });
    }

    lastGroupType = recipeBlockComponent.type;
  });

  return groups.map((group) => {
    if (typeof groupStartingComponentsPosition[group.type] !== "undefined") {
      const position = groupStartingComponentsPosition[group.type];
      groupStartingComponentsPosition[group.type] += group.content.length;

      return {
        ...group,
        startingComponentsPosition: position + 1,
      };
    } else {
      groupStartingComponentsPosition[group.type] = group.content.length;
      return group;
    }
  });
};
