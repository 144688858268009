import { useMemo, useCallback, useState, ChangeEvent } from "react";
import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import { connect, ConnectedProps } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

type PropsFromRedux = ConnectedProps<typeof connector>;

type Option = {
  id: string;
  px_title: string;
};

export interface CheckboxFieldType {
  column: string;
  type: string;
  placeholder: string;
  options: Option[];
  rules?: string;
  always?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: CheckboxFieldType;
  onChange: (column: string, value: string[], locale?: string) => void;
  locale: string;
};

type Props = PropsFromRedux & OwnProps;

const CheckboxFieldRaw = ({ field, draftValue, onChange, locale }: Props) => {
  // const selection = Array.isArray(draftValue) ? draftValue : [];

  const [selection] = useState<string[]>(
    Array.isArray(draftValue) ? draftValue : []
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked && selection.indexOf(event.target.name) === -1) {
        onChange(field.column, selection.concat([event.target.name]), locale);
      } else {
        if (
          !event.target.checked &&
          selection.indexOf(event.target.name) !== -1
        ) {
          onChange(
            field.column,
            selection.filter((option) => option !== event.target.name),
            locale
          );
        }
      }
    },
    [selection, field.column, locale, onChange]
  );

  const options = useMemo(
    () =>
      field.options.map((option) => (
        <FormControlLabel
          key={String(option.id)}
          control={
            <Checkbox
              name={String(option.id)}
              checked={selection.indexOf(option.id) !== -1 ? true : false}
              onChange={handleChange}
            />
          }
          label={option.px_title}
        />
      )),
    [field.options, selection, handleChange]
  );

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{field.placeholder}</FormLabel>
      <FormGroup aria-label={field.placeholder}>{options}</FormGroup>
    </FormControl>
  );
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId, field }: OwnProps
) => ({
  draftValue: getDraftColumn(state, entityModel, entityId, field.column),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const CheckboxField = connector(CheckboxFieldRaw);
export default CheckboxField;
