import { Theme, alpha } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import BackupIcon from "@mui/icons-material/Backup";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { FormEvent } from "react";

const useStyles = makeStyles()((theme: Theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: theme.spacing(1),
    width: "100%",
  },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  inputRoot: {
    width: "100%",
    color: "inherit",
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },

  iconButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  fileInput: {
    display: "none",
  },
}));

type OwnProps = {
  searchString: string;
  setSearchString: (text: string) => void;
  onSelectFile: (e: FormEvent) => void;
};

type Props = OwnProps;

const ImageManagerToolbar = ({
  setSearchString,
  searchString,
  onSelectFile,
}: Props) => {
  const { classes } = useStyles();

  return (
    <AppBar position="static" color="secondary">
      <Toolbar disableGutters={true}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            value={searchString}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={(event) => {
              setSearchString(event.target.value);
            }}
          />
        </div>
        <input
          accept="image/*"
          className={classes.fileInput}
          id="contained-button-file"
          multiple
          type="file"
          onChange={onSelectFile}
        />
        <label htmlFor="contained-button-file">
          <IconButton
            color="primary"
            className={classes.iconButton}
            component="span"
            size="large"
          >
            <BackupIcon />
          </IconButton>
        </label>
      </Toolbar>
    </AppBar>
  );
};

export default ImageManagerToolbar;
