import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { fetchEntity, pushEntity } from "~/features/data/Entity/entitiesSlice";
// import { getModelComponents } from '~/features/layout/layoutSlice';
import FormFields from "~/features/drafts/FormFields";
import EntityToolbar from "./EntityToolbar";
import styles from "./Entity.module.css";
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import EntityWithRouter from "./EntityWithRouter";
import { RootState } from "~/app/store";
// import ModelComponents from '~/features/layout/ModelComponents';
// import Highlighter from 'special/Highlighter';
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';

type PropsFromRedux = ConnectedProps<typeof connector>;

type PathParams = {
  activeModel: string;
  id?: string;
};

type OwnProps = {
  parentModel?: string;
  parentEntityId?: string;
  baseUrl: string;
};

type PathAndOwnProps = RouteComponentProps<PathParams> & OwnProps;

type Props = PathAndOwnProps & PropsFromRedux;

// const getCurrentUrl = (baseUrl: string, activeModel: string, id?: string) =>
//   baseUrl + '/' + activeModel + (typeof id !== 'undefined' ? '/' + id : '/');

class EntityRaw extends Component<Props> {
  componentDidMount() {
    this.fetchEntity();
  }

  componentDidUpdate(prevProps: Props) {
    const { entityId, entityModel } = this.props;
    // const {match: { params }} = this.props;

    // if (entityModel !== prevProps.entityModel) {
    //   this.fetchEntity();
    // }

    if (
      entityModel !== prevProps.entityModel ||
      entityId !== prevProps.entityId
    ) {
      this.fetchEntity();
    }
  }

  fetchEntity() {
    const { entityModel, entityId, parentModel, parentEntityId, fetchEntity } =
      this.props;

    if (typeof entityId !== "undefined") {
      fetchEntity(
        { model: entityModel, parentModel, parentEntityId },
        entityId
      );
    }
  }

  render() {
    const {
      // goBack,
      entityModel,
      entityId,
      // pushEntity,
      parentModel,
      parentEntityId,
      // baseUrl,
      match: { url },
      // modelComponents,
    } = this.props;

    return (
      <div className={styles.container}>
        <Switch>
          <Route path={`${url}/:activeModel/:id?`}>
            <EntityWithRouter
              parentModel={entityModel}
              parentEntityId={entityId}
              baseUrl={url}
            />
          </Route>
        </Switch>
        {/* <div className={styles.modelContainer}>
          {typeof entityId !== 'undefined' && modelComponents.length ? (
            <ModelComponents
              title={''}
              modelComponents={modelComponents}
              baseUrl={getCurrentUrl(baseUrl, entityModel, entityId)}
            />
          ) : (
            <ModelComponents
              title={''}
              modelComponents={[]}
              baseUrl={getCurrentUrl(baseUrl, entityModel, entityId)}
            />
          )}
        </div> */}
        <div className={styles.toolbarContainer}>
          <EntityToolbar
            entityModel={entityModel}
            entityId={entityId}
            parentModel={parentModel}
            parentEntityId={parentEntityId}
          />
        </div>
        <div className={styles.fieldsContainer}>
          <FormFields entityModel={entityModel} entityId={entityId} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (
  _state: RootState,
  { match: { params } }: PathAndOwnProps
) => ({
  entityModel: params.activeModel,
  entityId: params.id,
  // modelComponents: getModelComponents(state, params.activeModel),
  // draft: getDraft(state, params.activeModel, params.id),
  // isDraftSaveable:
  //   typeof params.id !== 'undefined'
  //     ? isDraftSaveable(state, params.activeModel, params.id)
  //     : false,
});

const mapDispatchToProps = {
  fetchEntity,
  pushEntity,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const Entity = connector(EntityRaw);
export default Entity;
