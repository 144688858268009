import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface LocalesState {
  list: string[];
  active?: string;
}

const initialState: LocalesState = {
  list: [],
  active: undefined,
};

export const localesSlice = createSlice({
  name: 'locales',
  initialState,
  reducers: {
    setLocales: (state, action) => {
      state.list = action.payload;
    },
    setActiveLocale: (state, action) => {
      state.active = action.payload;
    },
  },
});

export const { setLocales, setActiveLocale } = localesSlice.actions;

export const getLocalesList = (state: RootState) => state.locales.list;
export const getActiveLocale = (state: RootState) => state.locales.active;

export default localesSlice.reducer;
