import { showSnackbar } from "~/features/interface/Snackbar/snackbarSlice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "~/app/store";
import {
  hideLoader,
  showLoader,
} from "~/features/interface/Loader/loaderSlice";
import { Entity, ModelListProps, Draft } from "~/types";
import {
  getEntity,
  deleteEntity,
  updateEntity,
  saveEntity,
  setPublish,
  copyEntity,
} from "~/api/entitiesAPI";
import { PuxadinhoError } from "~/shared/PuxadinhoError";
import { clearDraft, removeEntitySuccess } from "~/redux/actions";
import { History } from "history";
import { showErrorDialog } from "~/features/interface/ErrorDialog/errorDialogSlice";
import { updateEntityView } from "../EntityView/entitiesViewSlice";

type EntitiesData = {
  [id: string]: Entity;
};

interface EntitiesState {
  [model: string]: EntitiesData;
}

const initialState: EntitiesState = {};

export const entitiesSlice = createSlice({
  name: "entities",
  initialState,
  reducers: {
    fetchEntitySuccess: (
      state,
      action: PayloadAction<{
        modelList: ModelListProps;
        id: string;
        response: Entity;
      }>
    ) => {
      const {
        modelList: { model },
        id,
        response,
      } = action.payload;

      if (typeof state[model] === "undefined") {
        state[model] = {};
      }

      state[model][id] = response;
    },
    pushEntitySuccess: (
      state,
      action: PayloadAction<{
        modelList: ModelListProps;
        id: string;
        response: Entity;
      }>
    ) => {
      const { modelList, id, response } = action.payload;

      if (typeof state[modelList.model] === "undefined") {
        state[modelList.model] = {};
      }

      state[modelList.model][id] = response;
    },
    setPublishSuccess: (
      _state,
      _action: PayloadAction<{
        model: string;
        id: string;
        response: Entity;
      }>
    ) => {
      // console.log({ state, action });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      removeEntitySuccess,
      (
        state,
        action: PayloadAction<{
          modelList: ModelListProps;
          id: string;
        }>
      ) => {
        const { modelList, id } = action.payload;

        if (typeof state[modelList.model] !== "undefined") {
          delete state[modelList.model][id];
        }
      }
    );
    // .addDefaultCase((state, action) => {});
  },
});

export const fetchEntity =
  (modelList: ModelListProps, id: string): AppThunk =>
  async (dispatch) => {
    try {
      // dispatch(showLoader({ queueType: 'fetchEntity' }));

      const response = await getEntity(modelList, id);

      const { statusCode, result } = response;

      if (statusCode === 200) {
        dispatch(
          fetchEntitySuccess({
            response: result,
            modelList,
            id: String(id),
          })
        );
      }

      // dispatch(hideLoader({ queueType: 'fetchEntity' }));
    } catch (error) {
      if (error instanceof PuxadinhoError) {
        if (error.statusCode === 400 || error.statusCode === 500) {
          // if (typeof history !== 'undefined') {
          //   history.push('./');
          // }
          // dispatch(showErrorDialog(error.result ));
        }

        if (error.statusCode === 401 || error.statusCode === 419) {
          // dispatch(setLoggedStatus(false));
        }
      }

      console.log(error);
      // console.log(`fetch entity error ${err}`);
      // dispatch(getCommentsFailure(err));
    }
  };

export const removeEntity =
  (modelList: ModelListProps, id: string, history?: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(showLoader({ queueType: "removeEntity" }));

      const response = await deleteEntity(modelList.model, id);

      const { statusCode } = response;

      if (statusCode === 200) {
        dispatch(
          removeEntitySuccess({
            modelList,
            id: String(id),
          })
        );

        dispatch(
          showSnackbar({
            message: "Removido com sucesso",
            type: "success",
          })
        );

        dispatch(updateEntityView());

        if (typeof history !== "undefined") {
          history.push("./");
        }
      }

      dispatch(hideLoader({ queueType: "removeEntity" }));
    } catch (error) {
      if (error instanceof PuxadinhoError) {
        if (error.statusCode === 400 || error.statusCode === 500) {
          dispatch(showErrorDialog(error.result));
        }

        if (error.statusCode === 401 || error.statusCode === 419) {
          // dispatch(setLoggedStatus(false));
        }
      }

      console.log(error);
      // console.log(`remove error ${err}`);
    }

    dispatch(hideLoader({ queueType: "removeEntity" }));
  };

export const pushEntity =
  (modelList: ModelListProps, id: string | undefined, draft: Draft): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(showLoader({ queueType: "pushEntity" }));
      // console.log(draft);
      let response;
      if (typeof id !== "undefined") {
        response = await updateEntity(modelList, id, draft);
      } else {
        response = await saveEntity(modelList, draft);
      }
      const { statusCode, result } = response;
      if (statusCode === 200) {
        dispatch(
          pushEntitySuccess({
            response: result,
            modelList,
            id: typeof id !== "undefined" ? String(id) : String(result.id),
          })
        );

        dispatch(clearDraft(modelList.model));

        dispatch(
          showSnackbar({
            message: "Salvo com sucesso",
            type: "success",
          })
        );

        dispatch(updateEntityView());

        if (typeof draft.image_id !== "undefined" && draft.image_id !== null) {
          dispatch(fetchEntity({ model: "image" }, draft.image_id));
        }
      }
      dispatch(hideLoader({ queueType: "pushEntity" }));
    } catch (error) {
      if (error instanceof PuxadinhoError) {
        if (error.statusCode === 400 || error.statusCode === 500) {
          dispatch(showErrorDialog(error.result));
        }

        if (error.statusCode === 401 || error.statusCode === 419) {
          // dispatch(setLoggedStatus(false));
        }
      }

      // console.log(error);
      // console.log(`push entity error ${err}`);
    }
    dispatch(hideLoader({ queueType: "pushEntity" }));
  };

export const setEntityPublish =
  (modelList: ModelListProps, id: string, publish: boolean): AppThunk =>
  async (dispatch) => {
    try {
      const response = await setPublish(modelList, id, publish);

      const { statusCode, result } = response;

      if (statusCode === 200) {
        dispatch(
          pushEntitySuccess({
            response: result,
            modelList,
            id: String(id),
          })
        );

        dispatch(
          setPublishSuccess({
            response: result,
            model: modelList.model,
            id: String(id),
          })
        );

        dispatch(
          showSnackbar({
            message: "Salvo com sucesso",
            type: "success",
          })
        );

        dispatch(updateEntityView());
      }
    } catch (error) {
      if (error instanceof PuxadinhoError) {
        if (error.statusCode === 400 || error.statusCode === 500) {
          dispatch(showErrorDialog(error.result));
        }

        if (error.statusCode === 401 || error.statusCode === 419) {
          // dispatch(setLoggedStatus(false));
        }
      }

      console.log(error);
    }
  };

export const duplicateEntity =
  (modelList: ModelListProps, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(showLoader({ queueType: "duplicateEntity" }));

      const response = await copyEntity(modelList, id);

      const { statusCode, result } = response;
      if (statusCode === 200) {
        dispatch(
          pushEntitySuccess({
            response: result,
            modelList,
            id: String(result.id),
          })
        );

        dispatch(
          showSnackbar({
            message: "Duplicado com sucesso",
            type: "success",
          })
        );
      }
      dispatch(hideLoader({ queueType: "duplicateEntity" }));
    } catch (error) {
      if (error instanceof PuxadinhoError) {
        if (error.statusCode === 400 || error.statusCode === 500) {
          dispatch(showErrorDialog(error.result));
        }

        if (error.statusCode === 401 || error.statusCode === 419) {
          // dispatch(setLoggedStatus(false));
        }
      }
    }
    dispatch(hideLoader({ queueType: "duplicateEntity" }));
  };

export const selectEntity = (state: EntitiesState, model: string, id: string) =>
  typeof state[model] !== "undefined" && typeof state[model][id] !== "undefined"
    ? state[model][id]
    : undefined;

export const { fetchEntitySuccess, pushEntitySuccess, setPublishSuccess } =
  entitiesSlice.actions;
export default entitiesSlice.reducer;
