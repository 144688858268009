import { forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import {
  getSnackbarMessage,
  getSnackbarType,
  isSnackbarOpen,
} from "~/features/interface/interfaceSlice";

import { hideSnackbar } from "./snackbarSlice";

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AppSnackbarRaw = ({
  snackbarMessage,
  snackbarType,
  isSnackbarOpen,
  hideSnackbar,
}: Props) => {
  const handleClose = () => {
    hideSnackbar();
  };

  return snackbarMessage !== "" ? (
    <Snackbar
      open={isSnackbarOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      // transitionDuration={{ exit: 0 }}
    >
      <Alert onClose={handleClose} severity={snackbarType}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  ) : null;
};

const mapStateToProps = (state: RootState) => ({
  snackbarMessage: getSnackbarMessage(state),
  snackbarType: getSnackbarType(state),
  isSnackbarOpen: isSnackbarOpen(state),
});

const mapDispatchToProps = {
  hideSnackbar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const AppSnackbar = connector(AppSnackbarRaw);
export default AppSnackbar;
