import { BlockContentType } from "~/types/views";
import styles from "./BlockContent.module.scss";
import { Link } from "react-router-dom";
import { getImageUrl } from "~/shared/ImageTools";

type Props = {
  blockContent: BlockContentType;
  blockId: string;
  entityId: string;
  entityModel: string;
};

const BlockContent = ({
  blockContent,
  entityModel,
  entityId,
  blockId,
}: Props) => {
  return (
    <div className={styles.ctn}>
      <Link
        to={`/${entityModel}/${entityId}/${entityModel}Block/${blockId}/${entityModel}BlockContent/${blockContent.id}`}
      >
        <div className={styles.ctnText}>
          <h6>{blockContent.title}</h6>
          {blockContent.tag !== "" ? <p>{blockContent.tag}</p> : null}
        </div>
        {blockContent.image ? (
          <div className={styles.ctnImage}>
            <img
              src={getImageUrl(`${blockContent.image.url}`, "bk")}
              alt={blockContent.image.alt}
              width={480}
              height={320}
              loading="lazy"
            />
          </div>
        ) : null}
      </Link>
    </div>
  );
};

export default BlockContent;
