import React from "react";
import ReactDOM from "react-dom/client";
import Puxadinho from "~/Puxadinho";
import { store } from "~/app/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import axios from "axios";

// declare module "@mui/styles/defaultTheme" {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

axios.defaults.withCredentials = true;

const theme = createTheme({
  palette: {
    primary: {
      main: "#212121",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ff1744",
      contrastText: "#000000",
    },
  },
});

const render = () => {
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
      <CssBaseline />
      <Provider store={store}>
        <Router>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Puxadinho />
            </ThemeProvider>
          </StyledEngineProvider>
        </Router>
      </Provider>
    </React.StrictMode>
  );
};

render();

// if (import.meta.env.NODE_ENV === "development" && module.hot) {
//   module.hot.accept("./Puxadinho", render);
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
