type Mapping = {
  [x: string]: string;
};

const decimalCharacterMapping: Mapping = {
  "0.5": "½",
  "0.25": "¼",
  "0.33": "⅓",
  "0.75": "¾",
  "0.67": "⅔",
  "0.125": "⅛",
};

const decimalSufixMapping: Mapping = {
  "0.5": "",
  "0.25": " de",
  "0.33": " de",
  "0.75": " de",
  "0.67": " de",
  "0.125": " de",
};

const getIntegerPart = (quantity: number) => {
  return Math.floor(quantity);
};

export const getDecimalPart = (quantity: number) => {
  const splitQuantity = String(quantity).split(".");

  return typeof splitQuantity[1] !== "undefined"
    ? "0." + splitQuantity[1].substring(0, 3)
    : "0.00";
};

const isInteger = (quantity: number) => {
  return getDecimalPart(quantity) === "0.00";
};

export const mapQuantityToCharacter = (
  quantity: number,
  shouldBeNumeric: boolean
) => {
  return isInteger(quantity) || shouldBeNumeric
    ? quantity
    : mapNonIntegerToCharacter(quantity);
};

const mapNonIntegerToCharacter = (quantity: number) => {
  return getIntegerPart(quantity) !== 0
    ? !decimalCharacterExists(getDecimalPart(quantity))
      ? quantity
      : getIntegerPart(quantity) +
        " " +
        mapDecimalToCharacter(getDecimalPart(quantity)) +
        (getIntegerPart(quantity) >= 1
          ? mapDecimalToSufix(getDecimalPart(quantity))
          : "")
    : mapDecimalToCharacter(getDecimalPart(quantity)) +
        mapDecimalToSufix(getDecimalPart(quantity));
};

const decimalCharacterExists = (decimal: string) =>
  typeof decimalCharacterMapping[decimal] !== "undefined";

const mapDecimalToCharacter = (decimal: string) => {
  return decimalCharacterExists(decimal)
    ? decimalCharacterMapping[decimal]
    : decimal;
};

const mapDecimalToSufix = (decimal: string) => {
  return typeof decimalCharacterMapping[decimal] !== "undefined"
    ? decimalSufixMapping[decimal]
    : "";
};
