import { useEffect, useState, useCallback, MouseEvent } from "react";
import { connect, ConnectedProps } from "react-redux";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AppDialog from "~/features/interface/ErrorDialog/ErrorDialog";
import { showErrorDialog } from "~/features/interface/ErrorDialog/errorDialogSlice";
import { setLoggedStatus } from "~/features/server/serverSlice";

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    flexGrow: 1,
    flexShrink: 1,
    display: "flex",
    overflow: "hidden",
  },

  paper: {
    padding: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  loader: {
    width: "100%",
  },
}));

const LoginRaw = ({ showErrorDialog, setLoggedStatus }: Props) => {
  const { classes } = useStyles();
  const [csrfStatus, setCsrfStatus] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const submit = useCallback(
    (failSilently = false) => {
      axios
        .post(`${import.meta.env.VITE_APP_API}/login`, {
          email,
          password,
        })
        .then(() => {
          setLoggedStatus(true);
        })
        .catch((err) => {
          if (!failSilently) {
            if (err.response.status === 422) {
              showErrorDialog(err.response.data.errors);
            }
          }
        });
    },
    [email, password, showErrorDialog, setLoggedStatus]
  );

  useEffect(() => {
    axios
      .get(`${import.meta.env.VITE_APP_API}/sanctum/csrf-cookie`)
      .then(() => {
        setCsrfStatus(true);
        submit(true);
      })
      .catch(() => {
        showErrorDialog({
          "Erro 500": ["Servidor offline"],
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <div className={classes.root}>
      <AppDialog />
      {csrfStatus ? (
        <Grid
          container
          spacing={3}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <form
              autoComplete="on"
              onSubmit={(e) => {
                e.preventDefault();
                submit();
              }}
            >
              <Paper className={classes.paper}>
                <Grid
                  container
                  spacing={3}
                  direction="column"
                  alignItems="flex-start"
                >
                  <Grid container item>
                    <TextField
                      required
                      id="email"
                      name="email"
                      label="E-mail"
                      type="email"
                      autoComplete="current-email"
                      focused
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      variant="outlined"
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                  <Grid container item>
                    <FormControl
                      // sx={{ m: 1, width: "25ch" }}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>
                    {/* <FormControl variant="outlined">
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <OutlinedInput
                        id="password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        required
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl> */}
                  </Grid>
                  <Grid container item>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      fullWidth
                    >
                      Log-in
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Grid>
        </Grid>
      ) : (
        <LinearProgress className={classes.loader} />
      )}
    </div>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = { showErrorDialog, setLoggedStatus };

const connector = connect(mapStateToProps, mapDispatchToProps);

const Login = connector(LoginRaw);
export default Login;
