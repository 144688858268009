import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import MultipleEntitySelect from "~/special/MultipleEntitySelect/MultipleEntitySelect";
import { setAssociation } from "~/features/interface/ImageManager/imageManagerSlice";
import { getImageAssociation } from "~/features/interface/interfaceSlice";

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  disabled?: boolean;
};

type Props = PropsFromRedux & OwnProps;

const entityModel = "sharedModel";
const entityId = undefined;
const fieldColumn = "entitiable";
const locale = "pt";

const field = {
  column: fieldColumn,
  type: "multipleDependantSelect",
  placeholder: "Associar imagens",
  dependencies: [
    "recipe",
    "post",
    "subhome",
    "blog",
    "showcase",
    "product",
    "dictionaryEntry",
  ],
};

const ImageAssociationSelectFieldRaw = ({
  disabled,
  onChange,
  value,
}: Props) => {
  return (
    <MultipleEntitySelect
      entityModel={entityModel}
      entityId={entityId}
      field={field}
      onChange={onChange}
      locale={locale}
      value={value}
      disabled={disabled}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  value: getImageAssociation(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChange: (
    model: string | null,
    id: string | null
    // locale?: string
  ): void => {
    dispatch(
      setAssociation({
        id,
        model,
      })
    );
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const ImageAssociationSelectField = connector(ImageAssociationSelectFieldRaw);
export default ImageAssociationSelectField;
