import { useState } from "react";
import { ExternalLink, PuxEditorHelper } from "../utils/PuxEditorHelper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import { BaseEditor } from "slate";

// type ActiveElement = {
//   url: string;
//   new_tab: boolean;
// };

type OwnProps = {
  editor: BaseEditor;
  isExternalLinkDialogOpen: boolean;
  setIsExternalLinkDialogOpen: (state: boolean) => void;
  activeElement: ExternalLink | null;
};

const isValidHttpUrl = (input: string) => {
  let url;
  try {
    url = new URL(input);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

const ExternalLinkDialog = ({
  editor,
  // isExternalLinkDialogOpen,
  setIsExternalLinkDialogOpen,
  activeElement,
}: OwnProps) => {
  const [url, setUrl] = useState("");
  const [newTab, toggleNewTab] = useState<boolean>(true);

  const handleConfirm = () => {
    PuxEditorHelper.setExternalLinkElement(editor, url, newTab);
    setIsExternalLinkDialogOpen(false);
  };

  return (
    <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
      {activeElement !== null ? (
        <>
          <TextField
            label="URL (com http...)"
            type="url"
            fullWidth
            disabled
            value={activeElement.url}
            size="small"
          />
          <Switch
            disabled
            size="small"
            checked={activeElement.new_tab}
            onChange={() => toggleNewTab(!newTab)}
          />
        </>
      ) : (
        <>
          <TextField
            size="small"
            label="URL (com http...)"
            type="url"
            fullWidth
            value={url}
            onChange={(event) => {
              setUrl(event.target.value);
            }}
          />
          <Switch
            size="small"
            checked={newTab}
            onChange={() => toggleNewTab(!newTab)}
          />
          <Button onClick={handleConfirm} disabled={!isValidHttpUrl(url)}>
            Ok
          </Button>
        </>
      )}
    </div>
  );
};

export default ExternalLinkDialog;
