import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import { getLoggedStatus } from "~/features/server/serverSlice";
import App from "~/main/App";
import Login from "~/main/Login";

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const PuxadinhoRaw = ({ logged }: Props) => {
  if (typeof logged !== "undefined") {
    return <App />;
  }

  return <Login />;
};

const mapStateToProps = (state: RootState) => ({
  logged: getLoggedStatus(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const Puxadinho = connector(PuxadinhoRaw);

export default Puxadinho;
