import { PivotListProps, ModelListProps } from "~/types";
import { RootState } from "~/app/store";
import { combineReducers } from "@reduxjs/toolkit";

import listsReducer, * as fromLists from "./Lists/listsSlice";
import pivotsReducer, * as fromPivots from "./pivotsSlice";
import entitiesReducer, * as fromEntities from "./Entity/entitiesSlice";
import entitiesViewReducer, * as fromEntitiesView from "./EntityView/entitiesViewSlice";

const dataSliceReducer = combineReducers({
  lists: listsReducer,
  pivots: pivotsReducer,
  entities: entitiesReducer,
  views: entitiesViewReducer,
});

export const getPivots = (
  state: RootState,
  pivotList: PivotListProps,
  id: string
) => fromPivots.getPivots(state.data.pivots, pivotList, id);

export const selectEntitiesFromList = (
  state: RootState,
  modelList: ModelListProps
) => fromLists.selectEntitiesFromList(state.data.lists, modelList);

export const selectEntitiesFromLists = (
  state: RootState,
  modelLists: ModelListProps[]
) => fromLists.selectEntitiesFromLists(state.data.lists, modelLists);

export const selectEntityFromList = (
  state: RootState,
  model: string,
  id: string
) => fromLists.selectEntityFromList(state.data.lists, model, id);

export const selectEntity = (state: RootState, model: string, id: string) =>
  fromEntities.selectEntity(state.data.entities, model, id);

export const selectEntityView = (state: RootState, model: string, id: string) =>
  fromEntitiesView.selectEntityView(state.data.views, model, id);

export const getLastViewedEntity = (state: RootState) =>
  fromEntitiesView.getLastViewedEntity(state.data.views);

export default dataSliceReducer;
