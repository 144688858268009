import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import { connect, ConnectedProps } from "react-redux";

import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormatClearIcon from "@mui/icons-material/FormatClear";
import { useRef } from "react";
import Typography from "@mui/material/Typography";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface DurationFieldType {
  column: string;
  type: string;
  placeholder: string;
  rules?: string;
  always?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: DurationFieldType;
  onChange: (column: string, value: string | null, locale?: string) => void;
  locale: string;
};

type Props = PropsFromRedux & OwnProps;

const getIsoDuration = (hours: number | null, minutes: number | null) => {
  if ((hours !== null && hours > 0) || (minutes !== null && minutes > 0)) {
    return `PT${hours !== null && hours > 0 ? `${hours}H` : ""}${
      minutes !== null && minutes > 0 ? `${minutes}M` : ""
    }`;
  }
  return null;
};

const reg = /PT([0-9]*H)?([0-9]*M)?/;

const getMinutesFromIsoDuration = (isoDuration: string) => {
  const match = isoDuration.match(reg);
  return match !== null && typeof match[2] !== "undefined"
    ? String(match[2]).slice(0, -1)
    : "";
};

const getHoursFromIsoDuration = (isoDuration: string) => {
  const match = isoDuration.match(reg);
  return match !== null && typeof match[1] !== "undefined"
    ? String(match[1]).slice(0, -1)
    : "";
};

const DurationFieldRaw = ({ field, draftValue, onChange, locale }: Props) => {
  const hoursInput = useRef<HTMLInputElement>(null);
  const minutesInput = useRef<HTMLInputElement>(null);

  return (
    <>
      <Typography gutterBottom>{field.placeholder}</Typography>
      <div style={{ display: "flex", gap: 10, width: "100%" }}>
        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel htmlFor={`field-${field.column}-${field.type}`}>
            {`Horas`}
          </InputLabel>
          <OutlinedInput
            inputRef={hoursInput}
            id={`field-${field.column}-${field.type}-hours`}
            value={
              typeof draftValue !== "undefined" && draftValue !== null
                ? getHoursFromIsoDuration(draftValue)
                : ""
            }
            onChange={(event) => {
              const minutesValue =
                minutesInput.current !== null
                  ? minutesInput.current.value
                  : null;
              onChange(
                field.column,
                getIsoDuration(
                  parseInt(event.target.value),
                  minutesValue !== null ? parseInt(minutesValue) : null
                ),
                locale
              );
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="clears field"
                  onClick={() => {
                    const minutesValue =
                      minutesInput.current !== null
                        ? minutesInput.current.value
                        : null;
                    onChange(
                      field.column,
                      getIsoDuration(
                        null,
                        minutesValue !== null ? parseInt(minutesValue) : null
                      ),
                      locale
                    );
                  }}
                  size="small"
                  edge="end"
                >
                  <FormatClearIcon fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            }
            label={`Horas ${field.placeholder}`}
          />
        </FormControl>

        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel htmlFor={`field-${field.column}-${field.type}`}>
            {`Minutos`}
          </InputLabel>
          <OutlinedInput
            inputRef={minutesInput}
            id={`field-${field.column}-${field.type}-minutes`}
            value={
              typeof draftValue !== "undefined" && draftValue !== null
                ? getMinutesFromIsoDuration(draftValue)
                : ""
            }
            onChange={(event) => {
              const hoursValue =
                hoursInput.current !== null ? hoursInput.current.value : null;

              onChange(
                field.column,
                getIsoDuration(
                  hoursValue !== null ? parseInt(hoursValue) : null,
                  parseInt(event.target.value)
                ),
                locale
              );
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="clears field"
                  onClick={() => {
                    const hoursValue =
                      hoursInput.current !== null
                        ? hoursInput.current.value
                        : null;
                    onChange(
                      field.column,
                      getIsoDuration(
                        hoursValue !== null ? parseInt(hoursValue) : null,
                        null
                      ),
                      locale
                    );
                  }}
                  size="small"
                  edge="end"
                >
                  <FormatClearIcon fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            }
            label={`Minutos ${field.placeholder}`}
          />
        </FormControl>
      </div>
    </>
  );
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId, field }: OwnProps
) => ({
  draftValue: getDraftColumn(state, entityModel, entityId, field.column),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const DurationField = connector(DurationFieldRaw);
export default DurationField;
