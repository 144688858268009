import { clearDraft } from "~/redux/actions";
import { RootState, AppThunk } from "~/app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { selectEntity } from "~/features/data/dataSlice";
import { ModelListProps, Entity, NormalizedListResponse, Draft } from "~/types";
import isEqual from "lodash/isEqual";
import {
  fetchEntitySuccess,
  setPublishSuccess,
} from "~/features/data/Entity/entitiesSlice";
import { fetchListSuccess } from "~/features/data/Lists/listsSlice";

const newEntityId = "#new-entity#";
const newEntityObject = {};

interface ColumnChanged {
  model: string;
  id: string | undefined;
  column: string;
  value: string | number | null | object;
  locale?: string;
}

interface DraftsList {
  [id: string]: Draft;
}

interface DraftsSlice {
  [model: string]: DraftsList;
}

const initialState: DraftsSlice = {
  sharedModel: {
    [newEntityId]: newEntityObject,
  },
};

export const draftsSlice = createSlice({
  name: "drafts",
  initialState,
  reducers: {
    resetDraftForNewEntity: (state, action: PayloadAction<ModelListProps>) => {
      const modelList = action.payload;
      if (typeof state[modelList.model] === "undefined") {
        state[modelList.model] = {};
      }

      state[modelList.model][newEntityId] = newEntityObject;
    },
    setColumnValue: (state, action: PayloadAction<ColumnChanged>) => {
      const { model, id, column, value } = action.payload;

      const idInUse = typeof id !== "undefined" ? id : newEntityId;

      // if (typeof locale !== 'undefined') {
      //   if (typeof state[model][idInUse].translations[locale] === 'undefined') {
      //     state[model][idInUse].translations[locale] = {};
      //   }

      //   state[model][idInUse].translations[locale][column] = value;
      // } else {

      if (
        typeof state[model] !== "undefined" &&
        typeof state[model][idInUse] !== "undefined"
      ) {
        state[model][idInUse][column] = value;
      }

      // }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchListSuccess,
        (
          state,
          action: PayloadAction<{
            modelList: ModelListProps;
            fetchTime: number;
            response: NormalizedListResponse<Entity>;
          }>
        ) => {
          const { modelList } = action.payload;

          if (typeof state[modelList.model] === "undefined") {
            state[modelList.model] = {};
            state[modelList.model][newEntityId] = newEntityObject;
          }
        }
      )
      .addCase(
        fetchEntitySuccess,
        (
          state,
          action: PayloadAction<{
            modelList: ModelListProps;
            id: string;
            response: Entity;
          }>
        ) => {
          const {
            modelList: { model },
            id,
            response,
          } = action.payload;

          if (typeof state[model] === "undefined") {
            state[model] = {};
            state[model][newEntityId] = newEntityObject;
          }

          state[model][id] = response;
        }
      )
      .addCase(
        setPublishSuccess,
        (
          state,
          action: PayloadAction<{
            model: string;
            id: string;
            response: Entity;
          }>
        ) => {
          const { model, id, response } = action.payload;

          if (
            typeof state[model] !== "undefined" &&
            typeof state[model][id] !== "undefined" &&
            typeof response.publish !== "undefined"
          ) {
            state[model][id].publish = response.publish;
          }
        }
      )
      .addCase(clearDraft, (state, action: PayloadAction<string>) => {
        const model = action.payload;

        if (typeof state[model] === "undefined") {
          state[model] = {};
        }
        state[model][newEntityId] = newEntityObject;
      })
      .addDefaultCase((_state, _action) => {
        // console.log({ state, action });
      });
  },
});

export const { setColumnValue, resetDraftForNewEntity } = draftsSlice.actions;

export const getDraft = (
  state: RootState,
  model: string,
  id: string | undefined
) => {
  return typeof state.drafts[model] !== "undefined"
    ? typeof id !== "undefined"
      ? state.drafts[model][id]
      : state.drafts[model][newEntityId]
    : undefined;
};

export const getDraftColumn = (
  state: RootState,
  model: string,
  id: string | undefined,
  column: string
) => {
  const draft = getDraft(state, model, id);

  if (typeof draft !== "undefined") {
    // if (
    //   typeof locale !== 'undefined' &&
    //   typeof draft.translations[locale] !== 'undefined'
    // ) {
    //   return draft.translations[locale][column];
    // }

    if (typeof draft[column] !== "undefined") {
      return draft[column];
    }
  }

  return undefined;
};

export const getDraftColumns = (
  state: RootState,
  model: string,
  id: string | undefined,
  fields: [string, boolean][]
) => {
  return fields.map((field) => getDraftColumn(state, model, id, field[0]));
};

export const copyValues =
  (
    attributes: ColumnChanged,
    copyFrom: [string, boolean][],
    callback: (copyFromValues: string[]) => string
  ): AppThunk =>
  async (dispatch, getState) => {
    const { model, id } = attributes;

    const copyFromValues = copyFrom.map((field) =>
      getDraftColumn(getState(), model, id, field[0])
    );

    dispatch(
      setColumnValue({ ...attributes, value: callback(copyFromValues) })
    );
  };

// export const getDraftColumns2 =
//   (
//     model: string,
//     id: string | undefined,
//     fields: [string, boolean][],
//     locale?: string
//   ): AppThunk =>
//   async (dispatch, getState) => {
//     //this is not good
//     const state = getState();

//     return fields.map((field) =>
//       getDraftColumn(state, model, id, field[0], field[1] ? locale : undefined)
//     );
//   };

export const isDraftSaveable = (state: RootState, model: string, id: string) =>
  !isEqual(getDraft(state, model, id), selectEntity(state, model, id));

export default draftsSlice.reducer;
