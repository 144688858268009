import { useState } from "react";
import { AnyAction } from "redux";
import { connect, ConnectedProps } from "react-redux";
// import { makeEntitiesFromListSelector } from "~/features/data/dataSlice";
import {
  updatePosition,
  makeEntitiesFromListSelector,
} from "~/features/data/Lists/listsSlice";
import { setEntityPublish } from "~/features/data/Entity/entitiesSlice";

import matchSorter from "match-sorter";
// import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
// import { FocusZone } from 'office-ui-fabric-react/lib/FocusZone';
// import { List } from 'office-ui-fabric-react/lib/List';
// import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';

import styles from "./EntitiesList.module.css";
import EntitiesListHeader from "./EntitiesListHeader";
import { RootState } from "~/app/store";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import ListItemLink from "~/app/components/ListItemLink";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import GpsNotFixedIcon from "@mui/icons-material/GpsNotFixed";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import MobiledataOffIcon from "@mui/icons-material/MobiledataOff";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { getModel } from "~/features/layout/layoutSlice";
import { ModelListProps } from "~/types";
import ListItemImage from "~/app/components/ListItemImage";

type PropsFromRedux = ConnectedProps<typeof connector>;
type OwnProps = {
  selectedEntityId?: string;
  baseUrl: string;
  parentModel?: string;
  parentEntityId?: string;
  activeModel: string;
};

type Props = PropsFromRedux & OwnProps;

const EntitiesListRaw = ({
  list,
  model,
  updatePosition,
  setEntityPublish,
  parentModel,
  parentEntityId,
  selectedEntityId,
  // pushPositions,
  baseUrl,
  activeModel,
}: Props) => {
  const [searchString, setSearchString] = useState("");
  const [movingId, setMovingId] = useState("");

  let sortedList: object[];

  if (searchString !== "") {
    // sortedList = matchSorter(list, searchString, {
    //   keys: ["px_title"],
    // });

    const terms = searchString.split(" ");

    sortedList = terms.reduceRight((results, term) => {
      return matchSorter(results, term, { keys: ["px_title"] });
    }, list);
  } else {
    sortedList = list;
  }

  const callUpdatePosition = (id: string) => {
    if (movingId !== "") {
      // updatePosition({ activeModel, id, movingId });
      updatePosition(id, movingId);
      setMovingId("");
    }
  };

  const renderRow = (props: ListChildComponentProps) => {
    const { index, style, data } = props;

    const entity = data.list[index];

    const getPublishButton = () => {
      return (
        <Switch
          checked={Boolean(entity.publish)}
          key={`${index}-switch`}
          size="small"
          onChange={() => {
            setEntityPublish(
              { model: activeModel, parentModel, parentEntityId },
              entity.id,
              !entity.publish
            );
          }}
        />
      );
    };

    const renderSecondaryAction = () => {
      let actions: JSX.Element[] = [];
      if (typeof model === "undefined") {
        return undefined;
      }

      if (model.hasPublish) {
        actions = [...actions, getPublishButton()];
      }

      if (model.hasOrder) {
        actions = [
          ...actions,
          movingId === entity.id ? (
            <IconButton
              key={`${index}-editing`}
              aria-label="Moving"
              size="small"
              onClick={() => setMovingId("")}
            >
              <GpsFixedIcon />
            </IconButton>
          ) : (
            <IconButton
              key={`${index}-moving`}
              aria-label="Set to move"
              size="small"
              color="secondary"
              onClick={() => setMovingId(entity.id)}
            >
              <MobiledataOffIcon />
            </IconButton>
          ),
          <IconButton
            key={`${index}-anchor`}
            aria-label="Set as anchor"
            size="small"
            onClick={() => callUpdatePosition(entity.id)}
          >
            <GpsNotFixedIcon />
          </IconButton>,
        ];
      }

      return actions;
    };

    return (
      <ListItemLink
        to={`${entity.id}`}
        key={index}
        style={style}
        primary={entity.px_title}
        primaryTypographyProps={{
          variant: "subtitle2",
          style: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
        secondary={entity.px_title_aux}
        secondaryTypographyProps={{
          variant: "caption",
          style: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
        avatar={<ListItemImage model={"image"} id={entity.px_image_id} />}
        secondaryAction={renderSecondaryAction()}
        itemId={entity.id}
        selectedItemId={selectedEntityId}
        height={72}
      />
    );
  };

  // console.log("re-rendered list");

  return typeof model !== "undefined" ? (
    <div className={styles.container}>
      {/* <button
        onClick={() => {
          pushPositions(activeModel);
        }}
      >
        Save
      </button> */}
      <EntitiesListHeader
        activeModel={activeModel}
        searchString={searchString}
        setSearchString={setSearchString}
        baseUrl={baseUrl}
        parentModel={parentModel}
        parentEntityId={parentEntityId}
      />

      <div className={styles.listContainer}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              itemData={{ list: sortedList }}
              height={height ?? 0}
              width={width ?? 0}
              itemSize={72}
              itemCount={sortedList.length}
            >
              {renderRow}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
    </div>
  ) : null;
};

const makeMapStateToProps = () => {
  const selectEntitiesFromList = makeEntitiesFromListSelector();
  const mapStateToProps = (
    state: RootState,
    { activeModel, parentModel, parentEntityId }: OwnProps
  ) => ({
    list: selectEntitiesFromList(state.data.lists, {
      model: activeModel,
      parentModel,
      parentEntityId,
    }),
    model: getModel(state, activeModel),
  });
  return mapStateToProps;
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  { activeModel, parentModel, parentEntityId }: OwnProps
) => ({
  updatePosition: (anchorId: string, movingId: string): void => {
    dispatch(
      updatePosition({
        modelList: {
          model: activeModel,
          parentModel,
          parentEntityId,
        },
        anchorId,
        movingId,
      })
    );
  },
  setEntityPublish: (
    modelList: ModelListProps,
    id: string,
    publish: boolean
  ): void => {
    dispatch(setEntityPublish(modelList, id, publish));
  },
  // updatePosition,
  // pushPositions: (model: string) => dispatch(pushPositions(model)),
});

const connector = connect(makeMapStateToProps, mapDispatchToProps);

const EntitiesList = connector(EntitiesListRaw);

export default EntitiesList;
