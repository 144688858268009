import { Fragment } from "react";
import {
  Descendant,
  RecipeImageType,
  RecipeTextType,
  RecipeEmbedType,
} from "~/types/views";
import { PostBlockType } from "~/types/views";

type Props = {
  postBlock: PostBlockType;
  title: string;
};

const PostHeadersOverview = ({ postBlock, title }: Props) => {
  return (
    <>
      <h2>[ Overview experimental ]</h2>
      <ul>
        <li>h1: {title}</li>
        <ul>
          <li>h2: {postBlock.title}</li>
          <ul>
            {postBlock.blockComponents.map(
              (blockComponent, blockComponentIndex) => {
                switch (blockComponent.type) {
                  case "step":
                    return null;

                  case "text": {
                    const text = blockComponent.content as RecipeTextType;
                    const h3Title = parseNodes(text.title);
                    return (
                      <Fragment key={blockComponentIndex}>
                        {h3Title.length ? <li>h3: {h3Title}</li> : null}
                        <ul>{parseNodes(text.text)}</ul>
                      </Fragment>
                    );
                  }

                  case "youtube":
                  case "instagram":
                  case "twitter": {
                    const embed = blockComponent.content as RecipeEmbedType;
                    return embed.title !== "" ? (
                      <li key={blockComponentIndex}>h3: {embed.title}</li>
                    ) : (
                      <li key={blockComponentIndex}>Embed sem título</li>
                    );
                  }

                  case "image": {
                    const image = blockComponent.content as RecipeImageType;
                    return image.title !== "" ? (
                      <li key={blockComponentIndex}>h3: {image.title}</li>
                    ) : null;
                  }

                  default:
                    return null;
                }
              }
            )}
          </ul>
        </ul>
      </ul>
    </>
  );
};

export const parseNodes = (nodes: Descendant[]) =>
  nodes.map((node, nodeIndex) => {
    return <Fragment key={nodeIndex}>{getNodeChildren(node)}</Fragment>;
  });

const getNodeChildren = (node: any) => {
  if (typeof node.text !== "undefined") {
    return node.text
      .split("\n")
      .map((text: string, textIndex: number, texts: string[]) => (
        <Fragment key={textIndex}>
          {text}
          {typeof texts[textIndex + 1] !== "undefined" ? <br /> : null}
        </Fragment>
      ));
  }

  const children = node.children.map((n: any, nIndex: number) => (
    <Fragment key={nIndex}>{getNodeChildren(n)}</Fragment>
  ));

  switch (node.type) {
    case "span":
      return children;
    case "subtitle":
      return <li>h4: {children}</li>;
    default:
      return null;
  }
};

export default PostHeadersOverview;
