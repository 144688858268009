import { useCallback, useState } from "react";
import { createEditor, Descendant } from "slate";
import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import { connect, ConnectedProps } from "react-redux";
// import PuxEditor from "~/special/PuxEditor/PuxEditor";
import styles from "~/special/PuxEditor/PuxEditor.module.scss";
import {
  Slate,
  Editable,
  withReact,
  DefaultElement,
  ReactEditor,
} from "slate-react";
import {
  ATTENTION,
  EXTERNAL_LINK,
  IDIOMATIC,
  INTERNAL_LINK,
  SPAN,
  STRESS,
  STRONG,
} from "~/special/PuxEditor/utils/elementsLists";
import { PuxEditorHelper } from "~/special/PuxEditor/utils/PuxEditorHelper";
import ExternalLinkElement from "~/special/PuxEditor/elements/ExternalLinkElement";
import InternalLinkElement from "~/special/PuxEditor/elements/InternalLinkElement";
import AttentionElement from "~/special/PuxEditor/elements/AttentionElement";
import IdiomaticElement from "~/special/PuxEditor/elements/IdiomaticElement";
import StressElement from "~/special/PuxEditor/elements/StressElement";
import StrongElement from "~/special/PuxEditor/elements/StrongElement";
import Toolbar from "~/special/PuxEditor/Toolbar";
import SpanElement from "~/special/PuxEditor/elements/SpanElement";
import isHotkey from "is-hotkey";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// import PuxEditor from "../special/PuxEditor/PuxEditor";

type PropsFromRedux = ConnectedProps<typeof connector>;
export interface SimpleSlateFieldType {
  column: string;
  type: string;
  placeholder: string;
  rules?: string;
  always?: boolean;
  internalLinkDependencies: string[];
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: SimpleSlateFieldType;
  // draft: any;
  onChange: (
    column: string,
    value: Descendant[] | null,
    locale?: string
  ) => void;
  locale: string;
};

type Props = PropsFromRedux & OwnProps;

const defaultValue = [
  {
    type: "span",
    children: [{ text: "" }],
  },
];

const fixValue = (value: Descendant[]) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (value[0] && value[0]?.type === "paragraph") {
    const newValue = [JSON.parse(JSON.stringify(value[0]))];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newValue[0].type = "span";
    return newValue;
  }

  return value;
};

const SimpleSlateFieldRaw = ({
  field,
  draftValue,
  entityId,
  onChange,
  locale,
}: Props) => {
  const [editor] = useState(() => withReact(createEditor() as ReactEditor));
  editor.isInline = PuxEditorHelper.isInline(editor);
  // const [value, setValue] = useState<Descendant[]>(
  //   typeof draftValue !== "undefined" && draftValue !== null
  //     ? typeof draftValue === "string"
  //       ? JSON.parse(draftValue)
  //       : draftValue
  //     : defaultValue
  // );

  const renderElement = useCallback((props: any) => {
    switch (props.element.type) {
      case SPAN:
        return <SpanElement {...props} />;
      // case PARAGRAPH:
      //   return <ParagraphElement {...props} />;
      case EXTERNAL_LINK:
        return <ExternalLinkElement {...props} />;
      case INTERNAL_LINK:
        return <InternalLinkElement {...props} />;
      case ATTENTION:
        return <AttentionElement {...props} />;
      case STRONG:
        return <StrongElement {...props} />;
      case IDIOMATIC:
        return <IdiomaticElement {...props} />;
      case STRESS:
        return <StressElement {...props} />;
      default:
        return <DefaultElement {...props} />;
    }
  }, []);

  console.log(draftValue);

  return (
    <div className={styles.container}>
      <FormControl component="fieldset">
        <FormLabel component="p">{field.placeholder}</FormLabel>

        <Slate
          key={typeof entityId !== "undefined" ? entityId : "new-entity"}
          editor={editor}
          initialValue={
            // value
            typeof draftValue !== "undefined" && draftValue !== null
              ? typeof draftValue === "string"
                ? fixValue(JSON.parse(draftValue))
                : fixValue(draftValue)
              : defaultValue
          }
          onChange={(value: Descendant[]) => {
            onChange(field.column, fixValue(value), locale);
            // setValue(value);
          }}
        >
          <div className={styles.fieldContainer}>
            <Editable
              className={styles.editor}
              renderElement={renderElement}
              onKeyDown={(event) => {
                if (isHotkey("shift+enter", event)) {
                  event.preventDefault();
                }

                if (isHotkey("enter", event)) {
                  event.preventDefault();
                }
              }}
              // onBlur={() => {
              //   onChange(field.column, value, locale);
              // }}
            />
          </div>
          <div className={styles.toolbarContainer}>
            <Toolbar field={field} />
          </div>
        </Slate>
      </FormControl>
    </div>
  );
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId, field }: OwnProps
) => ({
  draftValue: getDraftColumn(state, entityModel, entityId, field.column),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const SimpleSlateField = connector(SimpleSlateFieldRaw);
export default SimpleSlateField;
