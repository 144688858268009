import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import { connect, ConnectedProps } from "react-redux";
import { formatISO, isValid, parseISO } from "date-fns";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface DateFieldType {
  column: string;
  type: string;
  placeholder: string;
  rules?: string;
  always?: boolean;
  default?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: DateFieldType;
  onChange: (column: string, value: string | null, locale?: string) => void;
  locale: string;
};

type Props = PropsFromRedux & OwnProps;

const createDateFromIsoDate = (isoDate: string): Date => {
  return parseISO(isoDate);
};

const getFormatedDate = (date: Date): string => {
  return formatISO(date, { representation: "date" });
};

const DateFieldRaw = ({ field, draftValue, onChange }: Props) => {
  return (
    // <div>datefield</div>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={field.placeholder}
        value={
          typeof draftValue !== "undefined" && draftValue !== null
            ? createDateFromIsoDate(draftValue)
            : null
        }
        onChange={(date) => {
          onChange(
            field.column,
            date !== null
              ? isValid(date)
                ? getFormatedDate(date)
                : null
              : // : typeof value !== "undefined"
                // ? value
                null
          );
        }}
        // renderInput={(params: any) => <TextField {...params} fullWidth />}
        // clearable={true}
        format="dd/MM/yyyy"
      />
    </LocalizationProvider>
  );
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId, field }: OwnProps
) => ({
  draftValue: getDraftColumn(state, entityModel, entityId, field.column),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
const DateField = connector(DateFieldRaw);
export default DateField;
