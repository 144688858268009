import {
  Descendant,
  RPBlockComponentType,
  RecipeImageType,
  RecipeStepType,
  RecipeTextType,
  RecipeTwitterType,
  RecipeVideoType,
  RecipeInstagramType,
} from "~/types/views";
import { Link } from "react-router-dom";
import styles from "./RecipeBlockComponent.module.scss";
import { Fragment } from "react";
import { getImageUrl } from "~/shared/ImageTools";
// import LiteYouTubeEmbed from "react-lite-youtube-embed";

type Props = {
  recipeBlockComponent: RPBlockComponentType;
  link: string;
};

const RecipeBlockComponent = ({ recipeBlockComponent, link }: Props) => {
  switch (recipeBlockComponent.type) {
    case "step": {
      const recipeStep = recipeBlockComponent.content as RecipeStepType;
      return (
        <li>
          <Link to={link} className={styles.linkDot} />
          {parseNodes(recipeStep.text)}
        </li>
      );
    }

    case "quote": {
      const quote = recipeBlockComponent.content as RecipeStepType;
      return (
        <blockquote className={styles.blockquote}>
          {parseNodes(quote.text)}
        </blockquote>
      );
    }

    case "text": {
      const recipeText = recipeBlockComponent.content as RecipeTextType;
      const h5Title = parseNodes(recipeText.title);
      return (
        <div>
          {h5Title.length ? <h5>{h5Title}</h5> : null}
          {parseNodes(recipeText.text)}
        </div>
      );
    }

    case "youtube": {
      const recipeVideo = recipeBlockComponent.content as RecipeVideoType;
      return (
        <div>
          <a
            target="_blank"
            href={`https://www.youtube.com/watch?v=${recipeVideo.embed_ref}`}
          >
            Youtube
          </a>
        </div>
      );
    }

    case "twitter": {
      const recipeTwitter = recipeBlockComponent.content as RecipeTwitterType;
      return (
        <div>
          <a target="_blank" href={`${recipeTwitter.embed_ref}`}>
            Twitter
          </a>
        </div>
      );
    }

    case "instagram": {
      const recipeInstagram =
        recipeBlockComponent.content as RecipeInstagramType;
      return (
        <div>
          <a target="_blank" href={`${recipeInstagram.embed_ref}`}>
            Instagram
          </a>
        </div>
      );
    }

    case "image": {
      const recipeImage = recipeBlockComponent.content as RecipeImageType;

      if (recipeImage.url !== null) {
        return (
          <a
            href={recipeImage.url}
            target={recipeImage.newTab ? "_blank" : "_self"}
            rel="noreferrer"
          >
            {recipeImage.title !== "" ? <h4>{recipeImage.title}</h4> : null}
            {recipeImage.image ? (
              <img
                src={getImageUrl(`${recipeImage.image.url}`)}
                alt={recipeImage.image.alt}
                width={900}
                height={600}
              />
            ) : null}
          </a>
          // <>
          //   <h4>{title}</h4>
          //   <div dangerouslySetInnerHTML={{ __html: String(video) }} />
          // </>
        );
      }

      return (
        <>
          {recipeImage.title !== "" ? <h4>{recipeImage.title}</h4> : null}
          {recipeImage.image ? (
            <img
              src={getImageUrl(`${recipeImage.image.url}`)}
              alt={recipeImage.image.alt}
              width={900}
              height={600}
            />
          ) : null}
        </>
      );
    }

    default:
      return "Unknown: " + recipeBlockComponent.type;
  }
};

export const parseNodes = (nodes: Descendant[]) =>
  nodes.map((node, nodeIndex) => {
    return <Fragment key={nodeIndex}>{getNodeChildren(node)}</Fragment>;
  });

const getNodeChildren = (node: any) => {
  if (typeof node.text !== "undefined") {
    return node.text
      .split("\n")
      .map((text: string, textIndex: number, texts: string[]) => (
        <Fragment key={textIndex}>
          {text}
          {typeof texts[textIndex + 1] !== "undefined" ? <br /> : null}
        </Fragment>
      ));
  }

  const children = node.children.map((n: any, nIndex: number) => (
    <Fragment key={nIndex}>{getNodeChildren(n)}</Fragment>
  ));

  //   return <div key={nodeIndex}>Node!!!</div>;
  switch (node.type) {
    case "span":
      return <>{children}</>;
    case "paragraph":
      return <p>{children}</p>;
    case "link-internal":
      return <a href={node.url}>{children}</a>;
    case "link-external":
      return <a href={node.url}>{children}</a>;
    case "subtitle":
      return <h6>{children}</h6>;
    case "stress":
      return <em>{children}</em>;
    case "strong":
      return <strong>{children}</strong>;
    case "idiomatic":
      return <i>{children}</i>;
    case "attention":
      return <b>{children}</b>;
    case "list-item":
      return <li>{children}</li>;
    case "unordered-list":
      return <ul>{children}</ul>;
    case "ordered-list":
      return <ol>{children}</ol>;
    default:
      return children;
  }
};

export default RecipeBlockComponent;
