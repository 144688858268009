import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import {
  isErrorDialogOpen,
  getErrorDialogMessage,
} from "~/features/interface/interfaceSlice";

import { hideErrorDialog } from "~/features/interface/ErrorDialog/errorDialogSlice";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const ErrorDialogRaw = ({
  hideErrorDialog,
  isErrorDialogOpen,
  errorDialogMessage,
}: Props) => {
  const handleClose = () => {
    hideErrorDialog();
  };

  const getListItems = () => {
    const listItems = [];
    for (const [key, value] of Object.entries(errorDialogMessage)) {
      listItems.push(
        <ListItem key={key}>
          <ListItemText
            primary={key}
            secondary={value.map((error: string, index: number) => (
              <span key={index}>
                {error}
                <br />
              </span>
            ))}
          />
        </ListItem>
      );
    }

    return listItems;
  };

  return (
    <div>
      <Dialog
        open={isErrorDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={0}
      >
        <DialogTitle id="alert-dialog-title">{"Ops!"}</DialogTitle>

        <DialogContent>
          {Object.keys(errorDialogMessage).length ? (
            <List>{getListItems()}</List>
          ) : (
            <DialogContentText id="alert-dialog-description">
              Unknown server error.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  errorDialogMessage: getErrorDialogMessage(state),
  isErrorDialogOpen: isErrorDialogOpen(state),
});

const mapDispatchToProps = {
  hideErrorDialog,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const ErrorDialog = connector(ErrorDialogRaw);

export default ErrorDialog;
