import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import { connect, ConnectedProps } from "react-redux";
// import TextField from '@mui/material/TextField';
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface NumberFieldType {
  column: string;
  type: string;
  placeholder: string;
  rules?: string;
  always?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: NumberFieldType;
  // draft: any;
  onChange: (column: string, value: null | number, locale?: string) => void;
  locale: string;
};

type Props = PropsFromRedux & OwnProps;

const getNumber = (value: string | null) =>
  value === "" || value === null ? null : Number(value);

const NumberFieldRaw = ({ field, draftValue, onChange, locale }: Props) => {
  return (
    // <TextField
    //   fullWidth
    //   type="number"
    //   label={field.placeholder}
    //   onChange={(event) =>
    //     onChange(field.column, getNumber(event.target.value), locale)
    //   }
    //   value={
    //     typeof draftValue !== 'undefined' && draftValue !== null
    //       ? draftValue
    //       : ''
    //   }
    // />
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel htmlFor={`field-${field.column}-${field.type}`}>
        {field.placeholder}
      </InputLabel>
      <OutlinedInput
        id={`field-${field.column}-${field.type}`}
        type="number"
        value={
          typeof draftValue !== "undefined" && draftValue !== null
            ? draftValue
            : ""
        }
        onChange={(event) =>
          onChange(field.column, getNumber(event.target.value), locale)
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="clears field"
              onClick={() => {
                onChange(field.column, null, locale);
              }}
              size="small"
              edge="end"
            >
              <HighlightOffIcon fontSize="inherit" />
            </IconButton>
          </InputAdornment>
        }
        label={field.placeholder}
      />
    </FormControl>
  );
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId, field }: OwnProps
) => ({
  draftValue: getDraftColumn(state, entityModel, entityId, field.column),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const NumberField = connector(NumberFieldRaw);
export default NumberField;
