import { RootState } from 'app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InterfaceType {
  logged?: boolean;
}

const initialState: InterfaceType = {};

export const serverSlice = createSlice({
  name: 'server',
  initialState,
  reducers: {
    setLoggedStatus: (state, action: PayloadAction<boolean>) => {
      state.logged = action.payload;
    },
  },
});

export const getLoggedStatus = (state: RootState): boolean | undefined =>
  state.server.logged;

export const { setLoggedStatus } = serverSlice.actions;
export default serverSlice.reducer;
