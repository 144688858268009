import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import { connect, ConnectedProps } from "react-redux";
import TextField from "@mui/material/TextField";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface MultilineFieldType {
  column: string;
  type: string;
  placeholder: string;
  rules?: string;
  always?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: MultilineFieldType;
  // draft: any;
  onChange: (column: string, value: string, locale?: string) => void;
  locale: string;
};

type Props = PropsFromRedux & OwnProps;

const MultilineFieldRaw = ({ field, draftValue, onChange, locale }: Props) => {
  return (
    <TextField
      label={field.placeholder}
      onChange={(event) => onChange(field.column, event.target.value, locale)}
      value={
        typeof draftValue !== "undefined" && draftValue !== null
          ? draftValue
          : ""
      }
      multiline
      fullWidth
      size="small"
      maxRows={10}
    />
  );
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId, field }: OwnProps
) => ({
  draftValue: getDraftColumn(state, entityModel, entityId, field.column),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const MultilineField = connector(MultilineFieldRaw);
export default MultilineField;
