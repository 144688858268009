import { connect, ConnectedProps } from "react-redux";
import { selectEntity } from "~/features/data/dataSlice";
import { fetchList } from "~/features/data/Lists/listsSlice";
// import Entity from '~/features/entities/Entity';
import styles from "./Navigator.module.css";
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import NavigatorWithRouter from "./NavigatorWithRouter";
import { RootState } from "~/app/store";
import { getModelComponents } from "~/features/layout/layoutSlice";
import ModelComponents from "~/features/layout/ModelComponents";
import NavigatorDescription from "./NavigatorDescription";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

type PropsFromRedux = ConnectedProps<typeof connector>;

type PathParams = {
  activeModel: string;
  id?: string;
};

type OwnProps = {
  parentModel?: string;
  parentEntityId?: string;
  baseUrl: string;
};

type PathAndOwnProps = RouteComponentProps<PathParams> & OwnProps;

type Props = PathAndOwnProps & PropsFromRedux;

const isRoot = (parentModel: string | undefined) =>
  !(typeof parentModel !== "undefined");

const getCurrentUrl = (baseUrl: string, activeModel: string, id?: string) =>
  baseUrl + "/" + activeModel + (typeof id !== "undefined" ? "/" + id : "/");

const NavigatorRaw = ({
  match: { params, url },
  baseUrl,
  entity,
  entityParent,
  location,
}: Props) => {
  const activeTab = location.pathname.split("/").slice(-2, -1)[0];

  return (
    <AppBar position="static" color="primary" className={styles.container}>
      {/* {typeof entityParent !== 'undefined' ? (
        <h1>{entityParent.title}</h1>
      ) : null} */}
      <div className={styles.content}>
        <Switch>
          <Route path={`${url}/:activeModel/:id?`}>
            {/* <div className={styles.modelContainer}> */}
            <NavigatorWithRouter
              parentModel={params.activeModel}
              parentEntityId={params.id}
              baseUrl={url}
            />
            {/* </div> */}
          </Route>
        </Switch>
      </div>
      <Toolbar className={styles.contentContainer}>
        {/* <div className={styles.contentContainer}> */}
        <div className={styles.descriptionContainer}>
          <NavigatorDescription
            entity={entity}
            entityParent={entityParent}
            baseUrl={getCurrentUrl(baseUrl, params.activeModel)}
            activeModel={params.activeModel}
          />
        </div>
        <div className={styles.componentsContainer}>
          {typeof params.id !== "undefined" && typeof entity !== "undefined" ? (
            <ModelComponents
              // modelComponents={modelComponents}
              activeModel={params.activeModel}
              activeId={params.id}
              activeTab={activeTab}
              baseUrl={getCurrentUrl(baseUrl, params.activeModel, params.id)}
              // entity={entity}
              // activeModel={params.activeModel}
            />
          ) : null}
        </div>
        {/* </div> */}
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (
  state: RootState,
  { parentModel, parentEntityId, match: { params } }: PathAndOwnProps
) => ({
  parentModelComponents: isRoot(parentModel)
    ? getModelComponents(state, params.activeModel + "Root")
    : [],
  modelComponents: getModelComponents(state, params.activeModel),
  entityParent:
    typeof parentModel !== "undefined" && typeof parentEntityId !== "undefined"
      ? selectEntity(state, parentModel, parentEntityId)
      : undefined,
  entity:
    typeof params.id !== "undefined"
      ? selectEntity(state, params.activeModel, params.id)
      : undefined,
});

const mapDispatchToProps = {
  fetchList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const Navigator = connector(NavigatorRaw);

export default Navigator;
