import styles from "./PostLinks.module.scss";
import { RPRelationshipType } from "~/types/views";
import { Link } from "react-router-dom";
import PostRelationship from "../PostRelationship/PostRelationship";

type Props = {
  postLinks: RPRelationshipType[];
  link: string;
};
const PostLinks = ({ postLinks, link }: Props) => {
  if (!postLinks.length) {
    return null;
  }

  return (
    <div className={styles.ctn}>
      <Link to={`${link}/postLink/`}>
        <div className={styles.header}>
          <h5>Conteúdo adicional</h5>
        </div>
      </Link>

      <div className={styles.contentColumns2}>
        {postLinks.map((postLink, postLinkIndex) => (
          <PostRelationship
            key={postLinkIndex}
            relationship={postLink}
            link={`${link}/postLink/${postLink.id}`}
          />
        ))}
      </div>
    </div>
  );
};

export default PostLinks;
