import { Measure } from "~/types";

export const needsNumericValue = (titleSingular: string) => {
  return ["kg", "g", "l", "ml", "litro", "cm"].includes(titleSingular);
};

export const getMeasureCorrectTitle = (
  quantity: number,
  measure: Measure | undefined
) => {
  if (typeof measure !== "undefined" && measure !== null) {
    return (
      (shouldMeasureBePlural(quantity, measure)
        ? measure.title_plural
        : measure.title_singular) + getMeasureSufix(measure)
    );
  }

  return "";
};

function shouldMeasureBePlural(quantity: number, measure: Measure) {
  if (["", null].includes(measure.title_singular.trim()) || quantity >= 2) {
    return true;
  }

  return false;
}

function getMeasureSufix(measure: Measure) {
  if (
    typeof measure !== "undefined" &&
    measure !== null &&
    !["", null].includes(measure.title_singular.trim())
  ) {
    return " de";
  }

  return "";
}
