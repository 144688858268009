import { parseError, parseResponse } from "./entitiesAPI";
import { PuxadinhoResponse, Layout } from "~/types";
import axios from "axios";
import { PuxadinhoError } from "~/shared/PuxadinhoError";

// interface PuxadinhoResponse {
//   statusCode: number;
//   result: any;
//   fetchTime: number;
// }

export const getLayout = async (): Promise<PuxadinhoResponse<Layout>> => {
  // try {
  const response = await axios.get<Layout>(
    `${import.meta.env.VITE_APP_API}/api/layout`
  );

  try {
    return await parseResponse(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }

  //   return await parseResponse(response);
  // } catch (error) {}

  // console.log(response);

  // const fetchTime = Date.now();
  // const ok = await response.ok;
  // const statusCode = await response.status;
  // let result = {};

  // if ((ok && statusCode !== 500) || statusCode === 400) {
  //   result = await response.json();

  //   if (statusCode === 200) {
  //     set('layout', result);
  //     set(`layout-fetch-time`, fetchTime);
  //   }
  // }

  // return {
  //   statusCode,
  //   fetchTime,
  //   result,
  // };

  // return {
  //   statusCode: 200,
  //   fetchTime: 200,
  //   result: {},
  // };
};

// const parseResponse = async (
//   response: AxiosResponse
// ): Promise<PuxadinhoResponse> => {
//   const fetchTime = Date.now();
//   let statusCode = await response.status;
//   let result = {};

//   if (statusCode !== 500) {
//     try {
//       result = await response.data;
//     } catch (err) {
//       throw err;
//     }
//   }

//   return {
//     statusCode,
//     fetchTime,
//     result,
//   };
// };
