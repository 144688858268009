import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  selectEntitiesFromLists,
  selectEntityFromList,
} from "~/features/data/dataSlice";
import { fetchList } from "~/features/data/Lists/listsSlice";
import { RootState } from "~/app/store";
import matchSorter from "match-sorter";
import VirtualizedAutocomplete from "~/special/VirtualizedAutocomplete/VirtualizedAutocomplete";
import {
  getPrefix,
  getSufix,
  mapPrefixToModel,
  splitSearchString,
} from "~/shared/SearchTools";
import { getFriendlyModelName } from "~/shared/StringMappingTools";

type PropsFromRedux = ConnectedProps<typeof connector>;
export interface MultipleEntitySelectType {
  column: string;
  type: string;
  placeholder: string;
  dependencies: string[];
  rules?: string;
  always?: boolean;
}

type MultipleEntitySelectValue = {
  model: string | null;
  id: string | null;
};

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: MultipleEntitySelectType;
  onChange: (model: string | null, id: string | null, locale?: string) => void;
  locale: string;
  disabled?: boolean;
  value: MultipleEntitySelectValue;
};

type FilterInput = {
  inputValue: string;
};

type Props = PropsFromRedux & OwnProps;

const symbolicHomeListedEntity = {
  id: 1,
  model: "home",
  px_title: "Home",
  px_title_aux: "",
};

const symbolicStoreListedEntity = {
  id: 1,
  model: "store",
  px_title: "Loja",
  px_title_aux: "",
};

const getResults = (options: any, input: string) => {
  const terms = input.split(" ");

  return terms.reduceRight((list, term) => {
    return matchSorter(list, term, { keys: ["px_title"] });
  }, options);
};

const filterOptions = (options: any, filterInput: FilterInput) => {
  const model = mapPrefixToModel(
    getPrefix(splitSearchString(filterInput.inputValue))
  );

  return model !== null
    ? getResults(
        options.filter((option: any) => model === option.model),
        getSufix(splitSearchString(filterInput.inputValue))
      )
    : getResults(options, filterInput.inputValue);
};

const MultipleEntitySelectRaw = ({
  field,
  value,
  onChange,
  fetchList,
  locale,
  list,
  selectedOption,
  disabled,
}: Props) => {
  useEffect(() => {
    field.dependencies.forEach((dependency) =>
      fetchList({ model: dependency })
    );
  }, [field.dependencies, fetchList]);

  return (
    <VirtualizedAutocomplete
      filterOptions={filterOptions}
      value={
        typeof value.id !== "undefined" || typeof value.model !== "undefined"
          ? value
          : {
              id: null,
              model: null,
            }
      }
      onChange={(_event: any, newValue: any) => {
        onChange(
          newValue !== null ? newValue.model : null,
          newValue !== null ? newValue.id : null,
          locale
        );
      }}
      options={[{ id: null, model: null, px_title: "" }, ...list]}
      isOptionEqualToValue={(option: any, value: any) => {
        return (
          String(option.id) === String(value.id) &&
          String(option.model) === String(value.model)
        );
      }}
      getOptionLabel={() => {
        // console.log({ value, selectedOption });
        return typeof selectedOption !== "undefined"
          ? getFriendlyModelName(String(value.model)) +
              " | " +
              selectedOption.px_title
          : "";
      }}
      label={field.placeholder}
      fullWidth
      autoComplete={false}
      disabled={disabled}
    />
  );
};

const makeMapStateToProps = () => {
  // const selectEntitiesList = makeEntitiesListSelector();

  const mapStateToProps = (state: RootState, { field, value }: OwnProps) => {
    const selectEntityIncludingTheSymbolicOnes = (
      state: RootState,
      model: string | null,
      id: string | null
    ) => {
      if (model === "home") {
        return symbolicHomeListedEntity;
      }

      if (model === "store") {
        return symbolicHomeListedEntity;
      }

      return model !== null && id !== null
        ? selectEntityFromList(state, model, id)
        : undefined;
    };

    return {
      list: [
        symbolicHomeListedEntity,
        symbolicStoreListedEntity,
        ...selectEntitiesFromLists(
          state,
          field.dependencies.map((dependency) => ({ model: dependency }))
        ),
      ],
      selectedOption: selectEntityIncludingTheSymbolicOnes(
        state,
        value.model,
        value.id
      ),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = { fetchList };

const connector = connect(makeMapStateToProps, mapDispatchToProps);

const MultipleEntitySelect = connector(MultipleEntitySelectRaw);
export default MultipleEntitySelect;
