import { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  getPivots,
  selectEntitiesFromList,
  selectEntityFromList,
} from "~/features/data/dataSlice";
import {
  fetchEntityPivot,
  pushEntityPivots,
} from "~/features/data/pivotsSlice";
import { fetchList } from "~/features/data/Lists/listsSlice";
import { RootState } from "~/app/store";
import matchSorter from "match-sorter";
import VirtualizedAutocomplete from "~/special/VirtualizedAutocomplete/VirtualizedAutocomplete";
import { Pivot } from "~/types";
import { makeStyles } from "tss-react/mui";
import IconButton from "@mui/material/IconButton";
import HistoryIcon from "@mui/icons-material/History";
import SaveIcon from "@mui/icons-material/Save";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import TextField from "@mui/material/TextField";
import { showQuickCreateDialog } from "~/features/interface/QuickCreateDialog/quickCreateDialogSlice";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface DependantTagFieldType {
  column: string;
  type: string;
  placeholder: string;
  dependency: string;
  showQuickCreateDialog: boolean;
  rules?: string;
  always?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: DependantTagFieldType;
  onChange: (column: string, value: string[], locale?: string) => void;
  locale: string;
};

type FilterInput = {
  inputValue: string;
};

type Props = PropsFromRedux & OwnProps;

const assemblePivots = (pivots: string[]): Pivot[] =>
  pivots.map((id: string) => ({
    id,
  }));

const useStyles = makeStyles()(() => ({
  selector: {
    display: "flex",
  },

  containerButtons: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-evenly",
    height: "auto",
    minWidth: 100,
    alignItems: "center",
  },
}));

const filterOptions = (options: any, filterInput: FilterInput) => {
  const terms = filterInput.inputValue.split(" ");

  return terms.reduceRight((results, term) => {
    return matchSorter(results, term, { keys: ["px_title"] });
  }, options);
};

const DependantTagFieldRaw = ({
  field,
  fetchList,
  fetchEntityPivot,
  pushEntityPivots,
  entityModel,
  entityId,
  list,
  pivots,
  showQuickCreateDialog,
}: Props) => {
  const { classes } = useStyles();

  const [selection, setSelection] = useState<string[]>([...pivots]);

  const handleChange = (_event: any, newValue: any[]) => {
    setSelection(
      newValue.map((itemOrId) =>
        typeof itemOrId.id !== "undefined"
          ? String(itemOrId.id)
          : String(itemOrId)
      )
    );
  };

  useEffect(() => {
    setSelection([...pivots]);
  }, [pivots]);

  useEffect(() => {
    if (typeof entityId !== "undefined") {
      fetchEntityPivot(
        { model: entityModel, relatedModel: field.dependency },
        entityId
      );
    }
  }, [field.dependency, fetchEntityPivot, entityModel, entityId]);

  useEffect(() => {
    fetchList({ model: field.dependency });
  }, [field.dependency, fetchList]);

  const handleSave = () => {
    if (typeof entityId !== "undefined") {
      pushEntityPivots(
        { model: entityModel, relatedModel: field.dependency },
        entityId,
        assemblePivots(selection)
      );
    }
  };

  const handleReset = () => {
    if (typeof entityId !== "undefined") {
      fetchEntityPivot(
        { model: entityModel, relatedModel: field.dependency },
        entityId
      );
    }
  };

  return (
    <>
      {typeof entityId !== "undefined" ? (
        <div className={classes.selector}>
          <VirtualizedAutocomplete
            filterOptions={filterOptions}
            value={typeof selection !== "undefined" ? selection : []}
            onChange={handleChange}
            options={list}
            isOptionEqualToValue={(option: any, value: any) =>
              String(option.id) === String(value)
            }
            getOptionLabel={(option: any) => {
              if (typeof list !== "undefined") {
                const item = list.find(
                  (item: any) => String(item.id) === String(option)
                );

                if (typeof item !== "undefined") {
                  return item.px_title;
                }
              }

              return "";
            }}
            label={field.placeholder}
            fullWidth
            autoComplete={false}
            multiple
            disableCloseOnSelect
            filterSelectedOptions
          />
          <div className={classes.containerButtons}>
            {field.showQuickCreateDialog ? (
              <IconButton
                aria-label="quick create"
                color="secondary"
                // size="small"
                onClick={() => showQuickCreateDialog(field.dependency)}
                size="small"
                edge="end"
              >
                <PlusOneIcon />
              </IconButton>
            ) : null}
            <IconButton
              aria-label="reset"
              color="secondary"
              // size="small"
              onClick={handleReset}
              size="small"
            >
              <HistoryIcon />
            </IconButton>
            <IconButton
              aria-label="save"
              color="secondary"
              // size="small"
              onClick={handleSave}
              size="small"
              edge="end"
            >
              <SaveIcon />
            </IconButton>
          </div>
        </div>
      ) : (
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          disabled
          id={`field-${field.column}-${field.type}-disabled`}
          label={`${field.placeholder} (desabilitado até salvar)`}
          defaultValue=""
        />
      )}
    </>
  );
};

const makeMapStateToProps = () => {
  // const selectEntitiesList = makeEntitiesListSelector();
  const mapStateToProps = (
    state: RootState,
    { entityModel, entityId, field }: OwnProps
  ) => {
    const pivots =
      typeof entityId !== "undefined"
        ? getPivots(
            state,
            { model: entityModel, relatedModel: field.dependency },
            entityId
          )
        : [];

    return {
      list: selectEntitiesFromList(state, { model: field.dependency }),
      pivots,
      selectedOptions: pivots.map((id) =>
        selectEntityFromList(state, field.dependency, id)
      ),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchList,
  fetchEntityPivot,
  pushEntityPivots,
  showQuickCreateDialog,
};

const connector = connect(makeMapStateToProps, mapDispatchToProps);

const DependantTagField = connector(DependantTagFieldRaw);
export default DependantTagField;
