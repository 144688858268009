import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  selectEntitiesFromList,
  selectEntityFromList,
} from "~/features/data/dataSlice";
import { fetchList } from "~/features/data/Lists/listsSlice";
import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import { showQuickCreateDialog } from "~/features/interface/QuickCreateDialog/quickCreateDialogSlice";
import matchSorter from "match-sorter";
import VirtualizedAutocomplete from "~/special/VirtualizedAutocomplete/VirtualizedAutocomplete";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import IconButton from "@mui/material/IconButton";
import PlusOneIcon from "@mui/icons-material/PlusOne";

type PropsFromRedux = ConnectedProps<typeof connector>;
export interface DependantSelectFieldType {
  column: string;
  type: string;
  placeholder: string;
  dependency: string;
  showQuickCreateDialog: boolean;
  rules?: string;
  always?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: DependantSelectFieldType;
  onChange: (column: string, value: string | null, locale?: string) => void;
  locale: string;
};

type FilterInput = {
  inputValue: string;
};

type Props = PropsFromRedux & OwnProps;

const useStyles = makeStyles()((theme: Theme) => ({
  selector: {
    display: "flex",
    // marginBottom: theme.spacing(2),
  },

  containerButtons: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-evenly",
    height: "auto",
    // minWidth: 100,
    alignItems: "center",
  },

  button: {
    marginLeft: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
}));

const filterOptions = (options: any, filterInput: FilterInput) => {
  const terms = filterInput.inputValue.split(" ");

  return terms.reduceRight((results, term) => {
    return matchSorter(results, term, { keys: ["px_title"] });
  }, options);
};

const DependantSelectFieldRaw = ({
  field,
  draftValue,
  onChange,
  fetchList,
  locale,
  list,
  selectedOption,
  showQuickCreateDialog,
}: Props) => {
  const { classes } = useStyles();

  useEffect(() => {
    fetchList({ model: field.dependency });
  }, [field.dependency, fetchList]);

  return (
    <>
      <div className={classes.selector}>
        <VirtualizedAutocomplete
          filterOptions={filterOptions}
          value={typeof draftValue !== "undefined" ? String(draftValue) : null}
          onChange={(_event: any, newValue: any) => {
            onChange(
              field.column,
              newValue !== null ? String(newValue.id) : null,
              locale
            );
          }}
          options={
            draftValue === null || typeof draftValue === "undefined"
              ? [{ id: null, title: "" }, ...list]
              : list
          }
          isOptionEqualToValue={(option: any, value: any) =>
            String(option.id) === String(value)
          }
          getOptionLabel={(_option: any) => {
            return typeof selectedOption !== "undefined"
              ? selectedOption.px_title
              : "";
            //   if (typeof list !== "undefined") {
            //     const item = list.find(
            //       (item: any) => String(item.id) === String(option)
            //     );

            //     if (typeof item !== "undefined") {
            //       return item.px_title;
            //     }
            //   }

            //   return "";
            // }
            // typeof list !== 'undefined' &&
            // typeof list.find((item: any) => String(item.id) === String(option)) !==
            //   'undefined'
            //   ? list.find((item: any) => String(item.id) === String(option)).px_title
            //   : ''
          }}
          label={field.placeholder}
          fullWidth
          autoComplete={false}
        />

        {field.showQuickCreateDialog ? (
          <div className={classes.containerButtons}>
            <IconButton
              aria-label="quick create"
              color="secondary"
              className={classes.button}
              // size="small"
              onClick={() => showQuickCreateDialog(field.dependency)}
              size="small"
              edge="end"
            >
              <PlusOneIcon />
            </IconButton>
          </div>
        ) : null}
      </div>
    </>
  );
};

const makeMapStateToProps = () => {
  // const selectEntitiesList = makeEntitiesListSelector();
  const mapStateToProps = (
    state: RootState,
    { entityModel, entityId, field }: OwnProps
  ) => {
    const id = getDraftColumn(state, entityModel, entityId, field.column);

    return {
      list: selectEntitiesFromList(state, { model: field.dependency }),
      draftValue: id,
      selectedOption: selectEntityFromList(state, field.dependency, id),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = { fetchList, showQuickCreateDialog };

const connector = connect(makeMapStateToProps, mapDispatchToProps);

const DependantSelectField = connector(DependantSelectFieldRaw);
export default DependantSelectField;
