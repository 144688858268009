import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import { getLoggedStatus } from "~/features/server/serverSlice";
import Login from "~/main/Login";

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const LoginDialogRaw = ({ logged }: Props) => {
  return (
    <div>
      <Dialog
        open={!logged}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Login"}</DialogTitle>

        <DialogContent>
          <Login />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  logged: getLoggedStatus(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const LoginDialog = connector(LoginDialogRaw);
export default LoginDialog;
