import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import { RecipeBlockType } from "~/types/views";
import RecipeBlockComponents from "../RecipeBlockComponents/RecipeBlockComponents";
import { RecipeBlockIngredientListings } from "../RecipeBlockIngredientListings/RecipeBlockIngredientListings";
import styles from "./RecipeBlock.module.scss";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import Button from "@mui/material/Button";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import RPBlockProducts from "../../shared/RPBlockProducts/RPBlockProducts";
import { pushEntityToDestination } from "~/features/interface/Copy/copySlice";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { isCopyDestinationSet } from "~/features/interface/interfaceSlice";

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  recipeBlock: RecipeBlockType;
  link: string;
  entityModel: string;
};

type Props = PropsFromRedux & OwnProps;

const RecipeBlockRaw = ({
  recipeBlock,
  link,
  entityModel,
  isCopyDestinationSet,
  pushEntityToDestination,
}: Props) => {
  return (
    <div className={styles.ctn}>
      <Link to={`${link}/recipeBlock/${recipeBlock.id}`}>
        {recipeBlock.title ? (
          <div className={styles.header}>
            <h3>{recipeBlock.title}</h3>
          </div>
        ) : null}
        {recipeBlock.title_aux ? <h4>{recipeBlock.title_aux}</h4> : null}
      </Link>
      {!recipeBlock.title && !recipeBlock.title_aux ? (
        <Link
          className={styles.editButton}
          to={`${link}/recipeBlock/${recipeBlock.id}`}
        >
          <IconButton aria-label="delete" size="small">
            <BuildCircleIcon fontSize="inherit" />
          </IconButton>
        </Link>
      ) : null}

      {isCopyDestinationSet ? (
        <Button
          variant="contained"
          aria-label="fingerprint"
          color="primary"
          sx={{ position: "absolute", top: 5, right: 5, zIndex: 2 }}
          size="small"
          onClick={() => {
            pushEntityToDestination(`${entityModel}Block`, recipeBlock.id);
          }}
        >
          <AddShoppingCartOutlinedIcon fontSize="inherit" />
        </Button>
      ) : null}

      <RecipeBlockIngredientListings
        recipeBlockIngredientListings={recipeBlock.blockIngredientListings}
        link={`${link}/recipeBlock/${recipeBlock.id}`}
      />

      <RecipeBlockComponents
        recipeBlockComponents={recipeBlock.blockComponents}
        link={`${link}/recipeBlock/${recipeBlock.id}`}
      />

      <RPBlockProducts
        blockProducts={recipeBlock.blockProducts}
        format={recipeBlock.format}
        link={`${link}/recipeBlock/${recipeBlock.id}`}
        modelPrefix={"recipe"}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isCopyDestinationSet: isCopyDestinationSet(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  pushEntityToDestination: (model: string, id: string) =>
    dispatch(pushEntityToDestination(model, id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const RecipeBlock = connector(RecipeBlockRaw);
export default RecipeBlock;
