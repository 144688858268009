import ReactCrop, { Crop } from "react-image-crop";

type OwnProps = {
  src: string;
  crop: Crop;
  setCrop: (crop: Crop) => void;
};

export default function ImageCropper({ src, crop, setCrop }: OwnProps) {
  return (
    <ReactCrop
      src={src}
      crop={crop}
      onChange={(_newCrop: Crop, newPercentageCrop: Crop) => {
        setCrop(newPercentageCrop);
      }}
      ruleOfThirds
    />
  );
}
