export const splitSearchString = (search: string) => {
  const rx = /^([a-z]*)(:)(.*)/g;

  return rx.exec(search);
};

export const getPrefix = (splittedString: RegExpExecArray | null) => {
  return splittedString !== null ? splittedString[1] : null;
};

export const getSufix = (splittedString: RegExpExecArray | null): string => {
  return splittedString !== null ? splittedString[3] : "";
};

//-fix: map should come from server
export const mapPrefixToModel = (prefix: string | null) => {
  switch (prefix) {
    case "h":
      return "home";
    case "l":
      return "store";
    case "r":
      return "recipe";
    case "p":
      return "post";
    case "b":
      return "blog";
    case "c":
      return "category";
    case "s":
      return "subhome";
    case "v":
      return "showcase";
    case "pr":
      return "product";
    case "ve":
      return "dictionaryEntry";
    default:
      return null;
  }
};
