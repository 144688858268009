import { Ingredient, Measure } from "~/types";

export const getIngredientCorrectTitle = (
  quantity: number,
  measure: Measure | undefined,
  ingredient: Ingredient | undefined,
  forcePlural: boolean
) => {
  if (typeof ingredient !== "undefined" && ingredient !== null) {
    return shouldIngredientBePlural(quantity, measure) || forcePlural
      ? ingredient.title_plural
      : ingredient.title_singular;
  }

  return "";
};

export const shouldIngredientBePlural = (
  quantity: number,
  measure: Measure | undefined
) => {
  if (typeof measure !== "undefined" && measure !== null) {
    if (["", null].includes(measure.title_singular.trim()) && quantity >= 2) {
      return true;
    }

    return false;
  }

  if (quantity >= 2) {
    return true;
  }

  return false;
};
