// const imageDomain = "https://panelinha-next.s3.amazonaws.com/";
// const imageDomain = "http://localhost:8000/img/temporary-optimized-files/";

const imageDomain = import.meta.env.VITE_APP_IMAGE_DOMAIN;

export const getImageUrl = (src: string, format?:string) => {
    if(format) {
        return `${imageDomain}32-${format}-${src}`;
    }

    return `${imageDomain}32-o-${src}`;
};
// export const getImageUrl = (src: string) => `${src}`;
