// import {
//   getResultNamespace,
//   getResultFetchTimeNamespace,
// } from '~/features/data/dataSlice';
// import { set } from 'idb-keyval';
import {
  ModelListProps,
  PivotListProps,
  PuxadinhoResponse,
  PuxadinhoErrorResponse,
  Pivot,
  Entity,
  Draft,
  EntityPosition,
  Dependencies,
} from "~/types";
import axios, { AxiosResponse, AxiosError } from "axios";
import { PuxadinhoError } from "~/shared/PuxadinhoError";
import { EntityView } from "~/types/views";

export const parseResponse = async <T>(
  response: AxiosResponse<T>
): Promise<PuxadinhoResponse<T>> => {
  const fetchTime = Date.now();
  const statusCode = await response.status;
  const result = await response.data;
  // let result = undefined;

  // if ([200, 400, 500].indexOf(statusCode) === -1) {
  //   throw new Error('Unknown response type');
  // }

  // if (statusCode === 200 || statusCode === 400) {
  //   try {
  // result = await response.data;
  return {
    statusCode,
    result,
    fetchTime,
  };
  // } catch (error) {
  //   throw err;
  // }
  // }

  // return {
  //   statusCode,
  //   result: [statusCode, result],
  //   fetchTime,
  // };
};

// export class PuxadinhoError extends Error {
//   public statusCode: PuxadinhoErrorCodes;
//   public result?: PuxadinhoHandledError;

//   constructor({ statusCode, result }: PuxadinhoErrorResponse) {
//     super('PuxadinhoError');
//     this.name = 'PuxadinhoError';
//     this.statusCode = statusCode;
//     this.result = result;
//     Error.captureStackTrace(this, PuxadinhoError);
//   }
// }

export const getEntities = async (
  modelList: ModelListProps
): Promise<PuxadinhoResponse<Entity[]>> => {
  let url;

  if (
    typeof modelList.parentModel !== "undefined" &&
    typeof modelList.parentEntityId !== "undefined"
  ) {
    url = `${import.meta.env.VITE_APP_API}/api/model?model=${
      modelList.model
    }&parentModel=${modelList.parentModel}&parentEntityId=${
      modelList.parentEntityId
    }`;
  } else {
    url = `${import.meta.env.VITE_APP_API}/api/model?model=${modelList.model}`;
  }

  try {
    const response = await axios.get<Entity[]>(url);

    const parsedResponse = await parseResponse(response);

    //this should be somewhere else
    // const { statusCode, fetchTime, result } = parsedResponse;

    // if (statusCode === 200) {
    //   set(getResultNamespace(modelList), result);
    //   set(getResultFetchTimeNamespace(modelList), fetchTime);
    // }
    //this should be somewhere else
    return parsedResponse;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};

export const getEntity = async (
  modelList: ModelListProps,
  id: string
): Promise<PuxadinhoResponse<Entity>> => {
  let url;

  if (
    typeof modelList.parentModel !== "undefined" &&
    typeof modelList.parentEntityId !== "undefined"
  ) {
    url = `${import.meta.env.VITE_APP_API}/api/model/${id}?model=${
      modelList.model
    }&parentModel=${modelList.parentModel}&parentEntityId=${
      modelList.parentEntityId
    }`;
  } else {
    url = `${import.meta.env.VITE_APP_API}/api/model/${id}?model=${
      modelList.model
    }`;
  }

  try {
    const response = await axios.get<Entity>(url);

    return await parseResponse(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};

export const saveEntity = async (
  modelList: ModelListProps,
  data: Draft
): Promise<PuxadinhoResponse<Entity>> => {
  let url;

  if (
    typeof modelList.parentModel !== "undefined" &&
    typeof modelList.parentEntityId !== "undefined"
  ) {
    url = `${import.meta.env.VITE_APP_API}/api/model?model=${
      modelList.model
    }&parentModel=${modelList.parentModel}&parentEntityId=${
      modelList.parentEntityId
    }`;
  } else {
    url = `${import.meta.env.VITE_APP_API}/api/model?model=${modelList.model}`;
  }

  try {
    const response = await axios.post<Entity>(url, data);

    return await parseResponse(response);
  } catch (error) {
    // console.log("gotach");
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};

export const setPublish = async (
  modelList: ModelListProps,
  id: string,
  publish: boolean
): Promise<PuxadinhoResponse<Entity>> => {
  const url = `${import.meta.env.VITE_APP_API}/api/model-publish/${id}?model=${
    modelList.model
  }`;

  try {
    const response = await axios.put<Entity>(url, { publish });

    return await parseResponse(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};

export const updateEntity = async (
  modelList: ModelListProps,
  id: string,
  data: Draft
): Promise<PuxadinhoResponse<Entity>> => {
  const url = `${import.meta.env.VITE_APP_API}/api/model/${id}?model=${
    modelList.model
  }`;

  try {
    const response = await axios.put<Entity>(url, data);

    return await parseResponse(response);
  } catch (error) {
    console.log("gotcha");
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};

export const updateEntitiesPosition = async (
  model: string,
  data: EntityPosition[]
): Promise<PuxadinhoResponse<boolean>> => {
  const url = `${
    import.meta.env.VITE_APP_API
  }/api/model-position/?model=${model}`;

  try {
    const response = await axios.put<boolean>(url, data);

    return await parseResponse(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};

export const deleteEntity = async (
  model: string,
  id: string
): Promise<PuxadinhoResponse<boolean>> => {
  const url = `${import.meta.env.VITE_APP_API}/api/model/${id}?model=${model}`;

  try {
    const response = await axios.delete<boolean>(url);

    return await parseResponse(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};

export const getEntityPivots = async (
  pivotList: PivotListProps,
  id: string
): Promise<PuxadinhoResponse<string[]>> => {
  const url = `${import.meta.env.VITE_APP_API}/api/model-pivot/${id}?model=${
    pivotList.model
  }&relatedModel=${pivotList.relatedModel}`;

  try {
    const response = await axios.get<string[]>(url);

    return await parseResponse(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};

export const setEntityPivots = async (
  pivotList: PivotListProps,
  id: string,
  pivots: Pivot[]
): Promise<PuxadinhoResponse<boolean>> => {
  const url = `${import.meta.env.VITE_APP_API}/api/model-pivot/${id}?model=${
    pivotList.model
  }&relatedModel=${pivotList.relatedModel}`;

  try {
    const response = await axios.put<boolean>(url, { data: pivots });

    return await parseResponse(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};

export const copyEntityToDestination = async (
  entityId: string,
  entityModel: string,
  destinationId: string,
  destinationModel: string
): Promise<PuxadinhoResponse<ModelListProps[]>> => {
  const url = `${
    import.meta.env.VITE_APP_API
  }/api/model-copy/${entityId}?model=${entityModel}&destinationModel=${destinationModel}&destinationId=${destinationId}`;

  try {
    const response = await axios.get<ModelListProps[]>(url);

    return await parseResponse(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};

export const copyEntity = async (
  modelList: ModelListProps,
  id: string
): Promise<PuxadinhoResponse<Entity>> => {
  const url = `${
    import.meta.env.VITE_APP_API
  }/api/model-duplicate/${id}?model=${modelList.model}`;

  try {
    const response = await axios.get<Entity>(url);

    return await parseResponse(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};

export const getEntityView = async (
  model: string,
  id: string
): Promise<PuxadinhoResponse<EntityView>> => {
  const url = `${
    import.meta.env.VITE_APP_API
  }/api/model-view/${id}?model=${model}`;

  try {
    const response = await axios.get<EntityView>(url);

    return await parseResponse(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};

export const getEntityDependencies = async (
  modelList: ModelListProps,
  id: string
): Promise<PuxadinhoResponse<Dependencies>> => {
  let url;

  if (
    typeof modelList.parentModel !== "undefined" &&
    typeof modelList.parentEntityId !== "undefined"
  ) {
    url = `${import.meta.env.VITE_APP_API}/api/model-dependencies/${id}?model=${
      modelList.model
    }&parentModel=${modelList.parentModel}&parentEntityId=${
      modelList.parentEntityId
    }`;
  } else {
    url = `${import.meta.env.VITE_APP_API}/api/model-dependencies/${id}?model=${
      modelList.model
    }`;
  }

  try {
    const response = await axios.get<Dependencies>(url);

    return await parseResponse(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};

export const exchangeDependencies = async (
  modelList: ModelListProps,
  id: string,
  newModel: string,
  newId: string
): Promise<PuxadinhoResponse<boolean>> => {
  let url;

  if (
    typeof modelList.parentModel !== "undefined" &&
    typeof modelList.parentEntityId !== "undefined"
  ) {
    url = `${import.meta.env.VITE_APP_API}/api/model-exchange/${id}?model=${
      modelList.model
    }&parentModel=${modelList.parentModel}&parentEntityId=${
      modelList.parentEntityId
    }&newModel=${newModel}&newId=${newId}`;
  } else {
    url = `${import.meta.env.VITE_APP_API}/api/model-exchange/${id}?model=${
      modelList.model
    }&newModel=${newModel}&newId=${newId}`;
  }

  try {
    const response = await axios.get<boolean>(url);

    return await parseResponse(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};

export const resolveDependencies = async (
  modelList: ModelListProps,
  id: string
): Promise<PuxadinhoResponse<boolean>> => {
  let url;

  if (
    typeof modelList.parentModel !== "undefined" &&
    typeof modelList.parentEntityId !== "undefined"
  ) {
    url = `${
      import.meta.env.VITE_APP_API
    }/api/model-dependencies/resolve/${id}?model=${
      modelList.model
    }&parentModel=${modelList.parentModel}&parentEntityId=${
      modelList.parentEntityId
    }`;
  } else {
    url = `${
      import.meta.env.VITE_APP_API
    }/api/model-dependencies/resolve/${id}?model=${modelList.model}`;
  }

  try {
    const response = await axios.get<boolean>(url);

    return await parseResponse(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};

export const parseError = async (
  error: AxiosError
): Promise<PuxadinhoErrorResponse> => {
  try {
    if (typeof error.response !== "undefined") {
      const response = await error.response;

      const statusCode = await response.status;

      if (statusCode === 400) {
        const result = await response.data;

        return {
          statusCode,
          result,
        };
      }

      if (statusCode === 401 || statusCode === 419 || statusCode === 500) {
        return {
          statusCode,
        };
      }
    }
  } catch (error) {
    console.log(error);
  }

  return {
    statusCode: 500,
  };
};

// export const parseResponse = async (
//   response: Response
// ): Promise<PuxadinhoResponse> => {
//   const fetchTime = Date.now();
//   const ok = await response.ok;
//   let statusCode = await response.status;
//   let result = {};

//   if ((ok && statusCode !== 500) || statusCode === 400) {
//     try {
//       result = await response.json();
//     } catch (error) {
//       statusCode = 500;
//       result = {
//         json: [err.message],
//         server: ['Server error'],
//       };
//     }
//   }

//   return {
//     statusCode,
//     fetchTime,
//     result,
//   };
// };
