import { createSlice } from '@reduxjs/toolkit';

interface AppPanelState {
  isPanelOpen: boolean;
}

const initialState: AppPanelState = {
  isPanelOpen: false,
};

export const appPanelSlice = createSlice({
  name: 'appPanel',
  initialState,
  reducers: {
    openAppPanel: (state) => {
      state.isPanelOpen = true;
    },
    dismissAppPanel: (state) => {
      state.isPanelOpen = false;
    },
  },
});

export const getPanelStatus = (state: AppPanelState) => state.isPanelOpen;

export const { openAppPanel, dismissAppPanel } = appPanelSlice.actions;

export default appPanelSlice.reducer;
