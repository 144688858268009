import styles from "./RecipeLinks.module.scss";
import { RPRelationshipType } from "~/types/views";
import RecipeRelationship from "../RecipeRelationship/RecipeRelationship";
import { Link } from "react-router-dom";

type Props = {
  recipeLinks: RPRelationshipType[];
  link: string;
};
const RecipeLinks = ({ recipeLinks, link }: Props) => {
  if (!recipeLinks.length) {
    return null;
  }

  return (
    <div className={styles.ctn}>
      <Link to={`${link}/recipeLink/`}>
        <div className={styles.header}>
          <h5>Conteúdo adicional</h5>
        </div>
      </Link>

      <div className={styles.contentColumns2}>
        {recipeLinks.map((recipeLink, recipeLinkIndex) => (
          <RecipeRelationship
            key={recipeLinkIndex}
            recipeRelationship={recipeLink}
            link={`${link}/recipeLink/${recipeLink.id}`}
          />
        ))}
      </div>
    </div>
  );
};

export default RecipeLinks;
