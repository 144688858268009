import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import { connect, ConnectedProps } from "react-redux";
// import TextField from '@mui/material/TextField';
// import FormControl from "@mui/material/FormControl";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import InputAdornment from "@mui/material/InputAdornment";
// import IconButton from "@mui/material/IconButton";
// import FormatClearIcon from "@mui/icons-material/FormatClear";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface SliderFieldType {
  column: string;
  type: string;
  placeholder: string;
  rules?: string;
  always?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: SliderFieldType;
  // draft: any;
  onChange: (column: string, value: number, locale?: string) => void;
  locale: string;
};

type Props = PropsFromRedux & OwnProps;

const SliderFieldRaw = ({ field, draftValue, onChange, locale }: Props) => {
  return (
    <>
      <Box padding={1}>
        <Typography gutterBottom>{field.placeholder}</Typography>
        <Slider
          value={
            typeof draftValue !== "undefined" && draftValue !== null
              ? draftValue
              : 0
          }
          min={0}
          step={1}
          max={10}
          onChange={(_event, newValue) => {
            onChange(field.column, newValue as number, locale);
          }}
          valueLabelDisplay="auto"
        />
      </Box>
    </>
  );
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId, field }: OwnProps
) => ({
  draftValue: getDraftColumn(state, entityModel, entityId, field.column),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const SliderField = connector(SliderFieldRaw);
export default SliderField;
