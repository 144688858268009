import { ImageCrops } from "./../types/index";
import { parseResponse, parseError } from "./entitiesAPI";
import { PuxadinhoResponse } from "~/types";
import { ListedImage } from "~/types";
import axios from "axios";
import { Crop } from "react-image-crop";
import { PuxadinhoError } from "~/shared/PuxadinhoError";

export const saveImage = async (
  image: File,
  crops: ImageCrops,
  originalWidth: number,
  originalHeight: number,
  modelToAssociateUpload: string | null | undefined,
  idToAssociateUpload: string | null | undefined
): Promise<PuxadinhoResponse<ListedImage>> => {
  const url = `${import.meta.env.VITE_APP_API}/api/image/add`;

  let formData = new FormData();
  formData.append("image", image);
  formData.append("crops", JSON.stringify(crops));
  formData.append("originalWidth", String(originalWidth));
  formData.append("originalHeight", String(originalHeight));

  if (
    typeof modelToAssociateUpload !== "undefined" &&
    modelToAssociateUpload !== null &&
    typeof idToAssociateUpload !== "undefined" &&
    idToAssociateUpload !== null
  ) {
    formData.append("entitiable_type", String(modelToAssociateUpload));
    formData.append("entitiable_id", String(idToAssociateUpload));
  }

  const response = await axios.post(url, formData);

  try {
    return await parseResponse(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};

export const updateCrop = async (
  cropId: string,
  crop: Crop
): Promise<PuxadinhoResponse<ListedImage>> => {
  const url = `${import.meta.env.VITE_APP_API}/api/image/${cropId}`;

  const response = await axios.put(url, crop);

  try {
    return await parseResponse(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new PuxadinhoError(await parseError(error));
    }

    throw new PuxadinhoError({ statusCode: 600 });
  }
};
