import { RPBlockIngredientListingType } from "~/types/views";
import styles from "./RecipeBlockIngredientListings.module.scss";
import { Link } from "react-router-dom";

type Props = {
  recipeBlockIngredientListings: RPBlockIngredientListingType[];
  link: string;
};

export const RecipeBlockIngredientListings = ({
  recipeBlockIngredientListings,
  link,
}: Props) => {
  return (
    <div className={styles.ctn}>
      <Link to={`${link}/recipeBlockIngredientListing/`}>
        <h5>Ingredientes</h5>
      </Link>

      <ul>
        {recipeBlockIngredientListings.map(
          (recipeBlockIngredientListing, recipeBlockIngredientListingIndex) => (
            <li key={recipeBlockIngredientListingIndex}>
              <Link
                to={`${link}/recipeBlockIngredientListing/${recipeBlockIngredientListing.id}`}
                className={styles.linkDot}
              />
              {recipeBlockIngredientListing.textClean}
            </li>
          )
        )}
      </ul>
    </div>
  );
};
