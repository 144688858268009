import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import styles from "./Slideshow.module.scss";
import { Highlight } from "~/types/views";
import { Link } from "react-router-dom";
import { pushEntityToDestination } from "~/features/interface/Copy/copySlice";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { isCopyDestinationSet } from "~/features/interface/interfaceSlice";
import Button from "@mui/material/Button";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import { getImageUrl } from "~/shared/ImageTools";

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  highlights: Highlight[];
  entityId: string;
  entityModel: string;
};

type Props = PropsFromRedux & OwnProps;

const SlideshowRaw = ({
  highlights,
  entityId,
  entityModel,
  isCopyDestinationSet,
  pushEntityToDestination,
}: Props) => {
  return highlights.length ? (
    <div className={styles.ctn}>
      <h1 className={styles.title}>Slideshow</h1>
      <div className={styles.contentSlides}>
        {highlights.map((highlight, highlightIndex) => {
          return (
            <div key={highlightIndex}>
              {isCopyDestinationSet ? (
                <div className={styles.ctnCopyButton}>
                  <Button
                    variant="contained"
                    aria-label="fingerprint"
                    color="primary"
                    size="small"
                    onClick={() => {
                      pushEntityToDestination(
                        `${entityModel}Highlight`,
                        highlight.id
                      );
                    }}
                  >
                    <AddShoppingCartOutlinedIcon fontSize="inherit" />
                  </Button>
                </div>
              ) : null}
              <Link
                to={`/${entityModel}/${entityId}/${entityModel}Highlight/${highlight.id}`}
              >
                {highlight.video ? (
                  <div
                    className={styles.ctnVideo}
                    dangerouslySetInnerHTML={{ __html: highlight.video }}
                  />
                ) : highlight.image ? (
                  <img
                    src={getImageUrl(`${highlight.image.url}`, 'bn')}
                    alt={highlight.image.alt}
                    width={1140}
                    height={582}
                  />
                ) : null}
                <div>
                  <h1 className={styles.title}>{highlight.title}</h1>
                  <p className={styles.subtitle}>{highlight.subtitle}</p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

const mapStateToProps = (state: RootState) => ({
  isCopyDestinationSet: isCopyDestinationSet(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  pushEntityToDestination: (model: string, id: string) =>
    dispatch(pushEntityToDestination(model, id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const Slideshow = connector(SlideshowRaw);
export default Slideshow;
