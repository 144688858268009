import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import {
  isQuickCreateDialogOpen,
  getQuickCreateDialogModel,
} from "~/features/interface/interfaceSlice";

import { hideQuickCreateDialog } from "~/features/interface/QuickCreateDialog/quickCreateDialogSlice";
import FormFields from "~/features/drafts/FormFields";
import EntityToolbar from "~/features/data/Entity/EntityToolbar";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const QuickCreateRaw = ({
  hideQuickCreateDialog,
  isQuickCreateDialogOpen,
  model,
}: Props) => {
  const handleClose = () => {
    hideQuickCreateDialog();
  };

  return (
    <div>
      <Dialog
        open={isQuickCreateDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <EntityToolbar
          entityModel={model}
          parentModel={undefined}
          parentEntityId={undefined}
        />
        <DialogContent sx={{ width: 500 }}>
          <FormFields entityModel={model} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    model: getQuickCreateDialogModel(state),
    isQuickCreateDialogOpen: isQuickCreateDialogOpen(state),
  };
};

const mapDispatchToProps = {
  hideQuickCreateDialog,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const QuickCreate = connector(QuickCreateRaw);
export default QuickCreate;
