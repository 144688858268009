import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import { connect, ConnectedProps } from "react-redux";
import { formatISO, isValid, parseISO, setSeconds } from "date-fns";
// import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface TimeFieldType {
  column: string;
  type: string;
  placeholder: string;
  rules?: string;
  always?: boolean;
  default?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: TimeFieldType;
  onChange: (column: string, value: string | null, locale?: string) => void;
  locale: string;
};

type Props = PropsFromRedux & OwnProps;

const createDateFromUTCTimestamp = (timestamp: string): Date => {
  return parseISO(
    formatISO(new Date(), { representation: "date" }) + "T" + timestamp + "Z"
  );
};

const getUTCTimestamp = (date: Date): string => {
  return setSeconds(date, 0).toISOString().substring(11, 19);
};

const TimeFieldRaw = ({ field, draftValue, onChange }: Props) => {
  return (
    // <div>timefield</div>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        label={field.placeholder}
        value={
          typeof draftValue !== "undefined" && draftValue !== null
            ? createDateFromUTCTimestamp(draftValue)
            : null
        }
        onChange={(date) => {
          onChange(
            field.column,
            date !== null
              ? isValid(date)
                ? getUTCTimestamp(date)
                : null
              : // : typeof value !== "undefined"
                // ? value
                null
          );
        }}
        // renderInput={(params: any) => <TextField {...params} fullWidth />}
        // clearable={true}
      />
    </LocalizationProvider>
    // <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //   <KeyboardTimePicker
    //     margin="normal"
    //     // id="time-picker"
    //     label={field.placeholder}
    //     value={
    //       typeof draftValue !== 'undefined' && draftValue !== null
    //         ? createDateFromUTCTimestamp(draftValue)
    //         : null
    //     }
    //     ampm={false}
    //     onChange={(date, value) => {
    //       onChange(
    //         field.column,
    //         date !== null && typeof value !== 'undefined'
    //           ? isValid(date)
    //             ? getUTCTimestamp(date)
    //             : value
    //           : null,
    //         locale
    //       );
    //     }}
    //     clearable={true}
    //     fullWidth
    //   />
    // </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId, field }: OwnProps
) => ({
  draftValue: getDraftColumn(state, entityModel, entityId, field.column),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const TimeField = connector(TimeFieldRaw);
export default TimeField;
