/* eslint-disable react/jsx-no-target-blank */

const ExternalLinkElement = (props: any) => {
  return (
    <a
      {...props.attributes}
      href={props.element.url}
      rel={props.element.new_tab ? "noreferrer" : ""}
      target={props.element.new_tab ? "_blank" : "_self"}
    >
      {props.children}
    </a>
  );
};

export default ExternalLinkElement;
