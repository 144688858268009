import { RPBlockProductType } from "~/types/views";
import styles from "./RPBlockProducts.module.scss";
import { Link } from "react-router-dom";
import RPBlockProduct from "../RPBlockProduct/RPBlockProduct";

type Props = {
  blockProducts: RPBlockProductType[];
  link: string;
  format: string;
  modelPrefix: string;
};

const getClassNameFromFormat = (format: string) => {
  switch (format) {
    case "columns-2":
      return "contentColumns2";
    case "columns-3":
      return "contentColumns3";
    case "columns-4":
      return "contentColumns4";
    default:
      return "contentColumns1";
  }
};

const RPBlockProducts = ({
  blockProducts,
  link,
  format,
  modelPrefix,
}: Props) => {
  return blockProducts.length ? (
    <div className={styles.ctn}>
      <Link to={`${link}/blockProduct/`}>
        <div className={styles.header}>
          <h5>Vejas os produtos indicados</h5>
        </div>
      </Link>

      <div className={styles[getClassNameFromFormat(format)]}>
        {blockProducts.map((blockProduct, blockProductIndex) => (
          <RPBlockProduct
            key={blockProductIndex}
            blockProduct={blockProduct}
            link={`${link}/${modelPrefix}BlockProduct/${blockProduct.id}`}
          />
        ))}
      </div>
    </div>
  ) : null;
};

export default RPBlockProducts;
