import { ListedEntity, ListedImage, ListedImageCrop } from 'types';
import { schema } from 'normalizr';

const entity = new schema.Entity<ListedEntity | ListedImage | ListedImageCrop>(
  'list',
  undefined,
  {
    idAttribute: (value) => String(value.id),
  }
);
export const list = new schema.Array(entity);
