import { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import RestoreIcon from "@mui/icons-material/Restore";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";
import { ImageType, ImageCrop } from "~/types";
import { Crop } from "react-image-crop";
import { updateCrop } from "~/api/imagesApi";
import ImageCropper from "~/features/interface/ImageManager/ImageCropper/ImageCropper";
import SettingsOverscanIcon from "@mui/icons-material/SettingsOverscan";
import Badge from "@mui/material/Badge";
import {
  getBadgeColor,
  getCenteredCrop,
  getFreeCrop,
} from "~/features/interface/ImageManager/ImageManagerUtils";

type OwnProps = {
  image: ImageType;
  imageCrop: ImageCrop;
};

type Props = OwnProps;

const pushCrop = async (cropId: string, crop: Crop) => {
  // this should be called inside the image manager slice @todo
  await updateCrop(cropId, crop);
};

const SelectedImageCrop = ({ image, imageCrop }: Props) => {
  const [draftCrops, setDraftCrops] = useState<{ [key: string]: Crop }>({});

  const getCenterCrop = (aspect: number) => {
    const img = new Image();
    img.src = image.px_image_url;

    img.onload = () => {
      setDraftCrops((draftCrops) => ({
        ...draftCrops,
        [imageCrop.id]: getCenteredCrop(img, aspect),
      }));
    };
  };

  return (
    <div>
      <Badge badgeContent=" " color={getBadgeColor(imageCrop.status)}>
        <Card variant="outlined">
          <CardContent sx={{ textAlign: "center" }}>
            <ImageCropper
              src={image.px_image_url}
              crop={
                typeof draftCrops[imageCrop.id] !== "undefined"
                  ? draftCrops[imageCrop.id]
                  : imageCrop.options
              }
              setCrop={(newPercentageCrop: Crop) => {
                setDraftCrops((draftCrops) => ({
                  ...draftCrops,
                  [imageCrop.id]: newPercentageCrop,
                }));
              }}
            />
          </CardContent>
          <CardActions>
            <Typography sx={{ flexGrow: 1 }}>
              Formato: {imageCrop.px_title}
            </Typography>
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                setDraftCrops((draftCrops) => ({
                  ...draftCrops,
                  [imageCrop.id]: getFreeCrop(imageCrop.options.aspect),
                }));
              }}
            >
              <SettingsOverscanIcon />
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                if (typeof imageCrop.options.aspect !== "undefined") {
                  getCenterCrop(imageCrop.options.aspect);
                }
              }}
            >
              <CenterFocusWeakIcon />
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                setDraftCrops((draftCrops) => {
                  const { [imageCrop.id]: id, ...updatedDraftCrops } =
                    draftCrops;

                  return updatedDraftCrops;
                });
              }}
            >
              <RestoreIcon />
            </IconButton>
            <IconButton
              size="small"
              color="secondary"
              onClick={() => {
                if (typeof draftCrops[imageCrop.id] !== "undefined") {
                  pushCrop(imageCrop.id, draftCrops[imageCrop.id]);
                }
              }}
            >
              <SaveIcon />
            </IconButton>
          </CardActions>
        </Card>
      </Badge>
    </div>
  );
};

export default SelectedImageCrop;
