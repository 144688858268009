import { PostType } from "~/types/views";
import styles from "./PostHeader.module.scss";
import { getImageUrl } from "~/shared/ImageTools";

type Props = {
  post: PostType;
};

const PostHeader = ({ post }: Props) => {
  return (
    <header className={styles.ctn}>
      <h1>{post.title}</h1>
      {post.image ? (
        <img
          src={getImageUrl(`${post.image.url}`)}
          alt={post.image.alt}
          width={900}
          height={600}
        />
      ) : null}

      <p>
        Por <strong>{post.author?.title}</strong> - {post.created_at}
      </p>
      <div className={styles.ctnHr}>
        <hr />
      </div>
    </header>
  );
};

export default PostHeader;
