import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { useSelector } from "react-redux";
import { isLoaderOpen } from "../interfaceSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: "#fff",
  },
}));

export default function Loader() {
  const { classes } = useStyles();

  const isOpen = useSelector(isLoaderOpen);

  return (
    <Backdrop className={classes.backdrop} open={isOpen}>
      <CircularProgress />
    </Backdrop>
  );
}
