import UploadingImagesList from "./UploadingImagesList/UploadingImagesList";
import ImageUploaderToolbar from "./ImageUploaderToolbar/ImageUploaderToolbar";
import { LoadedImages } from "../ImageManager";
import styles from "./ImageUploaderSidebar.module.scss";

type OwnProps = {
  selectedFile: string | undefined;
  setSelectedFile: (index: string) => void;
  loadedFiles: LoadedImages;
  setLoadedFiles: (loadedImages: LoadedImages) => void;
  clearLoadedFile: (filename: string) => void;
  clearLoadedFiles: () => void;
  saveImage: (index: string) => void;
  saveImages: () => void;
};

type Props = OwnProps;

const ImageUploaderSidebar = ({
  clearLoadedFiles,
  saveImages,
  loadedFiles,
  selectedFile,
  setSelectedFile,
  setLoadedFiles,
  clearLoadedFile,
  saveImage,
}: Props) => {
  return (
    <div className={styles.ctn}>
      <div className={styles.ctnToolbar}>
        <ImageUploaderToolbar
          clearLoadedFiles={clearLoadedFiles}
          saveImages={saveImages}
        />
      </div>
      <div className={styles.ctnList}>
        <UploadingImagesList
          loadedFiles={loadedFiles}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setLoadedFiles={setLoadedFiles}
          clearLoadedFile={clearLoadedFile}
          saveImage={saveImage}
        />
      </div>
    </div>
  );
};

export default ImageUploaderSidebar;
