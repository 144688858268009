import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "~/app/store";
import { getEntityView } from "~/api/entitiesAPI";
import { PuxadinhoError } from "~/shared/PuxadinhoError";
import { EntityView } from "~/types/views";

type EntitiesViewData = {
  [id: string]: EntityView;
};

interface EntitiesViews {
  [model: string]: EntitiesViewData;
}

interface EntitiesViewState {
  views: EntitiesViews;
  lastViewedEntityModel?: string;
  lastViewedEntityId?: string;
}

const initialState: EntitiesViewState = {
  views: {},
};

export const entitiesViewSlice = createSlice({
  name: "entitiesView",
  initialState,
  reducers: {
    fetchEntityViewSuccess: (
      state,
      action: PayloadAction<{
        model: string;
        id: string;
        response: EntityView;
      }>
    ) => {
      const { model, id, response } = action.payload;

      if (typeof state.views[model] === "undefined") {
        state.views[model] = {};
      }

      state.views[model][id] = response;
      state.lastViewedEntityId = id;
      state.lastViewedEntityModel = model;
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(
      //   removeEntitySuccess,
      //   (
      //     state,
      //     action: PayloadAction<{
      //       modelList: ModelListProps;
      //       id: string;
      //     }>
      //   ) => {
      //     const { modelList, id } = action.payload;

      //     if (typeof state[modelList.model] !== "undefined") {
      //       delete state[modelList.model][id];
      //     }
      //   }
      // )
      .addDefaultCase((_state, _action) => {
        // console.log({ state, action });
      });
  },
});

export const updateEntityView = (): AppThunk => async (dispatch, getState) => {
  const { model, id } = getLastViewedEntity(getState().data.views);

  if (typeof model !== "undefined" && typeof id !== "undefined") {
    dispatch(fetchEntityView(model, id));
  }
};

export const fetchEntityView =
  (model: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await getEntityView(model, id);

      const { statusCode, result } = response;

      if (statusCode === 200) {
        dispatch(
          fetchEntityViewSuccess({
            response: result,
            model,
            id: String(id),
          })
        );
      }
    } catch (error) {
      if (error instanceof PuxadinhoError) {
        if (error.statusCode === 400 || error.statusCode === 500) {
          // dispatch(showErrorDialog(error.result ));
        }

        if (error.statusCode === 401 || error.statusCode === 419) {
          // dispatch(setLoggedStatus(false));
        }
      }

      console.log(error);
      // console.log(`fetch entity error ${err}`);
      // dispatch(getCommentsFailure(err));
    }
  };

export const selectEntityView = (
  state: EntitiesViewState,
  model: string,
  id: string
) =>
  typeof state.views[model] !== "undefined" &&
  typeof state.views[model][id] !== "undefined"
    ? state.views[model][id]
    : undefined;

export const getLastViewedEntity = (state: EntitiesViewState) => ({
  model: state.lastViewedEntityModel,
  id: state.lastViewedEntityId,
});

export const { fetchEntityViewSuccess } = entitiesViewSlice.actions;
export default entitiesViewSlice.reducer;
