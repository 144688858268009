import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { AppThunk } from "~/app/store";
// import { hideLoader, showLoader } from "~/features/interface/Loader/loaderSlice";
// import { saveImage } from "~/api/imagesApi";
// import { pushEntitySuccess } from "~/features/data/Entity/entitiesSlice";
// import { showSnackbar } from "../Snackbar/snackbarSlice";
// import { PuxadinhoError } from "~/shared/PuxadinhoError";
// import { ListedImage } from "~/types";
// import { PuxadinhoHandledError } from 'types';

type MergedContentState = {
  isMergedContentManagerOpen: boolean;
};

interface MergedContentManagerState {
  [model: string]: MergedContentState;
}

const initialState: MergedContentManagerState = {
  //   errorDialogMessage: {},
  // isMergedContentManagerOpen: false,
};

export const mergeBlockManagerSlice = createSlice({
  name: "mergedContentManager",
  initialState,
  reducers: {
    showMergedContentManager: (
      state,
      action: PayloadAction<{
        model: string;
      }>
    ) => {
      const { model } = action.payload;

      if (typeof state[model] === "undefined") {
        state[model] = {
          isMergedContentManagerOpen: true,
        };
      } else {
        state[model].isMergedContentManagerOpen = true;
      }

      // state.isMergedContentManagerOpen = true;

      //   state.errorDialogMessage = action.payload;
    },
    hideMergedContentManager: (
      state,
      action: PayloadAction<{
        model: string;
      }>
    ) => {
      const { model } = action.payload;

      if (typeof state[model] === "undefined") {
        state[model] = {
          isMergedContentManagerOpen: false,
        };
      } else {
        state[model].isMergedContentManagerOpen = false;
      }
    },
  },
});

export const isMergedContentManagerOpen = (
  state: MergedContentManagerState,
  model: string
) =>
  typeof state[model] !== "undefined" &&
  state[model].isMergedContentManagerOpen === true;

export const { showMergedContentManager, hideMergedContentManager } =
  mergeBlockManagerSlice.actions;
export default mergeBlockManagerSlice.reducer;
