import styles from "./PostLegacyContent.module.scss";

type Props = {
  html: string;
};
const PostLegacyContent = ({ html }: Props) => {
  return (
    <div
      className={styles.ctn}
      dangerouslySetInnerHTML={{ __html: html }}
    ></div>
  );
};

export default PostLegacyContent;
