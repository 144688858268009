import { Link } from "react-router-dom";
import { RPBlockProductType } from "~/types/views";
import styles from "./RPBlockProduct.module.scss";
import { getImageUrl } from "~/shared/ImageTools";

type Props = {
  blockProduct: RPBlockProductType;
  link: string;
};
const RPBlockProduct = ({ blockProduct, link }: Props) => {
  return (
    <div className={styles.ctn}>
      <Link to={link}>
        <div className={styles.ctnText}>
          <h6>{blockProduct.title}</h6>
          {blockProduct.tag !== "" ? <p>{blockProduct.tag}</p> : null}
        </div>
        {blockProduct.image ? (
          <div className={styles.ctnImage}>
            <img
              src={getImageUrl(`${blockProduct.image.url}`)}
              alt={blockProduct.image.alt}
              width={480}
              height={320}
            />
          </div>
        ) : null}
      </Link>
    </div>
  );
};

export default RPBlockProduct;
