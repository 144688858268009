import { createAction } from '@reduxjs/toolkit';

export const clearDraft = createAction<string>('drafts/clearDraft');
export const removeEntitySuccess = createAction<any>(
  'entity/removeEntitySuccess'
);

// export const fetchListSucesss = createAction<any>(
//   'list/fetchListSucesss'
// );
