import { useEffect, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { selectEntitiesFromList } from "~/features/data/dataSlice";
import { fetchList } from "~/features/data/Lists/listsSlice";
import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface DependantRadioFieldType {
  column: string;
  type: string;
  placeholder: string;
  dependency: string;
  rules?: string;
  always?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: DependantRadioFieldType;
  onChange: (column: string, value: string | null, locale?: string) => void;
  locale: string;
};

type Props = PropsFromRedux & OwnProps;

const DependantRadioFieldRaw = ({
  field,
  draftValue,
  onChange,
  fetchList,
  locale,
  list,
}: Props) => {
  // const getOptions = (list: any[]) => {
  //   return (
  //     list
  //       // .map((item) => {
  //       //   return {
  //       //     id: item.id,
  //       //     text: item.px_title,
  //       //   };
  //       // })
  //       .map((item) => (
  //         <FormControlLabel
  //           id={item.id}
  //           value={item.id}
  //           control={<Radio />}
  //           label={item.px_title}
  //         />
  //       ))
  //   );
  // };

  // console.log(list);

  const options = useMemo(
    () =>
      // const options = () => {
      //   return list.map((item: any) => {
      //     console.log(item.id);
      //     return (
      //       <FormControlLabel
      //         id={item.id}
      //         value={String(item.id)}
      //         control={<Radio />}
      //         label={item.px_title}
      //       />
      //     );
      //   });
      // };
      list.map((item: any) => (
        <FormControlLabel
          id={item.id}
          value={String(item.id)}
          control={<Radio />}
          label={item.px_title}
        />
      )),
    [list]
  );

  useEffect(() => {
    console.log("calling fetch entities");
    fetchList({ model: field.dependency });
  }, [field.dependency, fetchList]);

  // console.log(String(draftValue), draftValue);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{field.placeholder}</FormLabel>
      <RadioGroup
        aria-label={field.placeholder}
        name={field.column}
        value={typeof draftValue !== "undefined" ? String(draftValue) : ""}
        onChange={(event) =>
          onChange(field.column, String(event.target.value), locale)
        }
      >
        {options}
      </RadioGroup>
    </FormControl>
  );
};

const makeMapStateToProps = () => {
  // const selectEntitiesList = makeEntitiesListSelector();
  const mapStateToProps = (
    state: RootState,
    { entityModel, entityId, field }: OwnProps
  ) => {
    return {
      list: selectEntitiesFromList(state, { model: field.dependency }),
      draftValue: getDraftColumn(state, entityModel, entityId, field.column),
    };
  };
  return mapStateToProps;
};

// const mapStateToProps = (
//   state: RootState,
//   { entityModel, entityId, field }: OwnProps
// ) => ({
//   list: selectEntitiesList(state, { model: field.dependency }),
//   draftValue: getDraftColumn(
//     state,
//     entityModel,
//     entityId,
//     field.column,
//     locale
//   ),
// });

const mapDispatchToProps = { fetchList };

const connector = connect(makeMapStateToProps, mapDispatchToProps);

const DependantRadioField = connector(DependantRadioFieldRaw);
export default DependantRadioField;
