import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import * as schema from "./listSchema";
import { AppThunk } from "~/app/store";
import {
  hideLoader,
  showLoader,
} from "~/features/interface/Loader/loaderSlice";
import {
  fetchEntitySuccess,
  pushEntitySuccess,
} from "~/features/data/Entity/entitiesSlice";
import {
  Entity,
  ListedEntity,
  ListedImage,
  ListedImageCrop,
  ModelListProps,
  NormalizedListResponse,
  EntityPosition,
  ListedViewableEntity,
} from "~/types";
import { getList } from "~/api/cacheAPI";
import { getEntities, updateEntitiesPosition } from "~/api/entitiesAPI";
import { PuxadinhoError } from "~/shared/PuxadinhoError";
import { removeEntitySuccess } from "~/redux/actions";
import { showSnackbar } from "~/features/interface/Snackbar/snackbarSlice";
import { History } from "history";
import { updateEntityView } from "../EntityView/entitiesViewSlice";

type ListedEntities = {
  [id: string]:
    | Entity
    | ListedEntity
    | ListedImage
    | ListedImageCrop
    | ListedViewableEntity;
};

type SubList = {
  ids: string[];
  fetchTime?: number;
};

type SubLists = {
  [key: string]: SubList;
};

type EntitiesList = {
  lastFetchTime?: number;
  entities: ListedEntities;
  sublists: SubLists;
};

interface ListsState {
  [model: string]: EntitiesList;
}

const initialState: ListsState = {};

export const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {
    fetchListSuccess: (
      state,
      action: PayloadAction<{
        modelList: ModelListProps;
        fetchTime: number;
        response: NormalizedListResponse<ListedEntity>;
      }>
    ) => {
      const { modelList, fetchTime, response } = action.payload;
      if (typeof state[modelList.model] === "undefined") {
        state[modelList.model] = {
          entities: response.entities.list,
          sublists: {
            [getSublistNamespace(modelList)]: {
              ids: response.result,
              fetchTime,
            },
          },
          lastFetchTime: fetchTime,
        };
      } else {
        state[modelList.model].entities = {
          ...state[modelList.model].entities,
          ...response.entities.list,
        };

        state[modelList.model].sublists[getSublistNamespace(modelList)] = {
          ids: response.result,
          fetchTime,
        };

        state[modelList.model].lastFetchTime = fetchTime;
      }
    },
    updatePosition: (
      state,
      action: PayloadAction<{
        modelList: ModelListProps;
        anchorId: string;
        movingId: string;
      }>
    ) => {
      const { modelList, anchorId, movingId } = action.payload;
      const ids =
        state[modelList.model].sublists[getSublistNamespace(modelList)].ids;

      const movingIndex = ids.indexOf(String(movingId));
      const anchorIndex = ids.indexOf(String(anchorId));

      // state[modelList.model].sublists[
      //   getSublistNamespace(modelList)
      // ].ids.splice(movingIndex, 1);
      // state[modelList.model].sublists[
      //   getSublistNamespace(modelList)
      // ].ids.splice(anchorIndex, 0, String(movingId));

      ids.splice(movingIndex, 1);
      ids.splice(anchorIndex, 0, String(movingId));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        pushEntitySuccess,
        (
          state,
          action: PayloadAction<{
            modelList: ModelListProps;
            id: string;
            response: Entity;
          }>
        ) => {
          const { modelList, id, response } = action.payload;

          if (typeof state[modelList.model] !== "undefined") {
            state[modelList.model].entities[id] = response;

            if (
              state[modelList.model].sublists[
                getSublistNamespace(modelList)
              ].ids.indexOf(id) === -1
            ) {
              //this is ugly
              if (typeof response.position !== "undefined") {
                state[modelList.model].sublists[
                  getSublistNamespace(modelList)
                ].ids.push(id);
              } else {
                state[modelList.model].sublists[
                  getSublistNamespace(modelList)
                ].ids.unshift(id);
              }
            }
          }
        }
      )
      .addCase(
        removeEntitySuccess,
        (
          state,
          action: PayloadAction<{
            modelList: ModelListProps;
            id: string;
          }>
        ) => {
          const { modelList, id } = action.payload;

          delete state[modelList.model].entities[id];

          const index =
            state[modelList.model].sublists[
              getSublistNamespace(modelList)
            ].ids.indexOf(id);

          state[modelList.model].sublists[
            getSublistNamespace(modelList)
          ].ids.splice(index, 1);
        }
      )
      .addCase(
        fetchEntitySuccess,
        (
          state,
          action: PayloadAction<{
            modelList: ModelListProps;
            id: string;
            response: Entity;
          }>
        ) => {
          const { modelList, id, response } = action.payload;

          //there might be a race condition here (fetch entity returns before list)
          if (
            typeof state[modelList.model] === "undefined" ||
            typeof state[modelList.model].entities === "undefined"
          )
            return;

          state[modelList.model].entities[id] = response;

          if (
            state[modelList.model].sublists[
              getSublistNamespace(modelList)
            ].ids.indexOf(id) === -1
          ) {
            //this is ugly
            if (typeof response.position !== "undefined") {
              state[modelList.model].sublists[
                getSublistNamespace(modelList)
              ].ids.push(id);
            } else {
              state[modelList.model].sublists[
                getSublistNamespace(modelList)
              ].ids.unshift(id);
            }
          }
        }
      )
      // .addCase(
      //   copySuccess,
      //   (state, action: PayloadAction<{ modelList: ModelListProps }>) => {
      //     const { modelList } = action.payload;
      //   }
      // )
      .addDefaultCase((_state, _action) => {
        // console.log({ state, action });
      });
  },
});

export const fetchList =
  (
    modelList: ModelListProps,
    _history?: History,
    forceFetch = false
  ): AppThunk =>
  async (dispatch, getState) => {
    //this is not good
    const listsState = getState().data.lists;

    if (!hasBeenFetched(listsState, modelList) || forceFetch) {
      try {
        dispatch(showLoader({ queueType: "fetchEntities" }));

        try {
          const cachedList = getList(modelList);

          if (typeof cachedList !== "undefined") {
            dispatch(
              fetchListSuccess({
                response: normalize(cachedList, schema.list),
                fetchTime: Date.now(),
                modelList,
              })
            );

            dispatch(hideLoader({ queueType: "fetchEntities" }));
          }
        } catch (error) {
          console.log(error);
        }

        const response = await getEntities(modelList);

        const { statusCode, fetchTime, result } = response;

        if (statusCode === 200) {
          dispatch(
            fetchListSuccess({
              response: normalize(result, schema.list),
              fetchTime,
              modelList,
            })
          );

          if (
            typeof modelList.isListOfEntities !== "undefined" &&
            modelList.isListOfEntities
          ) {
            result.forEach((entity) => {
              dispatch(
                fetchEntitySuccess({
                  response: entity,
                  modelList,
                  id: String(entity.id),
                })
              );
            });
          }
        }

        dispatch(hideLoader({ queueType: "fetchEntities" }));
      } catch (error) {
        if (error instanceof PuxadinhoError) {
          if (error.statusCode === 400 || error.statusCode === 500) {
            // if (typeof history !== 'undefined') {
            //   history.push('../');
            // }
            // dispatch(showErrorDialog(error.result ));
          }

          if (error.statusCode === 401 || error.statusCode === 419) {
            // dispatch(setLoggedStatus(false));
          }
        }

        console.log(error);
        // console.log(`fetch entities error ${err}`);
        // console.log({ err });
        // dispatch(getCommentsFailure(err));
      }
    }
  };

export const fetchPositions =
  (modelList: ModelListProps): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(showLoader({ queueType: "fetchPositions" }));

      const response = await getEntities(modelList);

      const { statusCode, result, fetchTime } = response;

      if (statusCode === 200) {
        dispatch(
          showSnackbar({
            message: "Posições resetadas com sucesso",
            type: "success",
          })
        );

        // dispatch(
        //   resetPositions({
        //     response: normalize(result, schema.arrayOfEntities),
        //     modelList,
        //   })
        // );
        dispatch(
          fetchListSuccess({
            response: normalize(result, schema.list),
            fetchTime,
            modelList,
          })
        );
      }

      dispatch(hideLoader({ queueType: "fetchPositions" }));
    } catch (error) {
      if (error instanceof PuxadinhoError) {
        if (error.statusCode === 400 || error.statusCode === 500) {
          // dispatch(showErrorDialog(error.result ));
        }

        if (error.statusCode === 401 || error.statusCode === 419) {
          // dispatch(setLoggedStatus(false));
        }
      }

      console.log(error);
      // console.log(`updating positions error ${err}`);
    }
  };

export const pushPositions =
  (modelList: ModelListProps): AppThunk =>
  async (dispatch, getState) => {
    const listsState = getState().data.lists;

    try {
      dispatch(showLoader({ queueType: "pushPositions" }));

      const entitiesWithPositions = getEntitiesWithPositions(
        listsState,
        modelList
      );

      const response = await updateEntitiesPosition(
        modelList.model,
        entitiesWithPositions
      );

      const { statusCode } = response;

      if (statusCode === 200) {
        dispatch(
          showSnackbar({
            message: "Posições salvas com sucesso",
            type: "success",
          })
        );

        dispatch(updateEntityView());
      }

      dispatch(hideLoader({ queueType: "pushPositions" }));
    } catch (error) {
      if (error instanceof PuxadinhoError) {
        if (error.statusCode === 400 || error.statusCode === 500) {
          // dispatch(showErrorDialog(error.result ));
        }

        if (error.statusCode === 401 || error.statusCode === 419) {
          // dispatch(setLoggedStatus(false));
        }
      }

      console.log(error);
      // console.log(`updating positions error ${err}`);
    }
  };

const getEntitiesWithPositions = (
  state: ListsState,
  modelList: ModelListProps
) => {
  const diff: EntityPosition[] = [];

  // const selectEntitiesFromList = makeEntitiesFromListSelector();

  // console.log(state, modelList);
  // console.log(selectEntitiesFromList(state, modelList));

  selectEntitiesFromList(state, modelList).forEach(
    (entity: ListedEntity, index: number) => {
      // console.log(entity.position + ":" + index);
      if (entity.position !== index + 1) {
        diff.push({ [entity.id]: index + 1 });
      }
    }
  );

  // console.log(diff);

  return diff;
};

const selectIds = (state: ListsState, modelList: ModelListProps) =>
  hasBeenFetched(state, modelList)
    ? state[modelList.model].sublists[getSublistNamespace(modelList)].ids
    : [];

const selectList = (state: ListsState, modelList: ModelListProps) =>
  hasBeenFetched(state, modelList)
    ? state[modelList.model].entities
    : undefined;

// export const makeEntitiesListSelector = () => {
//   return createSelector([selectIds, selectList], (entities, list) => {
//     return typeof list !== 'undefined'
//       ? entities
//           .filter((id: string) => typeof list[id] !== 'undefined')
//           .map((id: string) => list[id])
//       : [];
//   });
// };

// export const selectEntitiesFromList = createSelector(
//   [selectIds, selectList],
//   (entities, list) => {
//     return typeof list !== 'undefined'
//       ? entities
//           .filter((id: string) => typeof list[id] !== 'undefined')
//           .map((id: string) => list[id])
//       : [];
//   }
// );

export const makeEntitiesFromListSelector = () => {
  return createSelector([selectIds, selectList], (ids, list) => {
    return typeof list !== "undefined"
      ? ids
          .filter((id: string) => typeof list[id] !== "undefined")
          .map((id: string) => list[id])
      : [];
  });
};

export const selectEntitiesFromList = (
  state: ListsState,
  modelList: ModelListProps
) => {
  const list = selectList(state, modelList);
  const ids = selectIds(state, modelList);

  return typeof list !== "undefined"
    ? ids
        .filter((id: string) => typeof list[id] !== "undefined")
        .map((id: string) => list[id])
    : [];
};

export const selectEntitiesFromLists = (
  state: ListsState,
  modelLists: ModelListProps[]
) => {
  return modelLists
    .map((modelList) =>
      selectEntitiesFromList(state, modelList).map((entity) => ({
        model: modelList.model,
        ...entity,
      }))
    )
    .flat(1);
};

export const selectEntityFromList = (
  state: ListsState,
  model: string,
  id: string
) => {
  return typeof state[model] !== "undefined" &&
    typeof state[model].entities !== "undefined" &&
    typeof state[model].entities[id] !== "undefined"
    ? state[model].entities[id]
    : undefined;
};

export const getSublistNamespace = (modelList: ModelListProps) => {
  return `${modelList.model}_${modelList.parentModel}_${modelList.parentEntityId}`;
};

const hasBeenFetched = (state: ListsState, modelList: ModelListProps) =>
  typeof state[modelList.model] !== "undefined" &&
  typeof state[modelList.model].sublists[getSublistNamespace(modelList)] !==
    "undefined";

export const { fetchListSuccess, updatePosition } = listsSlice.actions;
export default listsSlice.reducer;
