import { useEffect } from "react";
import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import { connect, ConnectedProps } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface ToggleFieldType {
  column: string;
  type: string;
  placeholder: string;
  rules?: string;
  always?: boolean;
  default?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: ToggleFieldType;
  // draft: any;
  onChange: (column: string, value: boolean, locale?: string) => void;
  locale: string;
};

type Props = PropsFromRedux & OwnProps;

const ToggleFieldRaw = ({
  field,
  draftValue,
  onChange,
  locale,
  entityId,
}: Props) => {
  useEffect(() => {
    if (
      typeof entityId === "undefined" &&
      typeof draftValue === "undefined" &&
      typeof field.default !== "undefined"
    ) {
      onChange(field.column, field.default, locale);
    }
  }, [entityId, draftValue, field, locale, onChange]);

  return (
    <FormGroup row>
      <FormControlLabel
        label={field.placeholder}
        control={
          <Switch
            size="small"
            onChange={(event) => {
              onChange(field.column, event.target.checked, locale);
            }}
            checked={
              typeof draftValue !== "undefined"
                ? Boolean(draftValue)
                : typeof field.default !== "undefined"
                ? field.default
                : false
            }
          />
        }
      />
    </FormGroup>
  );
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId, field }: OwnProps
) => {
  return {
    draftValue: getDraftColumn(state, entityModel, entityId, field.column),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const ToggleField = connector(ToggleFieldRaw);
export default ToggleField;
