import { useEffect, useMemo } from "react";
import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import { connect, ConnectedProps } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

type PropsFromRedux = ConnectedProps<typeof connector>;

type Option = {
  id: string;
  px_title: string;
};

export interface RadioFieldType {
  column: string;
  type: string;
  placeholder: string;
  options: Option[];
  rules?: string;
  always?: boolean;
  default?: string;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: RadioFieldType;
  // draft: any;
  onChange: (column: string, value: string, locale?: string) => void;
  locale?: string;
};

type Props = PropsFromRedux & OwnProps;

const RadioFieldRaw = ({
  field,
  draftValue,
  onChange,
  locale,
  entityId,
}: Props) => {
  useEffect(() => {
    if (
      typeof entityId === "undefined" &&
      typeof draftValue === "undefined" &&
      typeof field.default !== "undefined"
    ) {
      onChange(field.column, field.default, locale);
    }
  }, [entityId, draftValue, field, locale, onChange]);

  const options = useMemo(
    () =>
      field.options.map((option) => (
        <FormControlLabel
          key={option.id}
          value={option.id}
          control={<Radio size="small" />}
          label={option.px_title}
        />
      )),
    [field.options]
  );

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{field.placeholder}</FormLabel>
      <RadioGroup
        row
        aria-label={field.placeholder}
        name={field.column}
        value={typeof draftValue !== "undefined" ? draftValue : ""}
        onChange={(event) => onChange(field.column, event.target.value, locale)}
      >
        {options}
      </RadioGroup>
    </FormControl>
  );
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId, field }: OwnProps
) => ({
  draftValue: getDraftColumn(state, entityModel, entityId, field.column),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const RadioField = connector(RadioFieldRaw);
export default RadioField;
