import { RPBlockComponentType } from "~/types/views";
import RecipeBlockComponent from "../RecipeBlockComponent/RecipeBlockComponent";
import { getRecipeBlockComponentsGroups } from "../RecipeViewTools";
import styles from "./RecipeBlockComponents.module.scss";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import { Fragment } from "react";

type Props = {
  recipeBlockComponents: RPBlockComponentType[];
  link: string;
};

const RecipeBlockComponents = ({ recipeBlockComponents, link }: Props) => {
  return (
    <div className={styles.ctn}>
      {getRecipeBlockComponentsGroups(recipeBlockComponents).map(function (
        group,
        groupIndex
      ) {
        if (group.type === "step") {
          return (
            <div key={groupIndex} className={styles.recipeBlockComponent}>
              <h5>Modo de preparo</h5>
              <ol start={group.startingComponentsPosition}>
                {group.content.map(
                  (recipeBlockComponent, recipeBlockComponentIndex) => {
                    return (
                      <RecipeBlockComponent
                        recipeBlockComponent={recipeBlockComponent}
                        key={recipeBlockComponentIndex}
                        link={`${link}/recipeBlockComponent/${recipeBlockComponent.id}`}
                      />
                    );
                  }
                )}
              </ol>
            </div>
          );
        }

        return (
          <Fragment key={groupIndex}>
            {group.content.map(
              (recipeBlockComponent, recipeBlockComponentIndex) => {
                return (
                  <div
                    key={recipeBlockComponentIndex}
                    className={styles.recipeBlockComponent}
                  >
                    <Link
                      className={styles.editButton}
                      to={`${link}/recipeBlockComponent/${recipeBlockComponent.id}`}
                    >
                      <IconButton aria-label="delete" size="small">
                        <BuildCircleIcon fontSize="inherit" />
                      </IconButton>
                    </Link>
                    <RecipeBlockComponent
                      recipeBlockComponent={recipeBlockComponent}
                      link={`${link}/recipeBlockComponent/${recipeBlockComponent.id}`}
                    />
                  </div>
                );
              }
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default RecipeBlockComponents;
