import { RecipeType } from "~/types/views";
import styles from "./RecipeHeader.module.scss";
import { Link } from "react-router-dom";
import { getImageUrl } from "~/shared/ImageTools";

type Props = {
  recipe: RecipeType;
  link: string;
};

const RecipeHeader = ({ recipe, link }: Props) => {
  return (
    <header className={styles.ctn}>
      <Link to={link}>
        {recipe.image ? (
          <img
            src={getImageUrl(`${recipe.image.url}`)}
            alt={recipe.image.alt}
            width={900}
            height={600}
          />
        ) : null}
        <div className={styles.ctnText}>
          <h1>{recipe.title}</h1>
          <p>{recipe.description}</p>
          <hr />
        </div>
      </Link>
    </header>
  );
};

export default RecipeHeader;
