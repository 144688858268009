import { Link as RouterLink } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import { getModel } from "~/features/layout/layoutSlice";
import Chip from "@mui/material/Chip";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    // justifyContent: "center",
    // width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    overflow: "hidden",
    alignItems: "center",
  },

  chipWithPxTitle: {
    overflow: "hidden",
    flex: "1 1 auto",
  },

  chipWithModelTitle: {
    flex: "0 0 auto",
  },
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  entity?: any;
  entityParent?: any;
  baseUrl: string;
  activeModel: string;
};

type Props = PropsFromRedux & OwnProps;

const NavigatorDescriptionRaw = ({
  entity,
  entityParent,
  baseUrl,
  model,
}: Props) => {
  const { classes } = useStyles();

  return (
    // <AppBar position="static" color="secondary">
    //   <Toolbar>
    <div className={classes.root}>
      {typeof entityParent !== "undefined" ? (
        <Chip
          to={`../../${entityParent.id}`}
          component={RouterLink}
          size="small"
          label={String(entityParent.px_title)}
          className={classes.chipWithPxTitle}
          clickable
          color="secondary"
          icon={<KeyboardReturnIcon />}
        />
      ) : null}
      <Chip
        // to={`../../${entityParent.id}`}
        // component={RouterLink}
        size="small"
        label={String(model?.displayTitle)}
        className={classes.chipWithModelTitle}
        // clickable
        color="secondary"
        variant="outlined"
      />
      {/* <Breadcrumbs aria-label="breadcrumb"> */}
      {/* <Chip size="small" label={model.displayTitle} /> */}
      {/* <Button
        color="secondary"
        to={baseUrl}
        component={RouterLink}
        startIcon={<ListIcon />}
      >
        {model.displayTitle}
      </Button> */}
      {/* <Chip
        to={baseUrl}
        component={RouterLink}
        size="small"
        className={classes.chip}
        label={model.displayTitle}
        icon={<ListIcon />}
        clickable
      /> */}
      {typeof entity !== "undefined" ? (
        <Chip
          to={`${baseUrl}${entity.id}`}
          component={RouterLink}
          size="small"
          label={String(entity.px_title)}
          className={classes.chipWithPxTitle}
          clickable
          color="secondary"
        />
      ) : // <Button
      //   color="secondary"
      //   to={`${baseUrl}${entity.id}`}
      //   component={RouterLink}
      // >
      //   {/* {entity.px_title} */}
      //   Voltar
      // </Button>
      null}
      {/* </Breadcrumbs> */}
    </div>
    //   </Toolbar>
    // </AppBar>
  );
};

const mapStateToProps = (state: RootState, { activeModel }: OwnProps) => ({
  model: getModel(state, activeModel),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const NavigatorDescription = connector(NavigatorDescriptionRaw);
export default NavigatorDescription;
