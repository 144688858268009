import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { fetchEntityView } from "~/features/data/EntityView/entitiesViewSlice";
import styles from "./EntityView.module.css";
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import { RootState } from "~/app/store";
import ModelView from "./ModelView";
import { getModel } from "~/features/layout/layoutSlice";
import EntityViewWithRouter from "./EntityViewWithRouter";

type PropsFromRedux = ConnectedProps<typeof connector>;

type PathParams = {
  activeModel: string;
  id?: string;
};

type OwnProps = {
  parentModel?: string;
  parentEntityId?: string;
  baseUrl: string;
};

type PathAndOwnProps = RouteComponentProps<PathParams> & OwnProps;

type Props = PathAndOwnProps & PropsFromRedux;

export class EntityView extends Component<Props> {
  componentDidMount() {
    this.fetchEntityView();
  }

  componentDidUpdate(prevProps: Props) {
    const { entityId, entityModel } = this.props;

    if (
      entityModel !== prevProps.entityModel ||
      entityId !== prevProps.entityId
    ) {
      this.fetchEntityView();
    }
  }

  fetchEntityView() {
    const { entityModel, entityId, fetchEntityView, model } = this.props;

    if (
      typeof entityId !== "undefined" &&
      typeof model !== "undefined" &&
      model.hasOverview
    ) {
      fetchEntityView(entityModel, entityId);
    }
  }

  render() {
    const {
      entityModel,
      entityId,
      model,
      match: { url },
    } = this.props;

    return (
      <>
        <Switch>
          <Route path={`${url}/:activeModel/:id`}>
            <EntityViewWithRouter
              parentModel={entityModel}
              parentEntityId={entityId}
              baseUrl={url}
            />
          </Route>
        </Switch>
        {typeof model !== "undefined" && model.hasOverview ? (
          <div className={styles.container}>
            <div className={styles.viewContainer}>
              {typeof entityId !== "undefined" ? (
                <ModelView entityId={entityId} entityModel={entityModel} />
              ) : (
                <div className={styles.previewContainer}>
                  <h1 className={styles.preview}>[ Preview ]</h1>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  { match: { params } }: PathAndOwnProps
) => ({
  entityModel: params.activeModel,
  entityId: params.id,
  model: getModel(state, params.activeModel),
});

const mapDispatchToProps = {
  fetchEntityView,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(EntityView));
