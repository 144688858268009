import { useHistory } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import { Theme, alpha } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import SearchIcon from "@mui/icons-material/Search";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import { getModel } from "~/features/layout/layoutSlice";
import {
  pushPositions,
  fetchPositions,
} from "~/features/data/Lists/listsSlice";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { ModelListProps } from "~/types";
import { resetDraftForNewEntity } from "~/features/drafts/draftsSlice";
import { InputAdornment } from "@mui/material";
import FormatClearIcon from "@mui/icons-material/FormatClear";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    zIndex: 0,
  },

  toolbar: {
    paddingLeft: 16,
    paddingRight: 16,
  },

  menuButton: {
    // marginLeft: theme.spacing(1),
    // marginRight: -theme.spacing(1),
  },

  // title: {
  //   flexGrow: 1,
  //   display: 'none',
  //   [theme.breakpoints.up('sm')]: {
  //     display: 'block',
  //   },
  // },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
  },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  inputRoot: {
    width: "100%",
    color: "inherit",
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    // [theme.breakpoints.up('sm')]: {
    //   width: '12ch',
    //   '&:focus': {
    //     width: '20ch',
    //   },
    // },
  },
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  searchString: string;
  setSearchString: (text: string) => void;
  baseUrl: string;
  activeModel: string;
  parentModel?: string;
  parentEntityId?: string;
};

type Props = PropsFromRedux & OwnProps;

const EntitiesListHeaderRaw = ({
  pushPositions,
  fetchPositions,
  resetDraft,
  model,
  parentModel,
  parentEntityId,
  activeModel,
  searchString,
  setSearchString,
  baseUrl,
}: Props) => {
  const history = useHistory();

  const { classes } = useStyles();

  const getPositionButtons = () => {
    return typeof model !== "undefined" && model.hasOrder
      ? [
          <IconButton
            key="save"
            // edge="end"
            size="small"
            className={classes.menuButton}
            color="inherit"
            aria-label="Save Positions"
            onClick={() => {
              pushPositions({
                model: activeModel,
                parentModel,
                parentEntityId,
              });
            }}
          >
            <OfflinePinIcon />
          </IconButton>,
          <IconButton
            key="reset"
            // edge="end"
            size="small"
            className={classes.menuButton}
            color="inherit"
            aria-label="Reset Positions"
            onClick={() => {
              fetchPositions({
                model: activeModel,
                parentModel,
                parentEntityId,
              });
            }}
          >
            <SettingsBackupRestoreIcon />
          </IconButton>,
        ]
      : null;
  };

  return (
    <AppBar position="static" color="secondary" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            value={searchString}
            // placeholder={baseUrl}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={(event) => {
              setSearchString(event.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear field"
                  onClick={() => {
                    setSearchString("");
                  }}
                  size="small"
                >
                  <FormatClearIcon fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        {getPositionButtons()}
        <IconButton
          // edge="end"
          size="small"
          className={classes.menuButton}
          color="inherit"
          aria-label="open drawer"
          onClick={() => {
            resetDraft({
              model: activeModel,
              parentModel,
              parentEntityId,
            });
            history.push(baseUrl);
          }}
        >
          <PlusOneIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state: RootState, { activeModel }: OwnProps) => ({
  model: getModel(state, activeModel),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  pushPositions: (modelList: ModelListProps) =>
    dispatch(pushPositions(modelList)),
  fetchPositions: (modelList: ModelListProps) =>
    dispatch(fetchPositions(modelList)),
  resetDraft: (modelList: ModelListProps) =>
    dispatch(resetDraftForNewEntity(modelList)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const EntitiesListHeader = connector(EntitiesListHeaderRaw);

export default EntitiesListHeader;
