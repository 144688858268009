import { DictionaryEntryType } from "~/types/views";
import styles from "./DictionaryEntryHeader.module.scss";
import { Link } from "react-router-dom";
import { getImageUrl } from "~/shared/ImageTools";

type Props = {
  dictionaryEntry: DictionaryEntryType;
  link: string;
};

const DictionaryEntryHeader = ({ dictionaryEntry, link }: Props) => {
  return (
    <header className={styles.ctn}>
      <Link to={link}>
        {dictionaryEntry.image ? (
          <img
            src={getImageUrl(`${dictionaryEntry.image.url}`)}
            alt={dictionaryEntry.image.alt}
            width={900}
            height={600}
          />
        ) : null}
        <div className={styles.ctnText}>
          <h1>{dictionaryEntry.title}</h1>
          <p>{dictionaryEntry.description}</p>
          <hr />
        </div>
      </Link>
    </header>
  );
};

export default DictionaryEntryHeader;
