import styles from "./PostRelationships.module.scss";
import { RPRelationshipType } from "~/types/views";
import PostRelationship from "../PostRelationship/PostRelationship";
import { Link } from "react-router-dom";

type Props = {
  relationships: RPRelationshipType[];
  link: string;
};
const PostRelationships = ({ relationships, link }: Props) => {
  if (!relationships.length) {
    return null;
  }

  return (
    <div className={styles.ctn}>
      <Link to={`${link}/relationship/`}>
        <div className={styles.header}>
          <h5>Receitas relacionadas</h5>
        </div>
      </Link>

      <div className={styles.contentColumns2}>
        {relationships.map((relationship, relationshipIndex) => (
          <PostRelationship
            key={relationshipIndex}
            relationship={relationship}
            link={`${link}/postRelationship/${relationship.id}`}
          />
        ))}
      </div>
    </div>
  );
};

export default PostRelationships;
