import { RootState } from "~/app/store";
import { connect, ConnectedProps } from "react-redux";
import {
  pushEntity,
  removeEntity,
  duplicateEntity,
} from "~/features/data/Entity/entitiesSlice";
import { getDraft, isDraftSaveable } from "~/features/drafts/draftsSlice";
import { showEntityDependencies } from "~/features/interface/DependencyDialog/dependencyDialogSlice";
import { setCopyDestination } from "~/features/interface/Copy/copySlice";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PreviewIcon from "@mui/icons-material/Preview";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import Filter1Icon from "@mui/icons-material/Filter1";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { useHistory } from "react-router-dom";
import { getModel } from "~/features/layout/layoutSlice";

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  entityModel: string;
  entityId?: string;
  parentModel?: string;
  parentEntityId?: string;
  // draft: any;
  // isDraftSaveable: boolean;
};

type Props = PropsFromRedux & OwnProps;

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    zIndex: 0,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

const EntityToolbarRaw = ({
  pushEntity,
  removeEntity,
  duplicateEntity,
  showEntityDependencies,
  setCopyDestination,
  entityModel,
  entityId,
  parentModel,
  parentEntityId,
  draft,
  model,
}: Props) => {
  const { classes } = useStyles();
  const history = useHistory();

  return (
    <AppBar position="static" color="secondary" className={classes.root}>
      <Toolbar>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            if (typeof draft !== "undefined") {
              pushEntity(
                {
                  model: entityModel,
                  parentModel,
                  parentEntityId,
                },
                entityId,
                draft
              );
            }
          }}
          className={classes.menuButton}
        >
          Salvar
        </Button>
        {typeof entityId !== "undefined" && typeof model !== "undefined" ? (
          <>
            {model.hasDelete ? (
              <Button
                variant="contained"
                size="small"
                onClick={() =>
                  removeEntity(
                    {
                      model: entityModel,
                      parentModel,
                      parentEntityId,
                    },
                    entityId,
                    history
                  )
                }
                className={classes.menuButton}
              >
                Deletar
              </Button>
            ) : null}
            {model.hasDependencies ? (
              <IconButton
                size="medium"
                onClick={() =>
                  showEntityDependencies(
                    {
                      model: entityModel,
                      parentModel,
                      parentEntityId,
                    },
                    entityId
                  )
                }
                color="primary"
                className={classes.menuButton}
              >
                <ShareIcon fontSize="inherit" />
              </IconButton>
            ) : null}

            {typeof model.canReceiveCopiedContentFrom !== "undefined" &&
            model.canReceiveCopiedContentFrom.length ? (
              <IconButton
                size="medium"
                onClick={() =>
                  setCopyDestination({
                    model: entityModel,
                    id: entityId,
                    parentModel,
                    parentEntityId,
                  })
                }
                color="primary"
              >
                <ShoppingBagOutlinedIcon fontSize="inherit" />
              </IconButton>
            ) : null}

            {typeof model.canBeDuplicated !== "undefined" &&
            model.canBeDuplicated ? (
              <IconButton
                size="medium"
                onClick={() => {
                  duplicateEntity(
                    {
                      model: entityModel,
                    },
                    entityId
                  );
                }}
                color="primary"
              >
                <ContentCopyIcon fontSize="inherit" />
              </IconButton>
            ) : null}

            {typeof model.hasOverview !== "undefined" &&
            model.hasOverview &&
            entityId ? (
              <IconButton
                size="medium"
                href={`/panam/${model.entity}/${entityId}?hash=${
                  import.meta.env.VITE_APP_PANAM_CDN_HASH
                }`}
                target="_blank"
                color="primary"
              >
                <PreviewIcon fontSize="inherit" />
              </IconButton>
            ) : null}

            {typeof model.hasOverview !== "undefined" &&
            model.hasOverview &&
            entityId ? (
              <IconButton
                size="medium"
                href={`/cdn/${model.entity}/${entityId}?hash=${
                  import.meta.env.VITE_APP_PANAM_CDN_HASH
                }&bomb=1`}
                target="_blank"
                color="primary"
              >
                <CurrencyExchangeIcon fontSize="inherit" />
              </IconButton>
            ) : null}

            {typeof model.hasOverview !== "undefined" &&
            model.hasOverview &&
            entityId ? (
              <IconButton
                size="medium"
                href={`/cdn/${model.entity}/${entityId}?hash=${
                  import.meta.env.VITE_APP_PANAM_CDN_HASH
                }&bomb=0`}
                target="_blank"
                color="primary"
              >
                <Filter1Icon fontSize="inherit" />
              </IconButton>
            ) : null}
          </>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (
  state: RootState,
  { entityId, entityModel }: OwnProps
) => ({
  draft: getDraft(state, entityModel, entityId),
  isDraftSaveable:
    typeof entityId !== "undefined"
      ? isDraftSaveable(state, entityModel, entityId)
      : false,
  model: getModel(state, entityModel),
});

const mapDispatchToProps = {
  pushEntity,
  removeEntity,
  showEntityDependencies,
  setCopyDestination,
  duplicateEntity,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const EntityToolbar = connector(EntityToolbarRaw);

export default EntityToolbar;
