import { connect, ConnectedProps } from "react-redux";
import { selectEntityFromList } from "~/features/data/dataSlice";
import Avatar from "@mui/material/Avatar";
import { RootState } from "~/app/store";
import { ListedImage } from "~/types";

type PropsFromRedux = ConnectedProps<typeof connector>;
type OwnProps = {
  model: string;
  id: string;
};

type Props = PropsFromRedux & OwnProps;

const ListItemImageRaw = ({ image }: Props) => {
  return image ? (
    <Avatar alt={image.px_title.toString()} src={image.px_image_url} />
  ) : (
    <Avatar />
  );
};

const mapStateToProps = (state: RootState, { model, id }: OwnProps) => {
  return {
    image: selectEntityFromList(state, model, id) as ListedImage | undefined,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const ListItemImage = connector(ListItemImageRaw);

export default ListItemImage;
