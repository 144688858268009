import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  selectEntitiesFromList,
  selectEntityFromList,
} from "~/features/data/dataSlice";
import { fetchList } from "~/features/data/Lists/listsSlice";
import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import { showImageManager } from "~/features/interface/ImageManager/imageManagerSlice";
import matchSorter from "match-sorter";
import { ListedImage } from "~/types";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import IconButton from "@mui/material/IconButton";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import {
  getPrefix,
  getSufix,
  mapPrefixToModel,
  splitSearchString,
} from "~/shared/SearchTools";

import VirtualizedImageAutocomplete from "~/special/VirtualizedImageAutocomplete/VirtualizedImageAutocomplete";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface ImageFieldType {
  column: string;
  type: string;
  placeholder: string;
  dependency: string;
  rules?: string;
  always?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: ImageFieldType;
  onChange: (column: string, value: string | null, locale?: string) => void;
  locale: string;
};

type FilterInput = {
  inputValue: string;
};

type Props = PropsFromRedux & OwnProps;

const useStyles = makeStyles()((theme: Theme) => ({
  selector: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },

  containerButtons: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-evenly",
    height: "auto",
    minWidth: 100,
    alignItems: "center",
  },

  button: {
    // marginLeft: theme.spacing(1),
  },

  image: {
    position: "relative",
    width: "50%",
    // paddingBottom: "100%",
  },

  img: {
    // position: "absolute",
    objectFit: "contain",
    objectPosition: "left top",
    width: "100%",
    height: "100%",
    maxHeight: 200,
    // height: "100%",
  },
}));

const getResults = (options: ListedImage[], input: string) => {
  const terms = input.split(" ");

  return terms.reduceRight((list, term) => {
    return matchSorter(list, term, {
      keys: ["naming_titles", "url", "filename"],
    });
  }, options);
};

const filterOptions = (options: ListedImage[], filterInput: FilterInput) => {
  const model = mapPrefixToModel(
    getPrefix(splitSearchString(filterInput.inputValue))
  );

  return model !== null
    ? getResults(
        options.filter((option: ListedImage) =>
          option.naming_types.includes(model)
        ),
        getSufix(splitSearchString(filterInput.inputValue))
      )
    : getResults(options, filterInput.inputValue);
};

// const filterOptions = (options: any, filterInput: FilterInput) => {
//   const terms = filterInput.inputValue.split(" ");

//   return terms.reduceRight((list, term) => {
//     return matchSorter(list, term, { keys: ["px_title"] });
//   }, options);
// };

const ImageFieldRaw = ({
  entityModel,
  entityId,
  field,
  draftValue,
  image,
  onChange,
  fetchList,
  showImageManager,
  locale,
  list,
}: Props) => {
  const { classes } = useStyles();

  useEffect(() => {
    fetchList({ model: field.dependency });
  }, [field.dependency, fetchList]);

  return (
    <>
      <div className={classes.selector}>
        <VirtualizedImageAutocomplete
          filterOptions={filterOptions}
          value={typeof draftValue !== "undefined" ? String(draftValue) : null}
          onChange={(_event: any, newValue: any) => {
            onChange(
              field.column,
              newValue !== null ? String(newValue.id) : null,
              locale
            );
          }}
          options={
            draftValue === null || typeof draftValue === "undefined"
              ? [
                  {
                    id: null,
                    px_title: "",
                    naming_titles: [],
                    naming_types: [],
                    url: "",
                  },
                  ...list,
                ]
              : list
          }
          isOptionEqualToValue={(option: any, value: any) =>
            String(option.id) === String(value)
          }
          getOptionLabel={() => {
            return typeof image !== "undefined" ? image.px_image_url : "";
            // if (typeof list !== "undefined") {
            //   const item = list.find(
            //     (item: any) => String(item.id) === String(option)
            //   );

            //   if (typeof item !== "undefined") {
            //     return item.px_title;
            //   }
            // }

            // return "";
          }}
          label={field.placeholder}
          fullWidth
          autoComplete={false}
          size="small"
        />
        <div className={classes.containerButtons}>
          <IconButton
            aria-label="upload"
            color="secondary"
            className={classes.button}
            // size="small"
            onClick={() =>
              showImageManager({
                model: entityModel,
                id: entityId,
                column: field.column,
                locale,
              })
            }
            size="small"
          >
            <PermMediaIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.image}>
        {image !== undefined ? (
          <img
            className={classes.img}
            alt={String(image.px_title).toString()}
            src={image.px_image_url}
          />
        ) : null}
      </div>
    </>
  );
};

const makeMapStateToProps = () => {
  // const selectEntitiesList = makeEntitiesListSelector();
  const mapStateToProps = (
    state: RootState,
    { entityModel, entityId, field }: OwnProps
  ) => {
    const draftValue = getDraftColumn(
      state,
      entityModel,
      entityId,
      field.column
    );

    return {
      list: selectEntitiesFromList(state, { model: field.dependency }),
      image:
        typeof draftValue !== "undefined"
          ? (selectEntityFromList(
              state,
              field.dependency,
              draftValue
            ) as ListedImage)
          : undefined,
      draftValue: draftValue,
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = { fetchList, showImageManager };

const connector = connect(makeMapStateToProps, mapDispatchToProps);

const ImageField = connector(ImageFieldRaw);
export default ImageField;
