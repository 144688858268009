import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import { connect, ConnectedProps } from "react-redux";

import { mapQuantityToCharacter } from "~/shared/QuantityTools";
import { selectEntityFromList } from "~/features/data/dataSlice";
import {
  getMeasureCorrectTitle,
  needsNumericValue,
} from "~/shared/MeasureTools";
import { Ingredient, Measure } from "~/types";
import { getIngredientCorrectTitle } from "~/shared/IngredientTools";
import Chip from "@mui/material/Chip";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface IngredientListingFieldType {
  columns: string[];
  type: string;
  placeholder: string;
  rules?: string;
  always?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: IngredientListingFieldType;
  // draft: any;
  onChange: (column: string, value: string | null, locale?: string) => void;
  locale: string;
};

type Props = PropsFromRedux & OwnProps;

const IngredientListingFieldRaw = ({
  type,
  description_override,
  quantity,
  complement_cook,
  measure,
  ingredient,
  forcePlural,
}: Props) => {
  return (
    <Chip
      color="primary"
      label={
        ["from-previous-step", "tool"].includes(type) ||
        (typeof description_override !== "undefined" &&
          description_override !== "" &&
          description_override !== null)
          ? description_override
          : [
              mapQuantityToCharacter(
                quantity,
                typeof measure !== "undefined" &&
                  measure !== null &&
                  needsNumericValue(measure.title_singular)
              ),
              getMeasureCorrectTitle(quantity, measure),
              getIngredientCorrectTitle(
                quantity,
                measure,
                ingredient,
                forcePlural
              ),
              complement_cook,
            ]
              .filter((s) => s)
              .join(" ")
      }
      // variant="outlined"
    />
  );
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId }: OwnProps
) => {
  const measureId = getDraftColumn(state, entityModel, entityId, "measure_id");

  const ingredientId = getDraftColumn(
    state,
    entityModel,
    entityId,
    "ingredient_id"
  );

  return {
    quantity: getDraftColumn(
      state,
      entityModel,
      entityId,
      "quantity"
    ) as number,
    complement_cook: getDraftColumn(
      state,
      entityModel,
      entityId,
      "complement_cook"
    ) as string,
    description_override: getDraftColumn(
      state,
      entityModel,
      entityId,
      "description_override"
    ) as string,
    type: getDraftColumn(state, entityModel, entityId, "type") as string,
    measure:
      typeof measureId !== "undefined" && measureId !== null
        ? (selectEntityFromList(state, "measure", measureId) as Measure)
        : undefined,
    ingredient:
      typeof ingredientId !== "undefined" && ingredientId !== null
        ? (selectEntityFromList(
            state,
            "ingredient",
            ingredientId
          ) as Ingredient)
        : undefined,
    forcePlural: getDraftColumn(
      state,
      entityModel,
      entityId,
      "force_plural"
    ) as boolean,
    // draftValue: getDraftColumn(
    //   state,
    //   entityModel,
    //   entityId,
    //   field.column,
    //   locale
    // ),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const IngredientListingField = connector(IngredientListingFieldRaw);
export default IngredientListingField;
