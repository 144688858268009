import { RPRelationshipType } from "~/types/views";
import styles from "./PostRelationship.module.scss";
import { Link } from "react-router-dom";
import { getImageUrl } from "~/shared/ImageTools";

type Props = {
  relationship: RPRelationshipType;
  link: string;
};

const PostRelationship = ({
  relationship: { title, tag, image },
  link,
}: Props) => {
  return (
    <aside className={styles.ctn}>
      <Link to={link}>
        <div className={styles.ctnText}>
          <h6>{title}</h6>
          {tag !== "" ? <p>{tag}</p> : null}
        </div>
        {image ? (
          <div className={styles.ctnImage}>
            <img
              src={getImageUrl(`${image.url}`)}
              alt={image.alt}
              width={300}
              height={200}
            />
          </div>
        ) : null}
      </Link>
    </aside>
  );
};

export default PostRelationship;
