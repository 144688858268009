import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getEntityDependencies,
  exchangeDependencies,
  resolveDependencies,
} from "~/api/entitiesAPI";
import { AppThunk } from "~/app/store";
import { PuxadinhoError } from "~/shared/PuxadinhoError";
import { ModelListProps, Dependencies } from "~/types";
import { showErrorDialog } from "../ErrorDialog/errorDialogSlice";
import { hideLoader, showLoader } from "../Loader/loaderSlice";
import { showSnackbar } from "../Snackbar/snackbarSlice";

interface DependencyDialogState {
  dependencies: Dependencies;
  isDependencyDialogOpen: boolean;
  isDependencyDialogMinimized: boolean;
  modelList: ModelListProps;
  id: string;
}

const initialState: DependencyDialogState = {
  dependencies: {
    link: null,
    list: {},
  },
  isDependencyDialogOpen: false,
  isDependencyDialogMinimized: false,
  modelList: {
    model: "",
  },
  id: "",
};

export const dependencyDialogSlice = createSlice({
  name: "dependencyDialog",
  initialState,
  reducers: {
    showDependencyDialog: (
      state,
      action: PayloadAction<{
        response: Dependencies;
        modelList: ModelListProps;
        id: string;
      }>
    ) => {
      const { response, modelList, id } = action.payload;

      state.isDependencyDialogOpen = true;
      state.isDependencyDialogMinimized = false;
      state.dependencies = response;
      state.modelList = modelList;
      state.id = id;
    },
    hideDependencyDialog: (state) => {
      state.isDependencyDialogOpen = false;
      state.isDependencyDialogMinimized = false;
      state.modelList = {
        model: "",
      };
      state.id = "";
      state.dependencies = {
        link: null,
        list: {},
      };
    },
    maximizeDependencyDialog: (state) => {
      state.isDependencyDialogMinimized = false;
    },
    minimizeDependencyDialog: (state) => {
      state.isDependencyDialogMinimized = true;
    },
  },
});

export const showEntityDependencies =
  (modelList: ModelListProps, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(showLoader({ queueType: "showEntityDependencies" }));

      const response = await getEntityDependencies(modelList, id);

      const { statusCode, result } = response;

      if (statusCode === 200) {
        dispatch(showDependencyDialog({ response: result, modelList, id }));
      }

      dispatch(hideLoader({ queueType: "showEntityDependencies" }));
    } catch (error) {
      if (error instanceof PuxadinhoError) {
        if (error.statusCode === 400 || error.statusCode === 500) {
          dispatch(showErrorDialog(error.result));
        }

        if (error.statusCode === 401 || error.statusCode === 419) {
          // dispatch(setLoggedStatus(false));
        }
      }

      console.log(error);
      // console.log(`remove error ${err}`);
    }

    dispatch(hideLoader({ queueType: "showEntityDependencies" }));
  };

export const runExchangeInDependencies =
  (
    modelList: ModelListProps,
    id: string,
    newModel: string,
    newId: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(showLoader({ queueType: "runExchangeInDependencies" }));

      const response = await exchangeDependencies(
        modelList,
        id,
        newModel,
        newId
      );

      const { statusCode } = response;

      if (statusCode === 200) {
        dispatch(showEntityDependencies(modelList, id));

        dispatch(
          showSnackbar({
            message: "Dependências alteradas com sucesso",
            type: "success",
          })
        );
      }

      dispatch(hideLoader({ queueType: "runExchangeInDependencies" }));
    } catch (error) {
      if (error instanceof PuxadinhoError) {
        if (error.statusCode === 400 || error.statusCode === 500) {
          dispatch(showErrorDialog(error.result));
        }

        if (error.statusCode === 401 || error.statusCode === 419) {
          // dispatch(setLoggedStatus(false));
        }
      }

      console.log(error);
      // console.log(`remove error ${err}`);
    }

    dispatch(hideLoader({ queueType: "runExchangeInDependencies" }));
  };

export const runResolveDependencies =
  (modelList: ModelListProps, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(showLoader({ queueType: "runResolveDependencies" }));

      const response = await resolveDependencies(modelList, id);

      const { statusCode } = response;

      if (statusCode === 200) {
        dispatch(showEntityDependencies(modelList, id));

        dispatch(
          showSnackbar({
            message: "Dependências removidas com sucesso",
            type: "success",
          })
        );
      }

      dispatch(hideLoader({ queueType: "runResolveDependencies" }));
    } catch (error) {
      if (error instanceof PuxadinhoError) {
        if (error.statusCode === 400 || error.statusCode === 500) {
          dispatch(showErrorDialog(error.result));
        }

        if (error.statusCode === 401 || error.statusCode === 419) {
          // dispatch(setLoggedStatus(false));
        }
      }

      console.log(error);
      // console.log(`remove error ${err}`);
    }

    dispatch(hideLoader({ queueType: "runResolveDependencies" }));
  };

export const getDependencyDialogMessage = (state: DependencyDialogState) =>
  state.dependencies;
export const getDependencyDialogEntity = (state: DependencyDialogState) => ({
  modelList: state.modelList,
  id: state.id,
});
export const isDependencyDialogOpen = (state: DependencyDialogState) =>
  state.isDependencyDialogOpen;

export const isDependencyDialogMinimized = (state: DependencyDialogState) =>
  state.isDependencyDialogMinimized;

export const {
  showDependencyDialog,
  hideDependencyDialog,
  maximizeDependencyDialog,
  minimizeDependencyDialog,
} = dependencyDialogSlice.actions;
export default dependencyDialogSlice.reducer;
