import { connect, ConnectedProps } from "react-redux";
import { selectMenuList } from "~/features/layout/layoutSlice";
import { RootState } from "~/app/store";

import List from "@mui/material/List";
import ListItemLink from "~/app/components/ListItemLink";

type PropsFromRedux = ConnectedProps<typeof connector>;

export const ModelsListRaw = ({ list }: PropsFromRedux) => {
  return (
    <List>
      {list.map((item) => (
        <ListItemLink
          to={`/${item.entity}/`}
          primary={item.displayTitle}
          key={item.entity}
        />
      ))}
    </List>
  );
};

const mapStateToProps = (state: RootState) => ({
  list: selectMenuList(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const ModelsList = connector(ModelsListRaw);
export default ModelsList;
