import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

type PropsFromRedux = ConnectedProps<typeof connector>;

type Option = {
  id: string;
  px_title: string;
};

export interface TagFieldType {
  column: string;
  type: string;
  placeholder: string;
  options: Option[];
  dependency: string;
  rules?: string;
  always?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: TagFieldType;
  onChange: (column: string, value: string[], locale?: string) => void;
  locale: string;
};

type Props = PropsFromRedux & OwnProps;

const TagFieldRaw = ({ field, draftValue, onChange, locale }: Props) => {
  const { options } = field;

  return (
    <Autocomplete
      value={typeof draftValue !== "undefined" ? draftValue : []}
      onChange={(_event: any, value: any[]) => {
        onChange(
          field.column,
          value.map((itemOrId) =>
            typeof itemOrId.id !== "undefined"
              ? String(itemOrId.id)
              : String(itemOrId)
          ),
          locale
        );
      }}
      options={options}
      getOptionLabel={(option: any) => {
        const selectedItem = options.find(
          (item: any) => String(item.id) === String(option)
        );

        if (typeof selectedItem !== "undefined") {
          return selectedItem.px_title;
        }

        return "";
      }}
      isOptionEqualToValue={(option: any, value: any) =>
        String(option.id) === String(value)
      }
      fullWidth
      multiple
      disableListWrap
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={field.placeholder}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
        />
      )}
    />
  );
};

const makeMapStateToProps = () => {
  const mapStateToProps = (
    state: RootState,
    { entityModel, entityId, field }: OwnProps
  ) => {
    return {
      draftValue: getDraftColumn(state, entityModel, entityId, field.column),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {};

const connector = connect(makeMapStateToProps, mapDispatchToProps);

const TagField = connector(TagFieldRaw);
export default TagField;
