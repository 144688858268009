import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  selectEntitiesFromLists,
  selectEntityFromList,
} from "~/features/data/dataSlice";
import { fetchList } from "~/features/data/Lists/listsSlice";
import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import matchSorter from "match-sorter";
import VirtualizedAutocomplete from "~/special/VirtualizedAutocomplete/VirtualizedAutocomplete";
import {
  getPrefix,
  getSufix,
  mapPrefixToModel,
  splitSearchString,
} from "~/shared/SearchTools";
import { getFriendlyModelName } from "~/shared/StringMappingTools";

type PropsFromRedux = ConnectedProps<typeof connector>;
export interface MultipleDependantSelectFieldType {
  column: string;
  type: string;
  placeholder: string;
  dependencies: string[];
  rules?: string;
  always?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: MultipleDependantSelectFieldType;
  onChange: (column: string, value: string | null, locale?: string) => void;
  locale: string;
  disabled?: boolean;
  alternateOnChange?: (
    model: string | null,
    id: string | null,
    locale?: string
  ) => void;
};

type FilterInput = {
  inputValue: string;
};

type Props = PropsFromRedux & OwnProps;

const symbolicHomeListedEntity = {
  id: 1,
  model: "home",
  px_title: "Home",
  px_title_aux: "",
};

const symbolicStoreListedEntity = {
  id: 1,
  model: "store",
  px_title: "Loja",
  px_title_aux: "",
};

// function random(length: number) {
//   const characters =
//     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//   let result = "";

//   for (let i = 0; i < length; i += 1) {
//     result += characters.charAt(Math.floor(Math.random() * characters.length));
//   }

//   return result;
// }

// const OPTIONS = Array.from(new Array(10000))
//   .map(() => random(10 + Math.ceil(Math.random() * 20)))
//   .sort((a: string, b: string) =>
//     a.toUpperCase().localeCompare(b.toUpperCase())
//   );

const getResults = (options: any, input: string) => {
  const terms = input.split(" ");

  return terms.reduceRight((list, term) => {
    return matchSorter(list, term, { keys: ["px_title"] });
  }, options);
};

const filterOptions = (options: any, filterInput: FilterInput) => {
  const model = mapPrefixToModel(
    getPrefix(splitSearchString(filterInput.inputValue))
  );

  // return model !== null
  //   ? matchSorter(
  //       options.filter((option) => model == option.model),
  //       getSufix(splitSearchString(filterInput.inputValue)),
  //       {
  //         keys: ["px_title"],
  //       }
  //     )
  //   : matchSorter(options, filterInput.inputValue, {
  //       keys: ["px_title"],
  //     });

  return model !== null
    ? getResults(
        options.filter((option: any) => model === option.model),
        getSufix(splitSearchString(filterInput.inputValue))
      )
    : getResults(options, filterInput.inputValue);
};

const MultipleDependantSelectFieldRaw = ({
  field,
  draftValue,
  onChange,
  alternateOnChange,
  fetchList,
  locale,
  list,
  selectedOption,
  disabled,
}: Props) => {
  useEffect(() => {
    field.dependencies.forEach((dependency) =>
      fetchList({ model: dependency })
    );
  }, [field.dependencies, fetchList]);

  return (
    <VirtualizedAutocomplete
      filterOptions={filterOptions}
      value={
        typeof draftValue.id !== "undefined" ||
        typeof draftValue.model !== "undefined"
          ? draftValue
          : {
              id: null,
              model: null,
            }
      }
      onChange={(_event: any, newValue: any) => {
        onChange(
          field.column + "_type",
          newValue !== null ? newValue.model : null,
          locale
        );
        onChange(
          field.column + "_id",
          newValue !== null ? newValue.id : null,
          locale
        );

        if (alternateOnChange) {
          alternateOnChange(
            newValue !== null ? newValue.model : null,
            newValue !== null ? newValue.id : null,
            locale
          );
        }
      }}
      options={[{ id: null, model: null, px_title: "" }, ...list]}
      // options={OPTIONS}
      isOptionEqualToValue={(option: any, value: any) => {
        return (
          String(option.id) === String(value.id) &&
          String(option.model) === String(value.model)
        );
      }}
      getOptionLabel={() => {
        // console.log({ value, selectedOption });
        return typeof selectedOption !== "undefined"
          ? getFriendlyModelName(draftValue.model) +
              " | " +
              selectedOption.px_title
          : "";
        // if (typeof list !== "undefined") {
        //   const item = list.find(
        //     (item: any) =>
        //       String(item.id) === String(option[field.column + "_id"]) &&
        //       String(item.model) === String(option[field.column + "_type"])
        //   );

        //   if (typeof item !== "undefined") {
        //     return item.t;
        //   }
        // }

        // return "";
      }}
      label={field.placeholder}
      fullWidth
      autoComplete={false}
      disabled={disabled}
    />
  );
};

const makeMapStateToProps = () => {
  // const selectEntitiesList = makeEntitiesListSelector();

  const mapStateToProps = (
    state: RootState,
    { entityModel, entityId, field }: OwnProps
  ) => {
    const id = getDraftColumn(
      state,
      entityModel,
      entityId,
      field.column + "_id"
    );

    const model = getDraftColumn(
      state,
      entityModel,
      entityId,
      field.column + "_type"
    );

    const selectEntityIncludingTheSymbolicOnes = (
      state: RootState,
      model: string,
      id: string
    ) => {
      if (model === "home") {
        return symbolicHomeListedEntity;
      }

      if (model === "store") {
        return symbolicHomeListedEntity;
      }

      return selectEntityFromList(state, model, id);
    };

    return {
      list: [
        symbolicHomeListedEntity,
        symbolicStoreListedEntity,
        ...selectEntitiesFromLists(
          state,
          field.dependencies.map((dependency) => ({ model: dependency }))
        ),
      ],
      draftValue: {
        id,
        model,
      },
      selectedOption: selectEntityIncludingTheSymbolicOnes(state, model, id),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = { fetchList };

const connector = connect(makeMapStateToProps, mapDispatchToProps);

const MultipleDependantSelectField = connector(MultipleDependantSelectFieldRaw);
export default MultipleDependantSelectField;
