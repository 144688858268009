export const PARAGRAPH = "paragraph";
export const SPAN = "span";

export const ATTENTION = "attention";
export const STRESS = "stress";
export const STRONG = "strong";
export const IDIOMATIC = "idiomatic";

export const INTERNAL_LINK = "link-internal";
export const EXTERNAL_LINK = "link-external";

export const ORDERED_LIST = "ordered-list";
export const UNORDERED_LIST = "unordered-list";
export const LIST_ITEM = "list-item";

export const SUBTITLE = "subtitle";

export const semanticElements = [ATTENTION, STRESS, STRONG, IDIOMATIC];

export const listElements = [ORDERED_LIST, UNORDERED_LIST];

export const inlineElements = [
  ...semanticElements,
  INTERNAL_LINK,
  EXTERNAL_LINK,
];
