import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { LoadedImages } from "~/features/interface/ImageManager/ImageManager";
import styles from "./UploadingImagesList.module.scss";
import { getCenteredCrop, standardCrops } from "../../ImageManagerUtils";

type OwnProps = {
  loadedFiles: LoadedImages;
  selectedFile: string | undefined;
  setSelectedFile: (index: string) => void;
  clearLoadedFile: (filename: string) => void;
  saveImage: (index: string) => void;
  setLoadedFiles: (loadedImages: LoadedImages) => void;
};

type Props = OwnProps;

const UploadingImagesList = ({
  loadedFiles,
  setSelectedFile,
  clearLoadedFile,
  saveImage,
  // selectedFile,
  setLoadedFiles,
}: Props) => {
  return (
    <>
      {Object.entries(loadedFiles).map(([filename, loadedFile]) => {
        const { reader, file, originalHeight, originalWidth } = loadedFile;

        return typeof reader.result === "string" ? (
          <Card key={filename} className={styles.ctn} variant="outlined">
            <CardContent>
              <Typography color="text.secondary">
                {originalWidth}x{originalHeight} px
              </Typography>
              <Typography sx={{ fontSize: 14 }} color="text.secondary">
                {file.name}
              </Typography>
            </CardContent>
            <CardActionArea
              onClick={() => {
                setSelectedFile(filename);
              }}
            >
              <CardMedia
                className={styles.image}
                component="img"
                alt={String(filename)}
                image={reader.result}
                title={String(filename)}
              />
            </CardActionArea>
            <CardActions className={styles.actions}>
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  // alert(
                  //   "@todo - não implementado, image nao vai aparecer depois"
                  // );
                  saveImage(filename);
                }}
              >
                <SaveIcon />
              </IconButton>
              {
                // typeof selectedFile !== "undefined" &&
                // selectedFile === filename &&
                Object.entries(standardCrops).length !==
                Object.entries(loadedFiles[filename].crops).length ? (
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => {
                      const { reader, crops } = loadedFiles[filename];

                      if (typeof reader.result === "string") {
                        const img = new Image();
                        img.src = reader.result;

                        img.onload = () => {
                          Object.entries(standardCrops)
                            .map(([cropName]) => cropName)
                            .filter(
                              (cropName) =>
                                typeof crops[cropName] === "undefined"
                            )
                            .forEach((cropName) => {
                              setLoadedFiles({
                                ...loadedFiles,
                                [filename]: {
                                  ...loadedFiles[filename],
                                  crops: {
                                    ...crops,
                                    [cropName]: getCenteredCrop(
                                      img,
                                      standardCrops[cropName]
                                    ),
                                  },
                                },
                              });
                            });
                        };
                      }
                    }}
                  >
                    <AddBoxIcon />
                  </IconButton>
                ) : null
              }
              <IconButton
                size="small"
                color="primary"
                onClick={() => clearLoadedFile(filename)}
              >
                <DeleteForeverIcon />
              </IconButton>
            </CardActions>
          </Card>
        ) : null;
      })}
    </>
  );
};

export default UploadingImagesList;
