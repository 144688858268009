import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { DistributiveOmit } from "@mui/types";
import { Link, LinkProps } from "react-router-dom";
import styles from "./ListItemLink.module.css";
import { CSSProperties, ReactElement, forwardRef, useMemo } from "react";
// import Typography from '@mui/material/Typography';

interface ListItemLInkProps {
  avatar?: ReactElement;
  secondaryAction?: ReactElement[];
  primary: string;
  primaryTypographyProps?: object;
  secondary?: string;
  secondaryTypographyProps?: object;
  to: string;
  style?: CSSProperties;
  itemId?: string;
  selectedItemId?: string;
  height?: number;
}

const ListItemLink = (props: ListItemLInkProps) => {
  const {
    avatar,
    primary,
    primaryTypographyProps,
    secondary,
    secondaryTypographyProps,
    to,
    style,
    secondaryAction,
    selectedItemId,
    itemId,
    height = "auto",
  } = props;

  const CustomLink = useMemo(
    () =>
      forwardRef<any, DistributiveOmit<LinkProps, "to">>((linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to]
  );

  return (
    <ListItem
      sx={{ height }}
      button
      component={CustomLink}
      ContainerComponent="div"
      ContainerProps={{ style }}
      selected={selectedItemId === itemId}
      className={
        typeof secondaryAction !== "undefined"
          ? styles[`listItem${secondaryAction.length}`]
          : styles.listItem0
      }
    >
      {avatar ? <ListItemAvatar>{avatar}</ListItemAvatar> : null}
      <ListItemText
        primary={primary}
        primaryTypographyProps={primaryTypographyProps}
        secondary={secondary}
        secondaryTypographyProps={secondaryTypographyProps}
      />
      {secondaryAction ? (
        <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );
};

export default ListItemLink;
