import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import { connect, ConnectedProps } from "react-redux";
// import TextField from '@mui/material/TextField';
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormatClearIcon from "@mui/icons-material/FormatClear";
import Icon from "@mui/material/Icon";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface QuantityFieldType {
  column: string;
  type: string;
  placeholder: string;
  rules?: string;
  always?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: QuantityFieldType;
  // draft: any;
  onChange: (column: string, value: number | null, locale?: string) => void;
  locale: string;
};

type Props = PropsFromRedux & OwnProps;

const convertToNumber = (input: string): number | null => {
  // let possibleNumber;
  // const lastDigit = input[input.length - 1];
  // console.log({ input, lastDigit });

  // if (lastDigit === ".") {
  //   possibleNumber = Number(
  //     input
  //       .split(".")
  //       .filter((char: string) => /[1-9|\\.]/.test(char))
  //       .join(".") + "0"
  //   );
  // } else {
  //   possibleNumber = Number(
  //     input
  //       .split("")
  //       .filter((char: string) => /[1-9|\\.]/.test(char))
  //       .join("")
  //   );
  // }

  // console.log(possibleNumber);

  // console.log("called");
  // console.log(input);

  // console.log(input.replace("e", ""));

  const possibleNumber = Number(
    input
      .split("")
      .filter((char: string) => /[0-9|\\.]/.test(char))
      .join("")
  );

  return input !== "" && !isNaN(possibleNumber) ? possibleNumber : null;
};

const QuantityFieldRaw = ({ field, draftValue, onChange, locale }: Props) => {
  return (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel htmlFor={`field-${field.column}-${field.type}`}>
        {field.placeholder}
      </InputLabel>
      <OutlinedInput
        id={`field-${field.column}-${field.type}`}
        value={
          typeof draftValue !== "undefined" && draftValue !== null
            ? draftValue
            : ""
        }
        onChange={(event) => {
          onChange(field.column, convertToNumber(event.target.value), locale);
        }}
        type="number"
        endAdornment={[
          <InputAdornment position="end" key="add-1-2">
            <IconButton
              aria-label="add 1/2"
              onClick={() => {
                onChange(
                  field.column,
                  draftValue ? draftValue + 0.5 : 0.5,
                  locale
                );
              }}
              size="small"
              edge="end"
            >
              <Icon fontSize="inherit">½</Icon>
            </IconButton>
          </InputAdornment>,

          <InputAdornment position="end" key="add-1-4">
            <IconButton
              aria-label="add 1/4"
              onClick={() => {
                onChange(
                  field.column,
                  draftValue ? draftValue + 0.25 : 0.25,
                  locale
                );
              }}
              size="small"
              edge="end"
            >
              <Icon fontSize="inherit">¼</Icon>
            </IconButton>
          </InputAdornment>,
          <InputAdornment position="end" key="add-1-3">
            <IconButton
              aria-label="add 1/3"
              onClick={() => {
                onChange(
                  field.column,
                  draftValue ? draftValue + 0.33 : 0.33,
                  locale
                );
              }}
              size="small"
              edge="end"
            >
              <Icon fontSize="inherit">⅓</Icon>
            </IconButton>
          </InputAdornment>,
          <InputAdornment position="end" key="add-3-4">
            <IconButton
              aria-label="add 3/4"
              onClick={() => {
                onChange(
                  field.column,
                  draftValue ? draftValue + 0.75 : 0.75,
                  locale
                );
              }}
              size="small"
              edge="end"
            >
              <Icon fontSize="inherit">¾</Icon>
            </IconButton>
          </InputAdornment>,
          <InputAdornment position="end" key="add-2-3">
            <IconButton
              aria-label="add 2/3"
              onClick={() => {
                onChange(
                  field.column,
                  draftValue ? draftValue + 0.67 : 0.67,
                  locale
                );
              }}
              size="small"
              edge="end"
            >
              <Icon fontSize="inherit">⅔</Icon>
            </IconButton>
          </InputAdornment>,
          <InputAdornment position="end" key="add-1-8">
            <IconButton
              aria-label="add 1/8"
              onClick={() => {
                onChange(
                  field.column,
                  draftValue ? draftValue + 0.125 : 0.125,
                  locale
                );
              }}
              size="small"
              edge="end"
            >
              <Icon fontSize="inherit">⅛</Icon>
            </IconButton>
          </InputAdornment>,
          <InputAdornment position="end" key="add-null">
            <IconButton
              aria-label="clears field"
              onClick={() => {
                onChange(field.column, null, locale);
              }}
              size="small"
              edge="end"
            >
              <FormatClearIcon fontSize="inherit" />
            </IconButton>
          </InputAdornment>,
        ]}
        label={field.placeholder}
      />
    </FormControl>
  );
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId, field }: OwnProps
) => ({
  draftValue: getDraftColumn(state, entityModel, entityId, field.column),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const QuantityField = connector(QuantityFieldRaw);
export default QuantityField;
