import { connect, ConnectedProps } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { hideImageManager } from "~/features/interface/ImageManager/imageManagerSlice";
import styles from "./ImageManagerHeaderBar.module.scss";

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const ImageManagerHeaderBarRaw = ({ hideImageManager }: Props) => {
  return (
    <div className={styles.ctn}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => hideImageManager()}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Gerenciador de imagens</Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = {
  hideImageManager,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const ImageManagerHeaderBar = connector(ImageManagerHeaderBarRaw);
export default ImageManagerHeaderBar;
