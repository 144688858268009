import ModelsList from "~/features/layout/ModelsList";
import Drawer from "@mui/material/Drawer";
import { dismissAppPanel } from "./appPanelSlice";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getPanelStatus } from "../interfaceSlice";
import { useEffect } from "react";

export const AppPanel = () => {
  const dispatch = useDispatch();
  const isPanelOpen = useSelector(getPanelStatus);

  const location = useLocation();
  useEffect(() => {
    dispatch(dismissAppPanel());
  }, [dispatch, location]);

  return (
    <Drawer
      anchor="right"
      open={isPanelOpen}
      onClose={() => {
        dispatch(dismissAppPanel());
      }}
    >
      <ModelsList />
    </Drawer>
  );
};

export default AppPanel;
