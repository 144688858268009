import { FormEvent, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import { ListedImage, ImageCrops } from "~/types";
import { fetchList } from "~/features/data/Lists/listsSlice";
import { setColumnValue } from "~/features/drafts/draftsSlice";
import {
  hideImageManager,
  pushImages,
} from "~/features/interface/ImageManager/imageManagerSlice";
import {
  isImageManagerOpen,
  getDraftDetails,
} from "~/features/interface/interfaceSlice";
import { selectEntitiesFromList } from "~/features/data/dataSlice";
import styles from "./ImageManager.module.css";
import Dialog from "@mui/material/Dialog";
import matchSorter from "match-sorter";
import "react-image-crop/dist/ReactCrop.css";
import SelectedImageDetails from "~/features/interface/ImageManager/SelectedImageDetails/SelectedImageDetails";
import ImageManagerHeaderBar from "./ImageManagerHeaderBar/ImageManagerHeaderBar";
import SelectedFileCrops from "./SelectedFileCrops/SelectedFileCrops";
import {
  getPrefix,
  getSufix,
  mapPrefixToModel,
  splitSearchString,
} from "~/shared/SearchTools";
import ImageUploaderSidebar from "./ImageUploaderSidebar/ImageUploaderSidebar";
import ImageManagerSidebar from "./ImageManagerSidebar/ImageManagerSidebar";
import { getCenteredCrop, standardCrops } from "./ImageManagerUtils";

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  model: string;
};

type Props = OwnProps & PropsFromRedux;

export interface LoadedImage {
  reader: FileReader;
  crops: ImageCrops;
  file: File;
  originalWidth: number;
  originalHeight: number;
}

export interface LoadedImages {
  [filename: string]: LoadedImage;
}

const getResults = (options: ListedImage[], searchString: string) => {
  const terms = searchString.split(" ");

  return terms.reduceRight((list, term) => {
    return matchSorter(list, term, {
      keys: ["naming_titles", "url", "filename"],
    });
  }, options);
};

const filterOptions = (options: ListedImage[], searchString: string) => {
  const model = mapPrefixToModel(getPrefix(splitSearchString(searchString)));

  return model !== null
    ? getResults(
        options.filter((option: ListedImage) =>
          option.naming_types.includes(model)
        ),
        getSufix(splitSearchString(searchString))
      )
    : getResults(options, searchString);
};

const getCrops = (img: HTMLImageElement) => {
  const crops: ImageCrops = {};

  for (const cropTitle in standardCrops) {
    crops[cropTitle] = getCenteredCrop(img, standardCrops[cropTitle]);
  }

  return crops;
};

// export const getCenteredCrop = (
//   img: HTMLImageElement,
//   aspect: number
// ): Crop => {
//   const imageAspect = img.width / img.height;
//   const width = imageAspect < aspect ? 100 : (aspect / imageAspect) * 100;
//   const height = imageAspect < aspect ? (imageAspect / aspect) * 100 : 100;

//   return {
//     unit: "%",
//     width,
//     height,
//     x: (100 - width) / 2,
//     y: (100 - height) / 2,
//     aspect,
//   };
// };

// export const getFreeCrop = (aspect: number | undefined): Crop => ({
//   aspect: aspect,
//   height: 0,
//   unit: "%",
//   width: 0,
//   x: 0,
//   y: 0,
// });

// export const getBadgeColor = (status: string) => {
//   switch (status) {
//     case "1":
//       return "success";

//     case "0":
//       return "error";

//     default:
//       return "info";
//   }
// };

const ImageManagerRaw = ({
  model,
  fetchList,
  hideImageManager,
  list,
  isImageManagerOpen,
  pushImages,
  setColumnValue,
  draftDetails,
}: Props) => {
  const [searchString, setSearchString] = useState("");
  const [selectedImage, setSelectedImage] = useState<undefined | string>();
  const [loadedFiles, setLoadedFiles] = useState<LoadedImages>({});
  const [selectedFile, setSelectedFile] = useState<undefined | string>();

  let sortedList: ListedImage[];

  if (searchString !== "") {
    sortedList = filterOptions(list, searchString);
  } else {
    sortedList = list;
  }

  useEffect(() => {
    fetchList({ model });
  }, [model, fetchList]);

  const clearLoadedFiles = () => {
    setSelectedFile(undefined);
    setLoadedFiles({});
  };

  const saveImages = () => {
    pushImages(loadedFiles, clearLoadedFile);
  };

  const saveImage = (filename: string) => {
    pushImages({ [filename]: loadedFiles[filename] }, clearLoadedFile);
  };

  const clearLoadedFile = (filename: string) => {
    if (filename === selectedFile) {
      setSelectedFile(undefined);
    }

    setLoadedFiles((prevLoadedFiles) => {
      const { [filename]: loadedFile, ...updatedLoadedFiles } = prevLoadedFiles;

      return updatedLoadedFiles;
    });
  };

  const setDraft = (image: ListedImage) => {
    const { model, id, column, locale } = draftDetails;
    if (typeof model !== "undefined" && typeof column !== "undefined") {
      setColumnValue({
        model,
        id,
        column,
        value: image.id,
        locale,
      });

      hideImageManager();
    }
  };

  const onSelectFile = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      for (let index = 0; index < target.files.length; index++) {
        const file = target.files[index];
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          const image = new Image();
          if (typeof reader.result === "string") {
            image.src = reader.result;

            image.onload = () => {
              if (image.width < 2000) {
                alert(
                  `${file.name} tem menos de 2000px de largura, menos que o recomendado.`
                );
              }

              setLoadedFiles((prevLoadedFiles) => {
                return {
                  ...prevLoadedFiles,
                  [file.name]: {
                    crops: getCrops(image),
                    reader,
                    file,
                    originalWidth: image.width,
                    originalHeight: image.height,
                  },
                };
              });
            };
          }
        });
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <Dialog fullScreen open={isImageManagerOpen}>
      <ImageManagerHeaderBar />

      <div className={styles.ctn}>
        <div className={styles.ctnSidebar}>
          {Object.keys(loadedFiles).length ? (
            <ImageUploaderSidebar
              clearLoadedFiles={clearLoadedFiles}
              saveImages={saveImages}
              loadedFiles={loadedFiles}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              setLoadedFiles={setLoadedFiles}
              clearLoadedFile={clearLoadedFile}
              saveImage={saveImage}
            />
          ) : (
            <ImageManagerSidebar
              searchString={searchString}
              setSearchString={setSearchString}
              onSelectFile={onSelectFile}
              imagesList={sortedList}
              setSelectedImage={setSelectedImage}
              setDraft={setDraft}
            />
          )}
        </div>

        <div className={styles.ctnMain}>
          <div className={styles.grid}>
            {Object.keys(loadedFiles).length &&
            typeof selectedFile !== "undefined" ? (
              <SelectedFileCrops
                loadedFiles={loadedFiles}
                setLoadedFiles={setLoadedFiles}
                selectedFile={selectedFile}
              />
            ) : !Object.keys(loadedFiles).length &&
              typeof selectedImage !== "undefined" ? (
              <SelectedImageDetails
                model={"imageCrops"}
                parentModel={model}
                parentEntityId={selectedImage}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state: RootState, { model }: OwnProps) => {
  return {
    list: selectEntitiesFromList(state, { model }) as ListedImage[],
    isImageManagerOpen: isImageManagerOpen(state),
    draftDetails: getDraftDetails(state),
  };
};

const mapDispatchToProps = {
  fetchList,
  hideImageManager,
  pushImages,
  setColumnValue,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const ImageManager = connector(ImageManagerRaw);
export default ImageManager;
