import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import { connect, ConnectedProps } from "react-redux";
import { format, isValid, parseISO } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface DatetimeFieldType {
  column: string;
  type: string;
  placeholder: string;
  rules?: string;
  always?: boolean;
  default?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: DatetimeFieldType;
  onChange: (column: string, value: string | null, locale?: string) => void;
  locale: string;
};

type Props = PropsFromRedux & OwnProps;

const createDateFromIsoDate = (datetime: string): Date => {
  //parse with local timezone (this is done by not specifying the 'Z' at the end)
  return parseISO(datetime.split(" ")[0] + "T" + datetime.split(" ")[1]);
};

const getFormatedDate = (date: Date): string => {
  //remove timezone information from data (which was generated with the computers local timezone)
  return format(date, "yyyy-MM-dd HH:mm:ss");
};

const DatetimeFieldRaw = ({ field, draftValue, onChange, locale }: Props) => {
  return (
    // <div>datetimefield</div>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        label={field.placeholder}
        value={
          typeof draftValue !== "undefined" && draftValue !== null
            ? createDateFromIsoDate(draftValue)
            : null
        }
        onChange={(date) => {
          // if (isValid(date) && date !== null) {
          //   console.log(getFormatedDate(date));
          // }
          onChange(
            field.column,
            date !== null
              ? isValid(date)
                ? getFormatedDate(date)
                : null
              : // : typeof value !== "undefined"
                // ? value
                null,
            locale
          );
        }}
        // renderInput={(params: any) => <TextField {...params} fullWidth />}
        // clearable={true}
        // showTodayButton
        ampm={false}
        format="dd/MM/yyyy HH:mm"
      />
    </LocalizationProvider>
    // <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //   <KeyboardDateTimePicker
    //     margin="normal"
    //     ampm={false}
    //     label={field.placeholder}
    //     format="dd/MM/yyyy HH:mm"
    //     value={
    //       typeof draftValue !== 'undefined' && draftValue !== null
    //         ? createDateFromIsoDate(draftValue)
    //         : null
    //     }
    //     onChange={(datetime, value) => {
    //       onChange(
    //         field.column,
    //         datetime !== null && typeof value !== 'undefined'
    //           ? isValid(datetime)
    //             ? getFormatedDate(datetime)
    //             : value
    //           : null,
    //         locale
    //       );
    //     }}
    //     clearable={true}
    //     showTodayButton
    //     fullWidth
    //   />
    // </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId, field }: OwnProps
) => ({
  draftValue: getDraftColumn(state, entityModel, entityId, field.column),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const DatetimeField = connector(DatetimeFieldRaw);
export default DatetimeField;
