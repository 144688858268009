import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LoaderState {
  loaderQueue: string[];
  isLoaderOpen: boolean;
}

const initialState: LoaderState = {
  loaderQueue: [],
  isLoaderOpen: false,
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    showLoader: (state, action: PayloadAction<{ queueType: string }>) => {
      const { queueType } = action.payload;
      state.loaderQueue.push(queueType);

      state.isLoaderOpen = true;
    },
    hideLoader: (state, action: PayloadAction<{ queueType: string }>) => {
      const { queueType } = action.payload;

      if (queueType !== 'all') {
        const index = state.loaderQueue.indexOf(queueType);

        if (index !== -1) {
          state.loaderQueue.splice(index, 1);
        }
      } else {
        state.loaderQueue = [];
      }

      if (!state.loaderQueue.length) {
        state.isLoaderOpen = false;
      }
    },
  },
});

export const isLoaderOpen = (state: LoaderState) => state.isLoaderOpen;

export const { showLoader, hideLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
