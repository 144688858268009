import { RootState } from "~/app/store";
import { combineReducers } from "@reduxjs/toolkit";
import errorDialogReducer, * as fromErrorDialog from "./ErrorDialog/errorDialogSlice";
import dependencyDialogReducer, * as fromDependencyDialog from "./DependencyDialog/dependencyDialogSlice";
import quickCreateDialogReducer, * as fromQuickCreateDialog from "./QuickCreateDialog/quickCreateDialogSlice";
import snackbarReducer, * as fromSnackbar from "./Snackbar/snackbarSlice";
import appPanelReducer, * as fromAppPanel from "./AppPanel/appPanelSlice";
import loaderReducer, * as fromLoader from "./Loader/loaderSlice";
import imageManagerReducer, * as fromImageManager from "./ImageManager/imageManagerSlice";
import mergedContentManagerReducer, * as fromMergedContentManager from "./MergedContentManager/mergedContentManagerSlice";
import copyReducer, * as fromCopy from "./Copy/copySlice";

// interface InterfaceState {

// }

// const initialState: InterfaceState = {

// };

// export const interfaceSlice = createSlice({
//   name: 'interface',
//   initialState,
//   reducers: {
//     showLoader: (state, action: PayloadAction<{ queueType: string }>) => {
//       const { queueType } = action.payload;
//       state.loaderQueue.push(queueType);

//       state.isLoaderOpen = true;
//     },
//     hideLoader: (state, action: PayloadAction<{ queueType: string }>) => {
//       const { queueType } = action.payload;

//       if (queueType !== 'all') {
//         const index = state.loaderQueue.indexOf(queueType);

//         if (index !== -1) {
//           state.loaderQueue.splice(index, 1);
//         }
//       } else {
//         state.loaderQueue = [];
//       }

//       if (!state.loaderQueue.length) {
//         state.isLoaderOpen = false;
//       }
//     },
//   },
// });

export const getCopyDestinationEntity = (state: RootState) =>
  fromCopy.getCopyDestinationEntity(state.interface.copy);

export const isCopyDestinationSet = (state: RootState) =>
  fromCopy.isCopyDestinationSet(state.interface.copy);

export const getErrorDialogMessage = (state: RootState) =>
  fromErrorDialog.getErrorDialogMessage(state.interface.errorDialog);
export const isErrorDialogOpen = (state: RootState) =>
  fromErrorDialog.isErrorDialogOpen(state.interface.errorDialog);

export const getDependencyDialogMessage = (state: RootState) =>
  fromDependencyDialog.getDependencyDialogMessage(
    state.interface.dependencyDialog
  );
export const getDependencyDialogEntity = (state: RootState) =>
  fromDependencyDialog.getDependencyDialogEntity(
    state.interface.dependencyDialog
  );

export const isDependencyDialogOpen = (state: RootState) =>
  fromDependencyDialog.isDependencyDialogOpen(state.interface.dependencyDialog);

export const isDependencyDialogMinimized = (state: RootState) =>
  fromDependencyDialog.isDependencyDialogMinimized(
    state.interface.dependencyDialog
  );

export const getQuickCreateDialogModel = (state: RootState) =>
  fromQuickCreateDialog.getQuickCreateDialogModel(
    state.interface.quickCreateDialog
  );

export const isQuickCreateDialogOpen = (state: RootState) =>
  fromQuickCreateDialog.isQuickCreateDialogOpen(
    state.interface.quickCreateDialog
  );

export const getImageAssociation = (state: RootState) =>
  fromImageManager.getImageAssociation(state.interface.imageManager);

export const isImageManagerOpen = (state: RootState) =>
  fromImageManager.isImageManagerOpen(state.interface.imageManager);

export const getDraftDetails = (state: RootState) =>
  fromImageManager.getDraftDetails(state.interface.imageManager);

export const isMergedContentManagerOpen = (state: RootState, model: string) =>
  fromMergedContentManager.isMergedContentManagerOpen(
    state.interface.mergedContentManager,
    model
  );

export const getPanelStatus = (state: RootState) =>
  fromAppPanel.getPanelStatus(state.interface.appPanel);

export const isLoaderOpen = (state: RootState) =>
  fromLoader.isLoaderOpen(state.interface.loader);

export const isSnackbarOpen = (state: RootState): boolean =>
  fromSnackbar.isSnackbarOpen(state.interface.snackbar);

export const getSnackbarMessage = (state: RootState): string =>
  fromSnackbar.getSnackbarMessage(state.interface.snackbar);

export const getSnackbarType = (state: RootState) =>
  fromSnackbar.getSnackbarType(state.interface.snackbar);

const interfaceSliceReducer = combineReducers({
  loader: loaderReducer,
  appPanel: appPanelReducer,
  errorDialog: errorDialogReducer,
  dependencyDialog: dependencyDialogReducer,
  quickCreateDialog: quickCreateDialogReducer,
  snackbar: snackbarReducer,
  imageManager: imageManagerReducer,
  mergedContentManager: mergedContentManagerReducer,
  copy: copyReducer,
});

export default interfaceSliceReducer;
