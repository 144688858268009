import DisplayPageBanner from "~/views/shared/DisplayPageBanner/DisplayPageBanner";
import { ShowcaseType, SubhomeType } from "~/types/views";
import Block from "~/views/shared/Block/Block";

type Props = {
  entityModel: string;
  entityId: string;
  entityView: SubhomeType | ShowcaseType;
};

const SubhomeAndShowcaseView = ({
  entityView,
  entityModel,
  entityId,
}: Props) => {
  return (
    <>
      <DisplayPageBanner
        display={entityView}
        entityModel={entityModel}
        entityId={entityId}
      />

      {entityView.blocks.map((block, blockIndex) => {
        return (
          <Block
            entityModel={entityModel}
            entityId={entityId}
            block={block}
            key={blockIndex}
          />
        );
      })}
    </>
  );
};

export default SubhomeAndShowcaseView;
