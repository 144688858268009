import { useEffect, useState, useMemo, useCallback, ChangeEvent } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  // fetchList,
  // fetchEntityPivot,
  getPivots,
  // pushEntityPivots,
  // makeEntitiesListSelector,
  selectEntitiesFromList,
  // selectEntitiesList,
} from "~/features/data/dataSlice";
import {
  fetchEntityPivot,
  pushEntityPivots,
} from "~/features/data/pivotsSlice";
import { fetchList } from "~/features/data/Lists/listsSlice";
import { RootState } from "~/app/store";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import { Pivot } from "~/types";

type PropsFromRedux = ConnectedProps<typeof connector>;
export interface DependantCheckboxFieldType {
  column: string;
  type: string;
  placeholder: string;
  dependency: string;
  rules?: string;
  always?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: DependantCheckboxFieldType;
  onChange: (column: string, value: string | null, locale?: string) => void;
  locale: string;
};

type Props = PropsFromRedux & OwnProps;

const assemblePivots = (pivots: string[]): Pivot[] =>
  pivots.map((id: string) => ({
    id,
  }));

const DependantCheckboxFieldRaw = ({
  field,
  fetchList,
  fetchEntityPivot,
  pushEntityPivots,
  entityModel,
  entityId,
  list,
  pivots,
}: Props) => {
  const [selection, setSelection] = useState<string[]>([...pivots]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked && selection.indexOf(event.target.name) === -1) {
        setSelection(selection.concat([event.target.name]));
      } else {
        if (
          !event.target.checked &&
          selection.indexOf(event.target.name) !== -1
        ) {
          setSelection(
            selection.filter((option) => option !== event.target.name)
          );
        }
      }
    },
    [selection]
  );

  const options = useMemo(
    () =>
      list.map((item: any) => (
        <FormControlLabel
          key={item.id}
          value={String(item.id)}
          control={
            <Checkbox
              name={String(item.id)}
              checked={selection.indexOf(String(item.id)) !== -1 ? true : false}
              onChange={handleChange}
            />
          }
          label={item.px_title}
        />
      )),
    [list, selection, handleChange]
  );

  useEffect(() => {
    setSelection([...pivots]);
  }, [pivots]);

  useEffect(() => {
    if (typeof entityId !== "undefined") {
      fetchEntityPivot(
        { model: entityModel, relatedModel: field.dependency },
        entityId
      );
    }
  }, [field.dependency, fetchEntityPivot, entityModel, entityId]);

  useEffect(() => {
    fetchList({ model: field.dependency });
  }, [field.dependency, fetchList]);

  const handleSave = () => {
    if (typeof entityId !== "undefined") {
      pushEntityPivots(
        { model: entityModel, relatedModel: field.dependency },
        entityId,
        assemblePivots(selection)
      );
    }
  };

  const handleReset = () => {
    if (typeof entityId !== "undefined") {
      fetchEntityPivot(
        { model: entityModel, relatedModel: field.dependency },
        entityId
      );
    }
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{field.placeholder}</FormLabel>
      <Button variant="contained" onClick={handleSave} size="small">
        Salvar
      </Button>
      <Button variant="contained" onClick={handleReset} size="small">
        Resetar
      </Button>
      <FormGroup aria-label={field.placeholder}>{options}</FormGroup>
    </FormControl>
  );
};

const makeMapStateToProps = () => {
  // const selectEntitiesList = makeEntitiesListSelector();
  const mapStateToProps = (
    state: RootState,
    { entityModel, entityId, field }: OwnProps
  ) => {
    return {
      list: selectEntitiesFromList(state, { model: field.dependency }),
      pivots:
        typeof entityId !== "undefined"
          ? getPivots(
              state,
              { model: entityModel, relatedModel: field.dependency },
              entityId
            )
          : [],
      //   draftValue: getDraftColumn(
      //     state,
      //     entityModel,
      //     entityId,
      //     field.column,
      //     locale
      //   ),
    };
  };
  return mapStateToProps;
};

// const mapStateToProps = (
//   state: RootState,
//   { entityModel, entityId, field }: OwnProps
// ) => ({
//   list: selectEntitiesList(state, { model: field.dependency }),
//   draftValue: getDraftColumn(
//     state,
//     entityModel,
//     entityId,
//     field.column,
//     locale
//   ),
// });

const mapDispatchToProps = {
  fetchList,
  fetchEntityPivot,
  pushEntityPivots,
};

const connector = connect(makeMapStateToProps, mapDispatchToProps);

const DependantCheckboxField = connector(DependantCheckboxFieldRaw);
export default DependantCheckboxField;
