import { combineReducers } from "@reduxjs/toolkit";
import layoutReducer from "~/features/layout/layoutSlice";
import localesReducer from "~/features/locales/localesSlice";
import dataReducer from "~/features/data/dataSlice";
import draftsReducer from "~/features/drafts/draftsSlice";
import interfaceReducer from "~/features/interface/interfaceSlice";
import serverReducer from "~/features/server/serverSlice";
// import confirmationDialogSliceReducer from '~/features/confirmationDialog/confirmationDialogSlice';

const rootReducer = combineReducers({
  layout: layoutReducer,
  locales: localesReducer,
  data: dataReducer,
  drafts: draftsReducer,
  interface: interfaceReducer,
  server: serverReducer,
  // confirmationDialog: confirmationDialogSliceReducer,
});

export default rootReducer;
