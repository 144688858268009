import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "~/app/store";
import { PivotListProps, Pivot } from "~/types";
import { getEntityPivots, setEntityPivots } from "~/api/entitiesAPI";
import { PuxadinhoError } from "~/shared/PuxadinhoError";
import { showSnackbar } from "~/features/interface/Snackbar/snackbarSlice";
import { showErrorDialog } from "~/features/interface/ErrorDialog/errorDialogSlice";

interface PivotsState {
  [key: string]: string[];
}

const initialState: PivotsState = {};

export const pivotsSlice = createSlice({
  name: "pivots",
  initialState,
  reducers: {
    fetchEntityPivotSuccess: (
      state,
      action: PayloadAction<{
        id: string;
        pivotList: PivotListProps;
        fetchTime: number;
        response: string[];
      }>
    ) => {
      const { id, pivotList, response } = action.payload;

      state[getPivotNamespace(pivotList, id)] = response.map(
        (id: number | string) => String(id)
      );
    },
  },
});

export const fetchEntityPivot =
  (pivotList: PivotListProps, id: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await getEntityPivots(pivotList, id);

      const { statusCode, fetchTime, result } = response;

      if (statusCode === 200 && typeof result !== "undefined") {
        dispatch(
          fetchEntityPivotSuccess({
            response: result,
            fetchTime,
            id: String(id),
            pivotList,
          })
        );
      }
    } catch (error) {
      if (error instanceof PuxadinhoError) {
        if (error.statusCode === 400 || error.statusCode === 500) {
          // dispatch(showErrorDialog(error.result ));
        }

        if (error.statusCode === 401 || error.statusCode === 419) {
          // dispatch(setLoggedStatus(false));
        }
      }

      console.log(error);
      // console.log(`fetch entity error ${err}`);
      // dispatch(getCommentsFailure(err));
    }
  };

export const pushEntityPivots =
  (pivotList: PivotListProps, id: string, pivots: Pivot[]): AppThunk =>
  async (dispatch) => {
    try {
      const response = await setEntityPivots(pivotList, id, pivots);

      const { statusCode, fetchTime } = response;

      if (statusCode === 200) {
        dispatch(
          fetchEntityPivotSuccess({
            response: pivots.map((pivot) => pivot.id),
            fetchTime,
            id: String(id),
            pivotList,
          })
        );

        dispatch(
          showSnackbar({
            message: "Salvo com sucesso",
            type: "success",
          })
        );
      }
    } catch (error) {
      if (error instanceof PuxadinhoError) {
        if (error.statusCode === 400 || error.statusCode === 500) {
          dispatch(showErrorDialog(error.result));
        }

        if (error.statusCode === 401 || error.statusCode === 419) {
          // dispatch(setLoggedStatus(false));
        }
      }

      console.log(error);
      // console.log(`push entity error ${err}`);
    }
  };

export const getPivots = (
  state: PivotsState,
  pivotList: PivotListProps,
  id: string
): string[] => {
  return typeof state[getPivotNamespace(pivotList, id)] !== "undefined"
    ? state[getPivotNamespace(pivotList, id)]
    : [];
};

const getPivotNamespace = (pivotList: PivotListProps, id: string) => {
  return `${pivotList.model}_${id}_${pivotList.relatedModel}`;
};

export const { fetchEntityPivotSuccess } = pivotsSlice.actions;
export default pivotsSlice.reducer;
