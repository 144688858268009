import { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import { getDraftColumn, setColumnValue } from "~/features/drafts/draftsSlice";
import {
  InternalLink,
  // NewBaseElement,
  PuxEditorHelper,
} from "../utils/PuxEditorHelper";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
// import { ReactEditor } from "slate-react";
import MultipleDependantSelectField from "~/fields/MultipleDependantSelectField";
import { BaseEditor } from "slate";

type PropsFromRedux = ConnectedProps<typeof connector>;

const entityModel = "sharedModel";
const entityId = undefined;
const fieldColumn = "entitiable";

const field = {
  column: fieldColumn,
  type: "multipleDependantSelect",
  placeholder: "Link interno",
  dependencies: [],
};

// type ActiveElement = {
//   new_tab: boolean;
//   model: string;
//   id: string;
// };

type OwnProps = {
  editor: BaseEditor;
  isInternalLinkDialogOpen: boolean;
  setIsInternalLinkDialogOpen: (state: boolean) => void;
  internalLinkDependencies: string[];
  activeElement: InternalLink | null;
};

type Props = PropsFromRedux & OwnProps;

const isEntitySelected = (
  model: string | null | undefined,
  id: string | null | undefined
) => {
  return (
    typeof model !== "undefined" &&
    model !== null &&
    typeof id !== "undefined" &&
    id !== null
  );
};

const InternalLinkDialogRaw = ({
  editor,
  // isInternalLinkDialogOpen,
  // setIsInternalLinkDialogOpen,
  internalLinkDependencies,
  id,
  model,
  onChange,
  activeElement,
}: Props) => {
  const [newTab, toggleNewTab] = useState<boolean>(false);

  useEffect(() => {
    if (
      activeElement !== null &&
      (activeElement.model !== model || activeElement.id !== id)
    ) {
      onChange(fieldColumn + "_type", activeElement.model, undefined);
      onChange(fieldColumn + "_id", activeElement.id, undefined);
    }
  }, [activeElement, model, id, onChange]);

  // const eraseDraft = () => {
  //   onChange(fieldColumn + "_type", undefined, undefined);
  //   onChange(fieldColumn + "_id", undefined, undefined);
  // };

  const handleConfirm = () => {
    PuxEditorHelper.setInternalLinkElement(editor, model, id, newTab);
  };

  return (
    <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
      {activeElement !== null ? (
        <>
          <MultipleDependantSelectField
            field={{ ...field, dependencies: internalLinkDependencies }}
            onChange={onChange}
            locale={"pt"}
            entityModel={entityModel}
            entityId={entityId}
            disabled
          />
          <Switch
            disabled
            size="small"
            checked={activeElement.new_tab}
            onChange={() => toggleNewTab(!newTab)}
          />
        </>
      ) : (
        <>
          <MultipleDependantSelectField
            field={{ ...field, dependencies: internalLinkDependencies }}
            onChange={onChange}
            locale={"pt"}
            entityModel={entityModel}
            entityId={entityId}
          />

          <Switch
            size="small"
            checked={newTab}
            onChange={() => toggleNewTab(!newTab)}
          />
          <Button
            onClick={handleConfirm}
            disabled={!isEntitySelected(model, id)}
          >
            Ok
          </Button>
        </>
      )}
    </div>
  );
};

const makeMapStateToProps = () => {
  // const selectEntitiesList = makeEntitiesListSelector();
  const mapStateToProps = (state: RootState) => {
    return {
      id: getDraftColumn(state, entityModel, entityId, fieldColumn + "_id"),
      model: getDraftColumn(
        state,
        entityModel,
        entityId,
        fieldColumn + "_type"
      ),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChange: (column: string, value: any, locale?: string): void => {
    dispatch(
      setColumnValue({
        model: entityModel,
        id: entityId,
        column,
        value,
        locale,
      })
    );
  },
});

const connector = connect(makeMapStateToProps, mapDispatchToProps);

const InternalLinkDialog = connector(InternalLinkDialogRaw);
export default InternalLinkDialog;
