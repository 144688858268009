import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import { BlockType } from "~/types/views";
import BlockContent from "../BlockContent/BlockContent";
import styles from "./Block.module.scss";
import Button from "@mui/material/Button";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import { Link } from "react-router-dom";
import { pushEntityToDestination } from "~/features/interface/Copy/copySlice";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { isCopyDestinationSet } from "~/features/interface/interfaceSlice";

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  block: BlockType;
  entityModel: string;
  entityId: string;
};

type Props = PropsFromRedux & OwnProps;

const getClassNameFromFormat = (format: string) => {
  switch (format) {
    case "columns-2":
      return "contentColumns2";
    case "columns-3":
      return "contentColumns3";
    case "columns-4":
      return "contentColumns4";
    default:
      return "contentColumns1";
  }
};

const BlockRaw = ({
  block,
  entityModel,
  entityId,
  isCopyDestinationSet,
  pushEntityToDestination,
}: Props) => {
  return (
    <div className={styles.ctn}>
      {isCopyDestinationSet ? (
        <Button
          variant="contained"
          aria-label="fingerprint"
          color="primary"
          sx={{ position: "absolute", top: 5, right: 5, zIndex: 2 }}
          size="small"
          onClick={() => {
            pushEntityToDestination(`${entityModel}Block`, block.id);
          }}
        >
          <AddShoppingCartOutlinedIcon fontSize="inherit" />
        </Button>
      ) : null}

      <Link to={`/${entityModel}/${entityId}/${entityModel}Block/${block.id}`}>
        <div className={styles.header}>
          <div className={styles.wrapper}>
            <h5>{block.title}</h5>
          </div>
          <p>{block.subtitle}</p>
        </div>
      </Link>

      <div className={styles[getClassNameFromFormat(block.format)]}>
        {block.blockContents.map((blockContent, blockContentIndex) => (
          <BlockContent
            key={blockContentIndex}
            blockContent={blockContent}
            blockId={block.id}
            entityModel={entityModel}
            entityId={entityId}
          />
        ))}
      </div>
      <hr />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isCopyDestinationSet: isCopyDestinationSet(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  pushEntityToDestination: (model: string, id: string) =>
    dispatch(pushEntityToDestination(model, id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const Block = connector(BlockRaw);

export default Block;
