import ImageManagerToolbar from "~/features/interface/ImageManager/ImageManagerSidebar/ImageManagerToolbar/ImageManagerToolbar";
import { ListedImage } from "~/types";
import VirtualizedImagesList from "./VirtualizedImagesList/VirtualizedImagesList";
import styles from "./ImageManagerSidebar.module.scss";
import { FormEvent } from "react";

type OwnProps = {
  searchString: string;
  setSearchString: (text: string) => void;
  onSelectFile: (e: FormEvent) => void;
  imagesList: ListedImage[];
  setSelectedImage: (id: string) => void;
  setDraft: (image: ListedImage) => void;
};

type Props = OwnProps;

const ImageManagerSidebar = ({
  searchString,
  setSearchString,
  onSelectFile,
  imagesList,
  setSelectedImage,
  setDraft,
}: Props) => {
  return (
    <>
      <div className={styles.ctnToolbar}>
        <ImageManagerToolbar
          searchString={searchString}
          setSearchString={setSearchString}
          onSelectFile={onSelectFile}
        />
      </div>
      <div className={styles.ctnList}>
        <VirtualizedImagesList
          imagesList={imagesList}
          setSelectedImage={setSelectedImage}
          setDraft={setDraft}
        />
      </div>
    </>
  );
};

export default ImageManagerSidebar;
