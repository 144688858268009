import { Crop } from "react-image-crop";

export const standardCrops: { [key: string]: number } = {
  bn: 1140 / 582,
  bk: 15 / 10,
  q: 1,
  // full: 4 / 3,
  // wide: 16 / 9,
};

export const getCenteredCrop = (
  img: HTMLImageElement,
  aspect: number
): Crop => {
  const imageAspect = img.width / img.height;
  const width = imageAspect < aspect ? 100 : (aspect / imageAspect) * 100;
  const height = imageAspect < aspect ? (imageAspect / aspect) * 100 : 100;

  return {
    unit: "%",
    width,
    height,
    x: (100 - width) / 2,
    y: (100 - height) / 2,
    aspect,
  };
};

export const getFreeCrop = (aspect: number | undefined): Crop => ({
  aspect: aspect,
  height: 0,
  unit: "%",
  width: 0,
  x: 0,
  y: 0,
});

export const getBadgeColor = (status: string) => {
  switch (status) {
    case "1":
      return "success";

    case "0":
      return "error";

    default:
      return "info";
  }
};
