import { useEffect } from "react";
import ListsWithRouter from "~/features/data/Lists/ListsWithRouter";
import EntityWithRouter from "~/features/data/Entity/EntityWithRouter";
import NavigatorWithRouter from "~/features/interface/NavigatorWithRouter";
import styles from "./App.module.css";
import { Switch, Route } from "react-router-dom";
import AppHeaderBar from "~/layout/AppHeaderBar";
import AppPanel from "~/features/interface/AppPanel/AppPanel";
import ErrorDialog from "~/features/interface/ErrorDialog/ErrorDialog";
import {
  fetchLayout,
  getModelsToPreload,
  isLayoutLoaded,
} from "~/features/layout/layoutSlice";
import { fetchList } from "~/features/data/Lists/listsSlice";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import AppSnackbar from "~/features/interface/Snackbar/AppSnackbar";
import ImageManager from "~/features/interface/ImageManager/ImageManager";
import LoginDialog from "~/layout/LoginDialog";
import Loader from "~/features/interface/Loader/Loader";
import DependencyDialog from "~/features/interface/DependencyDialog/DependencyDialog";
import { isDependencyDialogOpen } from "~/features/interface/interfaceSlice";
import QuickCreateDialog from "~/features/interface/QuickCreateDialog/QuickCreateDialog";
import EntityViewWithRouter from "~/features/data/EntityView/EntityViewWithRouter";
// import MergedContentManager from "~/features/interface/MergedContentManager/MergedContentManager";
// import SlatePlaygroundField from "tests/SlatePlaygroundField/SlatePlaygroundField";

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const AppRaw = ({
  fetchLayout,
  fetchList,
  isLayoutLoaded,
  modelsToPreload,
  isDependencyDialogOpen,
}: Props) => {
  useEffect(() => {
    fetchLayout();
  }, [fetchLayout]);

  useEffect(() => {
    modelsToPreload.forEach((model) => fetchList({ model }));
  }, [modelsToPreload, fetchList]);

  // return !isLayoutLoaded ? (
  //   <></>
  // ) : (
  //   <div className={styles.container}>
  //     <div className={styles.appBarContainer}>
  //       <AppHeaderBar />
  //     </div>
  //     <div className={styles.contentContainer}>
  //       <div className={styles.listsContainer}></div>
  //       <div className={styles.entityContainer}>
  //         <Fields
  //           entityModel={"recipeBlockComponent"}
  //           fields={["slate_paragraph"]}
  //         />
  //       </div>
  //     </div>
  //   </div>
  // );

  return !isLayoutLoaded ? (
    <>
      <Loader />
      <ErrorDialog />
      <LoginDialog />
    </>
  ) : (
    // <div className={styles.container}>
    //   <div className={styles.playground}>
    //     <SlatePlaygroundField />
    //   </div>
    // </div>
    <div className={styles.container}>
      <div className={styles.appBarContainer}>
        <AppHeaderBar />
      </div>
      <Loader />
      <AppPanel />
      <ErrorDialog />
      {isDependencyDialogOpen ? <DependencyDialog /> : null}
      <QuickCreateDialog />
      <AppSnackbar />
      <LoginDialog />
      <ImageManager model="image" />
      {/* <MergedContentManager model="mergedHighlight" /> */}
      {/* <MergedContentManager model="mergedBlock" /> */}

      <Switch>
        <Route path="/:activeModel/:id?">
          <div className={styles.navigatorContainer}>
            <NavigatorWithRouter
              parentModel={undefined}
              parentEntityId={undefined}
              baseUrl={""}
            />
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.listsContainer}>
              <ListsWithRouter
                parentModel={undefined}
                parentEntityId={undefined}
                baseUrl={""}
              />
            </div>
            <div className={styles.entityContainer}>
              <EntityWithRouter
                parentModel={undefined}
                parentEntityId={undefined}
                baseUrl={""}
              />
            </div>
            <div className={styles.entityViewContainer}>
              <EntityViewWithRouter
                parentModel={undefined}
                parentEntityId={undefined}
                baseUrl={""}
              />
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLayoutLoaded: isLayoutLoaded(state),
  modelsToPreload: getModelsToPreload(state),
  isDependencyDialogOpen: isDependencyDialogOpen(state),
});

const mapDispatchToProps = {
  fetchLayout,
  fetchList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const App = connector(AppRaw);
export default App;
