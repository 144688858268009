import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertProps } from "@mui/material/Alert";

interface SnackbarState {
  snackbarMessage: string;
  snackbarType: AlertProps["severity"];
  isSnackbarOpen: boolean;
}

const initialState: SnackbarState = {
  snackbarMessage: "",
  snackbarType: undefined,
  isSnackbarOpen: false,
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackbar: (
      state,
      action: PayloadAction<{ message: string; type: AlertProps["severity"] }>
    ) => {
      const { message, type } = action.payload;
      state.isSnackbarOpen = true;
      state.snackbarMessage = message;
      state.snackbarType = type;
    },
    hideSnackbar: (state) => {
      state.isSnackbarOpen = false;
      state.snackbarMessage = "";
      state.snackbarType = undefined;
    },
  },
});

export const isSnackbarOpen = (state: SnackbarState): boolean =>
  state.isSnackbarOpen;

export const getSnackbarMessage = (state: SnackbarState): string =>
  state.snackbarMessage;

export const getSnackbarType = (state: SnackbarState): AlertProps["severity"] =>
  state.snackbarType;

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
