import { PostViewType } from "~/types/views";
import RecipeBlock from "~/views/RecipeView/RecipeBlock/RecipeBlock";
import PostBlock from "./PostBlock/PostBlock";
import PostHeader from "./PostHeader/PostHeader";
import PostHeadersOverview from "./PostHeadersOverview/PostHeadersOverview";
import PostLegacyContent from "./PostLegacyContent/PostLegacyContent";
import PostRelationships from "./PostRelationships/PostRelationships";
import { Fragment } from "react";
import PostLinks from "./PostLinks/PostLinks";

type Props = {
  entityModel: string;
  entityId: string;
  entityView: PostViewType;
};

const PostView = ({ entityView, entityModel, entityId }: Props) => {
  return (
    <>
      <PostHeader post={entityView.post} />

      {entityView.post.useLegacyContentHtml ? (
        <Fragment>
          <PostLegacyContent html={entityView.post.legacyContentHtml} />
          {entityView.post.blocks.map((block, blockIndex) => {
            return block.blockProducts.length ? (
              <Fragment key={blockIndex + "-bp"}>
                <PostBlock
                  postBlock={block}
                  link={`/blog/${entityView.blog.id}/${entityModel}/${entityId}`}
                  entityModel={entityModel}
                />
              </Fragment>
            ) : null;
          })}
        </Fragment>
      ) : (
        <>
          {entityView.post.blocks.map((block, blockIndex) => {
            return (
              <Fragment key={blockIndex}>
                {block.type === "recipe" ? null : (
                  <PostHeadersOverview
                    postBlock={block}
                    title={entityView.post.title}
                  />
                )}
              </Fragment>
            );
          })}

          {entityView.post.blocks.map((block, blockIndex) => {
            return (
              <Fragment key={blockIndex}>
                {block.type === "recipe" ? (
                  <RecipeBlock
                    recipeBlock={block}
                    link={`/blog/${entityView.blog.id}/${entityModel}/${entityId}`}
                    entityModel={entityModel}
                  />
                ) : (
                  <PostBlock
                    postBlock={block}
                    link={`/blog/${entityView.blog.id}/${entityModel}/${entityId}`}
                    entityModel={entityModel}
                  />
                )}
              </Fragment>
            );
          })}
        </>
      )}

      <PostRelationships
        relationships={entityView.post.relationships}
        link={`/blog/${entityView.blog.id}/${entityModel}/${entityId}`}
      />

      <PostLinks
        postLinks={entityView.post.links}
        link={`/blog/${entityView.blog.id}/${entityModel}/${entityId}`}
      />
    </>
  );
};

export default PostView;
