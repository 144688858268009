import { useState } from "react";
import { useSlate } from "slate-react";
import { BaseSelection, Range } from "slate";
import styles from "./Toolbar.module.css";
import {
  ATTENTION,
  EXTERNAL_LINK,
  IDIOMATIC,
  INTERNAL_LINK,
  STRESS,
  STRONG,
} from "./utils/elementsLists";
import {
  ExternalLink,
  InternalLink,
  PuxEditorHelper,
} from "./utils/PuxEditorHelper";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ExternalLinkDialog from "./dialogs/ExternalLinkDialog";
import InternalLinkDialog from "./dialogs/InternalLinkDialog";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import AddLinkIcon from "@mui/icons-material/AddLink";
import TranslateIcon from "@mui/icons-material/Translate";
import CampaignIcon from "@mui/icons-material/Campaign";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import { SimpleSlateFieldType } from "~/fields/SimpleSlateField";

type Props = {
  field: SimpleSlateFieldType;
};

const Toolbar = ({ field }: Props) => {
  const editor = useSlate();
  const [isExternalLinkDialogOpen, setIsExternalLinkDialogOpen] =
    useState(false);
  const [isInternalLinkDialogOpen, setIsInternalLinkDialogOpen] =
    useState(false);

  const [rangeWhenClicked, setRangeWhenClicked] = useState<BaseSelection>(null);

  const { selection } = editor;

  // console.log({ rangeWhenClicked, selection });

  if (
    rangeWhenClicked !== null &&
    selection !== null &&
    !Range.equals(selection, rangeWhenClicked)
  ) {
    setIsExternalLinkDialogOpen(false);
    setIsInternalLinkDialogOpen(false);
    setRangeWhenClicked(null);
  }

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <ButtonGroup
          size="small"
          variant="outlined"
          aria-label="outlined button group"
          // fullWidth
          // orientation="vertical"
        >
          <Button
            onClick={() => {
              if (
                PuxEditorHelper.isInlineElementActive(editor, INTERNAL_LINK)
              ) {
                PuxEditorHelper.removeInternalLinkElement(editor);
              } else {
                setRangeWhenClicked(editor.selection);
                setIsInternalLinkDialogOpen(true);
                setIsExternalLinkDialogOpen(false);
              }
            }}
            color={
              PuxEditorHelper.isInlineElementActive(editor, INTERNAL_LINK)
                ? "secondary"
                : "primary"
            }
            disabled={
              PuxEditorHelper.isInlineElementActive(editor, EXTERNAL_LINK)
                ? true
                : false
            }
            sx={{ backgroundColor: "lightskyblue" }}
          >
            <AutoStoriesIcon />
          </Button>
          <Button
            onClick={() => {
              if (
                PuxEditorHelper.isInlineElementActive(editor, EXTERNAL_LINK)
              ) {
                PuxEditorHelper.removeExternalLinkElement(editor);
              } else {
                setRangeWhenClicked(editor.selection);
                setIsExternalLinkDialogOpen(true);
                setIsInternalLinkDialogOpen(false);
              }
            }}
            color={
              PuxEditorHelper.isInlineElementActive(editor, EXTERNAL_LINK)
                ? "secondary"
                : "primary"
            }
            disabled={
              PuxEditorHelper.isInlineElementActive(editor, INTERNAL_LINK)
                ? true
                : false
            }
            sx={{ backgroundColor: "lightskyblue" }}
            // size={"medium"}
          >
            <AddLinkIcon />
          </Button>
          <Button
            color={
              PuxEditorHelper.isInlineElementActive(editor, ATTENTION)
                ? "secondary"
                : "primary"
            }
            onClick={() => {
              PuxEditorHelper.toggleSemanticElement(editor, ATTENTION);
            }}
            aria-label={ATTENTION}
            size="small"
            sx={{ backgroundColor: "lightgreen" }}
          >
            <FormatBoldIcon />
          </Button>
          <Button
            color={
              PuxEditorHelper.isInlineElementActive(editor, STRONG)
                ? "secondary"
                : "primary"
            }
            onClick={() => {
              PuxEditorHelper.toggleSemanticElement(editor, STRONG);
            }}
            aria-label={STRONG}
            size="small"
            sx={{ backgroundColor: "lightgreen" }}
          >
            <ReportGmailerrorredIcon />
          </Button>
          <Button
            color={
              PuxEditorHelper.isInlineElementActive(editor, IDIOMATIC)
                ? "secondary"
                : "primary"
            }
            onClick={() => {
              PuxEditorHelper.toggleSemanticElement(editor, IDIOMATIC);
            }}
            aria-label={IDIOMATIC}
            size="small"
            sx={{ backgroundColor: "lightgreen" }}
          >
            <TranslateIcon />
          </Button>
          <Button
            color={
              PuxEditorHelper.isInlineElementActive(editor, STRESS)
                ? "secondary"
                : "primary"
            }
            onClick={() => {
              PuxEditorHelper.toggleSemanticElement(editor, STRESS);
            }}
            aria-label={STRESS}
            size="small"
            sx={{ backgroundColor: "lightgreen" }}
          >
            <CampaignIcon />
          </Button>
          <Button
            onClick={() => {
              PuxEditorHelper.moveFocusForward(editor);
            }}
          >
            <KeyboardTabIcon />
          </Button>
        </ButtonGroup>
      </div>
      <div className={styles.bottomContainer}>
        {/* {isInternalLinkDialogOpen ? ( */}

        {(PuxEditorHelper.isInlineElementActive(editor, INTERNAL_LINK) &&
          !PuxEditorHelper.isInlineElementActive(editor, EXTERNAL_LINK)) ||
        (isInternalLinkDialogOpen && !isExternalLinkDialogOpen) ? (
          <div>
            <InternalLinkDialog
              editor={editor}
              isInternalLinkDialogOpen={isInternalLinkDialogOpen}
              setIsInternalLinkDialogOpen={setIsInternalLinkDialogOpen}
              internalLinkDependencies={field.internalLinkDependencies}
              activeElement={
                PuxEditorHelper.getActiveInlineElement(
                  editor,
                  INTERNAL_LINK
                ) as InternalLink
              }
            />
          </div>
        ) : null}

        {/* ) : null} */}
        {/* {isExternalLinkDialogOpen ? ( */}

        {(PuxEditorHelper.isInlineElementActive(editor, EXTERNAL_LINK) &&
          !PuxEditorHelper.isInlineElementActive(editor, INTERNAL_LINK)) ||
        (isExternalLinkDialogOpen && !isInternalLinkDialogOpen) ? (
          <div>
            <ExternalLinkDialog
              editor={editor}
              isExternalLinkDialogOpen={isExternalLinkDialogOpen}
              setIsExternalLinkDialogOpen={setIsExternalLinkDialogOpen}
              activeElement={
                PuxEditorHelper.getActiveInlineElement(
                  editor,
                  EXTERNAL_LINK
                ) as ExternalLink
              }
            />
          </div>
        ) : null}

        {/* ) : null} */}
      </div>
    </div>
  );
};

export default Toolbar;
