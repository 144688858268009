import { AppThunk } from "./../../app/store";
import { RootState } from "~/app/store";
import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import * as schema from "~/features/layout/modelsSchema";
import { getLayout } from "~/api/layoutAPI";
import { setLoggedStatus } from "~/features/server/serverSlice";
import {
  Layout,
  Model,
  Relationships,
  NormalizedLayoutResponse,
} from "~/types";
import { PuxadinhoError } from "~/shared/PuxadinhoError";
import {
  hideLoader,
  showLoader,
} from "~/features/interface/Loader/loaderSlice";

// interface ModelsList {
//   entities: Models;
//   resultsList: string[];
// }

type Models = {
  [id: string]: Model;
};

interface LayoutState {
  menu: string[];
  models: Models;
  modelsList: string[];
  relationships: Relationships;
  modelsToPreload: string[];
}

// interface ModelsListReceived {
//   list: any;
// }

const initialState: LayoutState = {
  menu: [],
  models: {},
  modelsList: [],
  relationships: {},
  modelsToPreload: [],
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    fetchLayoutSuccess: (state, action: PayloadAction<Layout>) => {
      const { menu, models, relationships, modelsToPreload } = action.payload;

      const normalizedModels: NormalizedLayoutResponse<Model> = normalize(
        models,
        schema.arrayOfModels
      );

      state.menu = menu;
      state.models = normalizedModels.entities.models;
      state.modelsList = normalizedModels.result;
      state.relationships = relationships;
      state.modelsToPreload = modelsToPreload;

      // if (typeof state.data[model] === 'undefined') {
      //   state.data[model] = {};
      // }
    },
    // setModelsList: (state, action: PayloadAction<ModelsListReceived>) => {
    //   state.list = action.payload.list;
    // },
  },
});

export const getModel = (state: RootState, id: string): Model | undefined =>
  typeof state.layout.models[id] !== "undefined"
    ? state.layout.models[id]
    : undefined;
// export const getModelsList = (state: LayoutState) =>
//   Object.entries(state.list).map((value) => value[1]);

const selectState = (state: RootState): RootState => state;

export const selectMenuList = createSelector(
  [selectState],
  (state) =>
    state.layout.menu
      .filter((model: string) => typeof getModel(state, model) !== "undefined")
      .map((model) => getModel(state, model)) as Model[]
);

export const isLayoutLoaded = (state: RootState): boolean =>
  state.layout.menu.length > 0 ? true : false;

export const getModelComponents = (
  state: RootState,
  model: string | undefined
): Model[] =>
  typeof model !== "undefined" &&
  typeof state.layout.relationships[model] !== "undefined"
    ? (state.layout.relationships[model].components
        .filter(
          (relatedModel: string) =>
            typeof getModel(state, relatedModel) !== "undefined"
        )
        .map((relatedModel: string) =>
          getModel(state, relatedModel)
        ) as Model[])
    : [];

export const fetchLayout = (): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoader({ queueType: "fetchLayout" }));

    // const cachedResult = await get('layout');

    // if (typeof cachedResult !== 'undefined') {
    //   dispatch(
    //     fetchLayoutSuccess({
    //       response: cachedResult,
    //     })
    //   );
    // }

    const response = await getLayout();

    const { statusCode, result } = response;

    if (statusCode === 200) {
      dispatch(fetchLayoutSuccess(result));

      dispatch(hideLoader({ queueType: "fetchLayout" }));
    }

    // if (statusCode === 400) {
    // dispatch(showErrorDialog( result ));
    //   dispatch(hideLoader({ queueType: 'fetchLayout' }));
    // }

    // if (statusCode === 500) {
    //   dispatch(showErrorDialog( { server: ['Server error'] } }));
    //   dispatch(hideLoader({ queueType: 'fetchLayout' }));
    // }

    // if (statusCode === 401 || statusCode === 419) {
    //   dispatch(setLoggedStatus(false));
    // }
  } catch (error) {
    if (error instanceof PuxadinhoError) {
      if (error.statusCode === 400 || error.statusCode === 500) {
        // if (typeof history !== 'undefined') {
        //   history.push('./');
        // }
        // dispatch(showErrorDialog( error.result ));
      }

      if (error.statusCode === 401 || error.statusCode === 419) {
        dispatch(setLoggedStatus(false));
      }
    }

    dispatch(hideLoader({ queueType: "fetchLayout" }));

    console.log(error);
    // console.log(`fetch layout error ${err}`);
  }
};

export const getUppermostParentModelFromEntity = (
  state: RootState,
  entityModel: string
): Model | undefined => {
  const model = getModel(state, entityModel);

  if (typeof model !== "undefined" && model.entityParent !== null) {
    return getUppermostParentModelFromEntity(state, model.entityParent);
  }

  return model;
};

export const getModelsToPreload = (state: RootState) => {
  return state.layout.modelsToPreload;
};

export const { fetchLayoutSuccess } = layoutSlice.actions;
export default layoutSlice.reducer;
