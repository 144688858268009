import { DictionaryEntryType } from "~/types/views";
import PostBlock from "~/views/PostView/PostBlock/PostBlock";
import DictionaryEntryHeader from "./DictionaryEntryHeader/DictionaryEntryHeader";
import DictionaryEntryLinks from "./DictionaryEntryLinks/DictionaryEntryLinks";

type Props = {
  entityModel: string;
  entityId: string;
  entityView: DictionaryEntryType;
};

const DictionaryEntryView = ({ entityView, entityModel, entityId }: Props) => {
  return entityView ? (
    <>
      <DictionaryEntryHeader
        dictionaryEntry={entityView}
        link={`/${entityModel}/${entityId}`}
      />
      <PostBlock
        postBlock={entityView.block}
        link={`/${entityModel}/${entityId}`}
        entityModel={entityModel}
      />

      <DictionaryEntryLinks
        dictionaryEntryLinks={entityView.links}
        link={`/${entityModel}/${entityId}`}
      />
    </>
  ) : null;
};

export default DictionaryEntryView;
