import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { getModel } from "~/features/layout/layoutSlice";
import { fetchList } from "~/features/data/Lists/listsSlice";

import EntitiesList from "~/features/data/Lists/EntitiesList";
// import Entity from '~/features/entities/Entity';
import styles from "./Lists.module.css";
// import { getModelComponents } from '../../layout/layoutSlice';
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import ListsWithRouter from "~/features/data/Lists/ListsWithRouter";
import { RootState } from "~/app/store";
// import ModelComponents from '../../layout/ModelComponents';
import { selectEntity } from "../dataSlice";

type PropsFromRedux = ConnectedProps<typeof connector>;

type PathParams = {
  activeModel: string;
  id?: string;
};

type OwnProps = {
  parentModel?: string;
  parentEntityId?: string;
  baseUrl: string;
};

type PathAndOwnProps = RouteComponentProps<PathParams> & OwnProps;

type Props = PathAndOwnProps & PropsFromRedux;

const getCurrentUrl = (baseUrl: string, activeModel: string, id?: string) =>
  baseUrl + "/" + activeModel + (typeof id !== "undefined" ? "/" + id : "/");

export class EntitiesRaw extends Component<Props> {
  componentDidMount() {
    this.fetchList();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      match: { params },
      parentEntityId,
    } = this.props;

    if (
      params.activeModel !== prevProps.match.params.activeModel ||
      parentEntityId !== prevProps.parentEntityId
    ) {
      this.fetchList();
    }
  }

  fetchList() {
    const {
      match: { params },
      fetchList,
      parentModel,
      parentEntityId,
      history,
      model,
    } = this.props;

    if (
      typeof params.activeModel !== "undefined" &&
      typeof model !== "undefined"
    ) {
      fetchList(
        {
          model: params.activeModel,
          parentModel,
          parentEntityId,
          isListOfEntities: model.isListOfEntities,
        },
        history
      );
    }
  }

  render() {
    const {
      match: { params, url },
      parentModel,
      parentEntityId,
      baseUrl,
      entity,
      model,
    } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <Switch>
            <Route path={`${url}/:activeModel/:id?`}>
              <ListsWithRouter
                parentModel={params.activeModel}
                parentEntityId={params.id}
                baseUrl={url}
              />
            </Route>
          </Switch>

          <div
            className={
              typeof model !== "undefined" && model.hasOverview
                ? styles.listHalfContainer
                : styles.listContainer
            }
          >
            <EntitiesList
              selectedEntityId={
                typeof entity !== "undefined" ? entity.id : undefined
              }
              parentModel={parentModel}
              parentEntityId={parentEntityId}
              activeModel={params.activeModel}
              baseUrl={getCurrentUrl(baseUrl, params.activeModel)}
            />
          </div>
          {/* {typeof model !== "undefined" && model.hasOverview ? (
            <div className={styles.viewContainer}>
              <View
                selectedEntityId={
                  typeof entity !== "undefined" ? entity.id : undefined
                }
                parentModel={parentModel}
                parentEntityId={parentEntityId}
                activeModel={params.activeModel}
                baseUrl={getCurrentUrl(baseUrl, params.activeModel)}
              />
            </div>
          ) : null} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  { match: { params } }: PathAndOwnProps
) => ({
  entity:
    typeof params.id !== "undefined"
      ? selectEntity(state, params.activeModel, params.id)
      : undefined,
  model:
    typeof params.activeModel !== "undefined"
      ? getModel(state, params.activeModel)
      : undefined,
});

const mapDispatchToProps = {
  fetchList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const Entities = connector(EntitiesRaw);

export default Entities;
