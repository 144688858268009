import { RecipeType } from "~/types/views";
import styles from "./RecipeStats.module.scss";

type Props = {
  recipe: RecipeType;
};

const RecipeStats = ({ recipe }: Props) => {
  return (
    <div className={styles.ctn}>
      <dl>
        <dt>Autor</dt>
        <dd>{recipe.author?.title}</dd>
        <dt>Tempo de preparo</dt>
        <dd>{recipe.totalTimeText}</dd>
        <dt>Serve</dt>
        <dd>{recipe.recipeYieldText}</dd>
      </dl>
    </div>
  );
};

export default RecipeStats;
