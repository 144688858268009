import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import {
  getDependencyDialogMessage,
  getDependencyDialogEntity,
  isDependencyDialogMinimized,
} from "~/features/interface/interfaceSlice";

import {
  hideDependencyDialog,
  showEntityDependencies,
  minimizeDependencyDialog,
  maximizeDependencyDialog,
  runResolveDependencies,
} from "~/features/interface/DependencyDialog/dependencyDialogSlice";
import { getModel } from "~/features/layout/layoutSlice";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import styles from "./DependencyDialog.module.scss";
import Paper from "@mui/material/Paper";
import { selectEntityFromList } from "~/features/data/dataSlice";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MinimizeIcon from "@mui/icons-material/Minimize";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DependenciesList, DependencyLink } from "~/types";
import Fab from "@mui/material/Fab";
import ShareIcon from "@mui/icons-material/Share";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ExchangeSelector from "./ExchangeSelector/ExchangeSelector";
import Tooltip from "@mui/material/Tooltip";

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const getListItems = (list: DependenciesList) => {
  const listItems = [];
  for (const [key, value] of Object.entries(list)) {
    listItems.push(
      <li key={key}>
        <span>{key}</span>
        <div>
          <List>
            {value.map((dependencyLink: DependencyLink, index: number) => (
              <ListItem key={index} divider={true}>
                <ListItemText
                  secondary={
                    <Link to={dependencyLink.link}>{dependencyLink.title}</Link>
                  }
                  primary={null}
                />
              </ListItem>
              //   <ListItemLink
              //   key={index}
              //   primary={dependencyLink.title}
              //   to={dependencyLink.link}
              // />
            ))}
          </List>
        </div>
      </li>
    );
  }

  return listItems;
};

const DependencyDialogRaw = ({
  hideDependencyDialog,
  dependencies,
  entity,
  entityData,
  showEntityDependencies,
  minimizeDependencyDialog,
  isDependencyDialogMinimized,
  maximizeDependencyDialog,
  runResolveDependencies,
  model,
}: Props) => {
  // const handleClose = () => {
  //   hideDependencyDialog();
  // };

  return isDependencyDialogMinimized ? (
    <Fab
      color="primary"
      aria-label="maximize"
      className={styles.fab}
      onClick={() => maximizeDependencyDialog()}
    >
      <ShareIcon />
    </Fab>
  ) : (
    <Paper className={styles.ctn} elevation={3}>
      <AppBar position="static" color="secondary">
        <Toolbar>
          {dependencies.link !== null ? (
            <div className={styles.title}>
              <Link to={dependencies.link}>{entityData?.px_title}</Link>
            </div>
          ) : null}

          {typeof model !== "undefined" && model.hasResolveDependencies ? (
            <Tooltip title="Remover dependências automaticamente">
              <IconButton
                // edge="end"
                color="inherit"
                aria-label="Atualizar"
                size="large"
                onClick={() => {
                  if (window.confirm("Isso é irreversível, tem certeza?")) {
                    runResolveDependencies(entity.modelList, entity.id);
                  }
                }}
              >
                <AutoFixHighIcon />
              </IconButton>
            </Tooltip>
          ) : null}

          <IconButton
            // edge="end"
            color="inherit"
            aria-label="Atualizar"
            size="large"
            onClick={() => {
              if (entity.modelList.model !== "" && entity.id !== "") {
                showEntityDependencies(entity.modelList, entity.id);
              }
            }}
          >
            <RefreshIcon />
          </IconButton>

          <IconButton
            color="inherit"
            aria-label="Minimizar"
            size="large"
            onClick={() => minimizeDependencyDialog()}
          >
            <MinimizeIcon />
          </IconButton>

          <IconButton
            edge="end"
            color="inherit"
            aria-label="Fechar"
            onClick={() => hideDependencyDialog()}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {typeof model !== "undefined" && model.canBeExchangedBy.length ? (
        <AppBar position="static" color="default">
          <Toolbar>
            <div className={styles.ctnExchangeSelector}>
              <ExchangeSelector entitiesList={model.canBeExchangedBy} />
            </div>
          </Toolbar>
        </AppBar>
      ) : null}
      <div className={styles.content}>
        {Object.keys(dependencies.list).length ? (
          <ul>{getListItems(dependencies.list)}</ul>
        ) : (
          // <DialogContentText id="alert-dialog-description">
          <p>Unknown server error.</p>
          // </DialogContentText>
        )}
      </div>
    </Paper>
  );
};

const mapStateToProps = (state: RootState) => {
  const entity = getDependencyDialogEntity(state);
  return {
    isDependencyDialogMinimized: isDependencyDialogMinimized(state),
    dependencies: getDependencyDialogMessage(state),
    entity,
    model: getModel(state, entity.modelList.model),
    entityData: selectEntityFromList(state, entity.modelList.model, entity.id),
  };
};

const mapDispatchToProps = {
  hideDependencyDialog,
  showEntityDependencies,
  minimizeDependencyDialog,
  maximizeDependencyDialog,
  runResolveDependencies,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const DependencyDialog = connector(DependencyDialogRaw);
export default DependencyDialog;
