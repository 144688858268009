import styles from "./RecipeRelationships.module.scss";
import { RPRelationshipType } from "~/types/views";
import RecipeRelationship from "../RecipeRelationship/RecipeRelationship";
import { Link } from "react-router-dom";

type Props = {
  recipeRelationships: RPRelationshipType[];
  link: string;
};
const RecipeRelationships = ({ recipeRelationships, link }: Props) => {
  if (!recipeRelationships.length) {
    return null;
  }

  return (
    <div className={styles.ctn}>
      <Link to={`${link}/recipeRelationship/`}>
        <div className={styles.header}>
          <h5>Sugestão de cardápio</h5>
        </div>
      </Link>

      <div className={styles.contentColumns2}>
        {recipeRelationships.map(
          (recipeRelationship, recipeRelationshipIndex) => (
            <RecipeRelationship
              key={recipeRelationshipIndex}
              recipeRelationship={recipeRelationship}
              link={`${link}/recipeRelationship/${recipeRelationship.id}`}
            />
          )
        )}
      </div>
    </div>
  );
};

export default RecipeRelationships;
