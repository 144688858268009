import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "~/app/store";
import { LoadedImages } from "~/features/interface/ImageManager/ImageManager";
import {
  hideLoader,
  showLoader,
} from "~/features/interface/Loader/loaderSlice";
import { saveImage } from "~/api/imagesApi";
import { pushEntitySuccess } from "~/features/data/Entity/entitiesSlice";
import { showSnackbar } from "../Snackbar/snackbarSlice";
import { PuxadinhoError } from "~/shared/PuxadinhoError";
// import { ListedImage } from "~/types";
// import { PuxadinhoHandledError } from 'types';

interface ImageManagerState {
  //   errorDialogMessage: PuxadinhoHandledError;
  isImageManagerOpen: boolean;
  model?: string;
  id?: string | undefined;
  column?: string;
  locale?: string;
  modelToAssociateUpload?: string | null;
  idToAssociateUpload?: string | null;
}

const initialState: ImageManagerState = {
  //   errorDialogMessage: {},
  isImageManagerOpen: false,
};

export const imageManagerSlice = createSlice({
  name: "imageManager",
  initialState,
  reducers: {
    showImageManager: (
      state,
      action: PayloadAction<{
        model?: string;
        id?: string | undefined;
        column?: string;
        locale?: string;
      }>
    ) => {
      const { model, id, column, locale } = action.payload;

      state.isImageManagerOpen = true;
      state.model = model;
      state.id = id;
      state.column = column;
      state.locale = locale;

      //if both are set, automatically set associations (this can be overriden by user)
      if (typeof id !== "undefined" && typeof model !== "undefined") {
        state.modelToAssociateUpload = model;
        state.idToAssociateUpload = id;
      }

      //   state.errorDialogMessage = action.payload;
    },
    hideImageManager: (state) => {
      state.isImageManagerOpen = false;
      state.modelToAssociateUpload = null;
      state.idToAssociateUpload = null;
      //   state.errorDialogMessage = {};
    },
    setAssociation: (
      state,
      action: PayloadAction<{
        model: string | null;
        id: string | null;
      }>
    ) => {
      const { model, id } = action.payload;

      state.modelToAssociateUpload = model;
      state.idToAssociateUpload = id;
    },
  },
});

export const updateDraft = (): AppThunk => async () => null;
// (image: ListedImage): AppThunk =>
// async (dispatch) => {
// dispatch(
// setColumnValue({
//   model: ownProps.entityModel,
//   id: ownProps.entityId,
//   column,
//   value,
//   locale,
// })
// );
// try {
//   for (const key in loadedImages) {
//     const response = await saveImage(
//       loadedImages[key].file,
//       loadedImages[key].crops
//     );
//     const { statusCode, result } = response;
//     if (statusCode === 200) {
//       if (typeof clearLoadedFile !== 'undefined') {
//         clearLoadedFile(key);
//       }
//       dispatch(
//         pushEntitySuccess({
//           response: result,
//           modelList: { model: 'image' },
//           id: String(result.id),
//         })
//       );
//       dispatch(
//         showSnackbar({
//           message: 'Imagem salva com sucesso',
//           type: 'success',
//         })
//       );
//     }
//   }
//   dispatch(hideLoader({ queueType: 'pushImages' }));
// } catch (error) {
//   if (error instanceof PuxadinhoError) {
//     if (error.statusCode === 400 || error.statusCode === 500) {
//       // dispatch(showErrorDialog(error.result ));
//     }
//     if (error.statusCode === 401 || error.statusCode === 419) {
//       // dispatch(setLoggedStatus(false));
//     }
//   }
//   console.log(error);
//   // console.log(`push entity error ${err}`);
// }
// };

export const pushImages =
  (
    loadedImages: LoadedImages,
    clearLoadedFile?: (key: string) => void
  ): AppThunk =>
  async (dispatch, getState) => {
    //this is not good
    const { modelToAssociateUpload, idToAssociateUpload } =
      getState().interface.imageManager;

    try {
      dispatch(showLoader({ queueType: "pushImages" }));

      for (const key in loadedImages) {
        const response = await saveImage(
          loadedImages[key].file,
          loadedImages[key].crops,
          loadedImages[key].originalWidth,
          loadedImages[key].originalHeight,
          modelToAssociateUpload,
          idToAssociateUpload
        );

        const { statusCode, result } = response;

        if (statusCode === 200) {
          // console.log(result);
          if (typeof clearLoadedFile !== "undefined") {
            clearLoadedFile(key);
          }
          dispatch(
            pushEntitySuccess({
              response: result,
              modelList: { model: "image" },
              id: String(result.id),
            })
          );
          dispatch(
            showSnackbar({
              message: "Imagem salva com sucesso",
              type: "success",
            })
          );
        }
      }

      dispatch(hideLoader({ queueType: "pushImages" }));
    } catch (error) {
      if (error instanceof PuxadinhoError) {
        if (error.statusCode === 400 || error.statusCode === 500) {
          // dispatch(showErrorDialog(error.result ));
        }

        if (error.statusCode === 401 || error.statusCode === 419) {
          // dispatch(setLoggedStatus(false));
        }
      }

      console.log(error);
      // console.log(`push entity error ${err}`);
    }
    dispatch(hideLoader({ queueType: "pushImages" }));
  };

// export const getErrorDialogMessage = (state: ImageManagerState) =>
//   state.errorDialogMessage;
export const isImageManagerOpen = (state: ImageManagerState) =>
  state.isImageManagerOpen;

export const getDraftDetails = (state: ImageManagerState) => ({
  model: state.model,
  id: state.id,
  column: state.column,
  locale: state.locale,
});

export const getImageAssociation = (state: ImageManagerState) => ({
  model: state.modelToAssociateUpload ?? null,
  id: state.idToAssociateUpload ?? null,
});

export const { showImageManager, hideImageManager, setAssociation } =
  imageManagerSlice.actions;
export default imageManagerSlice.reducer;
