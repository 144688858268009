import { useCallback } from "react";
import { RootState } from "~/app/store";
import { getDraftColumn } from "~/features/drafts/draftsSlice";
import { connect, ConnectedProps } from "react-redux";
// import TextField from '@mui/material/TextField';
import slugify from "@sindresorhus/slugify";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import EditAttributesIcon from "@mui/icons-material/EditAttributes";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface TrackbackFieldType {
  column: string;
  type: string;
  placeholder: string;
  copyFrom?: [string, boolean][];
  rules?: string;
  always?: boolean;
}

type OwnProps = {
  entityModel: string;
  entityId?: string;
  field: TrackbackFieldType;
  onChange: (column: string, value: string, locale?: string) => void;
  locale: string;
  copyValues: (
    column: string,
    value: any,
    copyFrom: [string, boolean][],
    callback: (copyFromValues: string[]) => void,
    locale?: string
  ) => void;
};

type Props = PropsFromRedux & OwnProps;

const getCopiedValuesSlug = (copyFromValues: string[]): string => {
  return copyFromValues
    .reduce((carry: string, value) => {
      return typeof value !== "undefined"
        ? carry + "-" + slugify(value)
        : carry;
    }, "-")
    .substring(2);
};

const TrackbackFieldRaw = ({
  field,
  draftValue,
  onChange,
  locale,
  copyValues,
}: Props) => {
  const canCopy = useCallback((): boolean => {
    return field.copyFrom?.length ? true : false;
  }, [field]);

  return (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel htmlFor={`field-${field.column}-${field.type}`}>
        {field.placeholder}
      </InputLabel>
      <OutlinedInput
        id={`field-${field.column}-${field.type}`}
        value={
          typeof draftValue !== "undefined" && draftValue !== null
            ? draftValue
            : ""
        }
        onChange={(event) => {
          onChange(field.column, slugify(event.target.value), locale);
        }}
        endAdornment={
          canCopy() ? (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="copy from other fields"
                onClick={() => {
                  if (typeof field.copyFrom !== "undefined") {
                    copyValues(
                      field.column,
                      null,
                      field.copyFrom,
                      getCopiedValuesSlug,
                      locale
                    );
                  }

                  // onChange(field.column, getCopiedValuesSlug(), locale);
                }}
                size="small"
              >
                <EditAttributesIcon fontSize="inherit" />
              </IconButton>
            </InputAdornment>
          ) : null
        }
        label={field.placeholder}
      />
    </FormControl>
  );
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId, field }: OwnProps
) => ({
  draftValue: getDraftColumn(state, entityModel, entityId, field.column),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const TrackbackField = connector(TrackbackFieldRaw);
export default TrackbackField;
