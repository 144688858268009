import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface QuickCreateDialogState {
  isQuickCreateDialogOpen: boolean;
  model: string;
}

const initialState: QuickCreateDialogState = {
  isQuickCreateDialogOpen: false,
  model: "",
};

export const quickCreateDialogSlice = createSlice({
  name: "quickCreateDialog",
  initialState,
  reducers: {
    showQuickCreateDialog: (state, action: PayloadAction<string>) => {
      const model = action.payload;

      state.isQuickCreateDialogOpen = true;
      state.model = model;
    },
    hideQuickCreateDialog: (state) => {
      state.isQuickCreateDialogOpen = false;
      state.model = "";
    },
  },
});

export const getQuickCreateDialogModel = (state: QuickCreateDialogState) =>
  state.model;
export const isQuickCreateDialogOpen = (state: QuickCreateDialogState) =>
  state.isQuickCreateDialogOpen;

export const { showQuickCreateDialog, hideQuickCreateDialog } =
  quickCreateDialogSlice.actions;
export default quickCreateDialogSlice.reducer;
