import { getModel } from "~/features/layout/layoutSlice";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import {
  setColumnValue,
  getDraftColumn,
  resetDraftForNewEntity,
} from "./draftsSlice";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

import { RootState } from "~/app/store";
import Fields from "./Fields";
import RadioField from "~/fields/RadioField";

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  entityModel: string;
  entityId?: string;
};

type Props = PropsFromRedux & OwnProps;

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    // flexGrow: 1,
  },
  fieldContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // zIndex: 0,
  },
}));

const getField = (fieldsList: any, fieldId: string) => {
  return fieldsList[fieldId];
};

const FormFieldsRaw = ({
  model,
  entityModel,
  entityId,
  typeValue,
  onTypeChange,
}: Props) => {
  const { classes } = useStyles();

  // console.log('re-render form-fields');

  // const getTranslatableFields = (model: Model) => {
  //   return model.locales.map((locale) => (
  //     <Fields
  //       fields={model.visibleFields.translatable}
  //       locale={locale}
  //       entityModel={entityModel}
  //       entityId={entityId}
  //       key={locale}
  //     />
  //   ));
  // };

  // const getFields = (
  //   fields: string[],
  //   model: Model,
  //   draft: any,
  //   onChange: (column: string, value: any, locale?: string) => void,
  //   locale?: string
  // ) => {
  //   const classes = useStyles();

  //   return fields.map((fieldId) => {
  //     const field = getField(model.fieldsList, fieldId);
  //     const Component = getComponent(field.type);

  //     if (typeof Component === 'undefined') {
  //       return null;
  //     }

  //     return (
  //       <div className={classes.root}>
  //         <Component
  //           key={fieldId}
  //           field={field}
  //           draft={draft}
  //           onChange={onChange}
  //           locale={locale}
  //         />
  //         <Divider key={`divider-${fieldId}`} />
  //       </div>
  //     );
  //   });
  // };

  return typeof model !== "undefined" ? (
    <div>
      {model.hasType && typeof model.typeField !== "undefined" ? (
        <div className={classes.fieldContainer}>
          <RadioField
            field={getField(model.fieldsList, model.typeField)}
            onChange={onTypeChange}
            locale={undefined}
            entityModel={entityModel}
            entityId={entityId}
          />
        </div>
      ) : null}

      <Fields
        fields={
          typeof model.optionsByType !== "undefined"
            ? typeof typeValue !== "undefined" &&
              typeof model.optionsByType[typeValue] !== "undefined"
              ? model.optionsByType[typeValue].visibleFields.normal
              : model.visibleFields.normal
            : model.visibleFields.normal
        }
        entityModel={entityModel}
        entityId={entityId}
      />
      {/* {getTranslatableFields(model)} */}
      {/* {typeof draft !== 'undefined' ? (
        <Fields
          fields={model.visibleFields.normal}
          model={model}
          entityModel={entityModel}
          entityId={entityId}
        />
      ) : null}
      {typeof draft !== 'undefined'
        ? getTranslatableFields(model)
        : null} */}
    </div>
  ) : null;
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId }: OwnProps
) => {
  const model = getModel(state, entityModel);
  return {
    model: model,
    typeValue:
      typeof model !== "undefined" &&
      model.hasType &&
      typeof model.typeField !== "undefined"
        ? getDraftColumn(state, entityModel, entityId, model.typeField)
        : undefined,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => ({
  onTypeChange: (column: string, value: any, locale?: string): void => {
    dispatch(resetDraftForNewEntity({ model: ownProps.entityModel }));
    dispatch(
      setColumnValue({
        model: ownProps.entityModel,
        id: ownProps.entityId,
        column,
        value,
        locale,
      })
    );
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const FormFields = connector(FormFieldsRaw);
export default FormFields;
