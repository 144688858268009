import { useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import { getDraftColumn, setColumnValue } from "~/features/drafts/draftsSlice";
import MultipleDependantSelectField from "~/fields/MultipleDependantSelectField";
import IconButton from "@mui/material/IconButton";
import CachedIcon from "@mui/icons-material/Cached";
import styles from "./ExchangeSelector.module.scss";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { getDependencyDialogEntity } from "~/features/interface/interfaceSlice";
import { runExchangeInDependencies } from "~/features/interface/DependencyDialog/dependencyDialogSlice";
import { ModelListProps } from "~/types";

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  entitiesList: string[];
};

type Props = PropsFromRedux & OwnProps;

const fieldEntityModel = "sharedModel";
const fieldEntityId = undefined;
const fieldColumn = "entitiable";

const ExchangeSelectorRaw = ({
  entity,
  selectedModel,
  selectedId,
  onChange,
  runExchangeInDependencies,
  entitiesList,
}: Props) => {
  const field = useMemo(
    () => ({
      column: fieldColumn,
      type: "multipleDependantSelect",
      placeholder: "Substituir por",
      dependencies: entitiesList,
    }),
    [entitiesList]
  );

  return (
    <div className={styles.ctn}>
      <MultipleDependantSelectField
        field={field}
        onChange={onChange}
        locale={""}
        entityModel={fieldEntityModel}
        entityId={fieldEntityId}
      />
      <div className={styles.containerButtons}>
        <IconButton
          aria-label="quick create"
          color="secondary"
          className={styles.button}
          onClick={() => {
            if (window.confirm("Isso é irreversível, tem certeza?")) {
              runExchangeInDependencies(
                entity.modelList,
                entity.id,
                selectedModel,
                selectedId
              );
            }
          }}
          size="small"
          edge="end"
        >
          <CachedIcon />
        </IconButton>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedId: getDraftColumn(
    state,
    fieldEntityModel,
    fieldEntityId,
    fieldColumn + "_id"
  ),
  selectedModel: getDraftColumn(
    state,
    fieldEntityModel,
    fieldEntityId,
    fieldColumn + "_type"
  ),
  entity: getDependencyDialogEntity(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onChange: (column: string, value: any, locale?: string): void => {
    dispatch(
      setColumnValue({
        model: fieldEntityModel,
        id: fieldEntityId,
        column,
        value,
        locale,
      })
    );
  },
  runExchangeInDependencies: (
    modelList: ModelListProps,
    id: string,
    newModel: string,
    newId: string
  ) => dispatch(runExchangeInDependencies(modelList, id, newModel, newId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const ExchangeSelector = connector(ExchangeSelectorRaw);
export default ExchangeSelector;
