import styles from "./DictionaryEntryLinks.module.scss";
import { RPRelationshipType } from "~/types/views";
import { Link } from "react-router-dom";
import PostRelationship from "~/views/PostView/PostRelationship/PostRelationship";

type Props = {
  dictionaryEntryLinks: RPRelationshipType[];
  link: string;
};
const DictionaryEntryLinks = ({ dictionaryEntryLinks, link }: Props) => {
  if (!dictionaryEntryLinks.length) {
    return null;
  }

  return (
    <div className={styles.ctn}>
      <Link to={`${link}/dictionaryEntryLink/`}>
        <div className={styles.header}>
          <h5>Conteúdo adicional</h5>
        </div>
      </Link>

      <div className={styles.contentColumns2}>
        {dictionaryEntryLinks.map(
          (dictionaryEntryLink, dictionaryEntryLinkIndex) => (
            <PostRelationship
              key={dictionaryEntryLinkIndex}
              relationship={dictionaryEntryLink}
              link={`${link}/dictionaryEntryLink/${dictionaryEntryLink.id}`}
            />
          )
        )}
      </div>
    </div>
  );
};

export default DictionaryEntryLinks;
