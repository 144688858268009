import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import { ShowcaseType, SubhomeType } from "~/types/views";
import styles from "./DisplayPageBanner.module.scss";
import { Link } from "react-router-dom";
import { pushEntityToDestination } from "~/features/interface/Copy/copySlice";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { isCopyDestinationSet } from "~/features/interface/interfaceSlice";
import Button from "@mui/material/Button";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import { getImageUrl } from "~/shared/ImageTools";

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  display: SubhomeType | ShowcaseType;
  entityModel: string;
  entityId: string;
};

type Props = PropsFromRedux & OwnProps;

const DisplayPageBannerRaw = ({
  display,
  entityModel,
  entityId,
  isCopyDestinationSet,
  pushEntityToDestination,
}: Props) => {
  return (
    <header className={styles.ctn}>
      {isCopyDestinationSet ? (
        <div className={styles.ctnCopyButton}>
          <Button
            variant="contained"
            aria-label="fingerprint"
            color="primary"
            size="small"
            onClick={() => {
              pushEntityToDestination(`${entityModel}`, display.id);
            }}
          >
            <AddShoppingCartOutlinedIcon fontSize="inherit" />
          </Button>
        </div>
      ) : null}
      <Link to={`/${entityModel}/${entityId}`}>
        {display.image ? (
          <img
            src={getImageUrl(`${display.image.url}`)}
            alt={display.image.alt}
            width={1140}
            height={582}
          />
        ) : null}
        <div className={styles.ctnText}>
          <h1>{display.bannerTitle}</h1>
          <p>{display.bannerSubtitle}</p>
        </div>
      </Link>
    </header>
  );
};

const mapStateToProps = (state: RootState) => ({
  isCopyDestinationSet: isCopyDestinationSet(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  pushEntityToDestination: (model: string, id: string) =>
    dispatch(pushEntityToDestination(model, id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const DisplayPageBanner = connector(DisplayPageBannerRaw);
export default DisplayPageBanner;
