import {
  PuxadinhoErrorResponse,
  PuxadinhoErrorCodes,
  PuxadinhoHandledError,
} from "~/types";

export class PuxadinhoError extends Error {
  public statusCode: PuxadinhoErrorCodes;
  public result: PuxadinhoHandledError;

  constructor({ statusCode, result }: PuxadinhoErrorResponse) {
    super("PuxadinhoError");
    this.name = "PuxadinhoError";
    this.statusCode = statusCode;
    this.result = result ?? {};
    Error.captureStackTrace(this, PuxadinhoError);
  }
}
