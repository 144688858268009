import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { Crop } from "react-image-crop";
import ImageCropper from "~/features/interface/ImageManager/ImageCropper/ImageCropper";
import { LoadedImages } from "~/features/interface/ImageManager/ImageManager";
import styles from "./SelectedFileCrops.module.scss";
import IconButton from "@mui/material/IconButton";
import SettingsOverscanIcon from "@mui/icons-material/SettingsOverscan";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { getFreeCrop } from "../ImageManagerUtils";

type OwnProps = {
  selectedFile: string;
  loadedFiles: LoadedImages;
  setLoadedFiles: (loadedImages: LoadedImages) => void;
};

type Props = OwnProps;

const SelectedFileCrops = ({
  selectedFile,
  loadedFiles,
  setLoadedFiles,
}: Props) => {
  const { reader, crops } = loadedFiles[selectedFile];

  return (
    <div className={styles.ctn}>
      {Object.entries(crops).map(([cropName, crop]) =>
        typeof reader.result === "string" ? (
          <Card
            key={String(cropName)}
            className={styles.ctnCrop}
            variant="outlined"
          >
            <CardActionArea className={styles.ctnImage}>
              <ImageCropper
                src={reader.result}
                crop={crops[cropName]}
                setCrop={(newPercentageCrop: Crop) => {
                  setLoadedFiles({
                    ...loadedFiles,
                    [selectedFile]: {
                      ...loadedFiles[selectedFile],
                      crops: { ...crops, [cropName]: newPercentageCrop },
                    },
                  });
                }}
              />
            </CardActionArea>
            <CardActions>
              <Typography className={styles.title}>
                Formato: {cropName}
              </Typography>
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  setLoadedFiles({
                    ...loadedFiles,
                    [selectedFile]: {
                      ...loadedFiles[selectedFile],
                      crops: {
                        ...crops,
                        [cropName]: getFreeCrop(crop.aspect),
                      },
                    },
                  });
                }}
              >
                <SettingsOverscanIcon />
              </IconButton>
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  const { [cropName]: removedCrop, ...otherCrops } = crops;

                  setLoadedFiles({
                    ...loadedFiles,
                    [selectedFile]: {
                      ...loadedFiles[selectedFile],
                      crops: otherCrops,
                    },
                  });
                }}
              >
                <DeleteForeverIcon />
              </IconButton>
            </CardActions>
          </Card>
        ) : null
      )}
    </div>
  );
};

export default SelectedFileCrops;
