import {
  Editor,
  BaseEditor,
  BaseElement,
  Element,
  Range,
  Transforms,
} from "slate";
// import { ReactEditor } from "slate-react";
import {
  EXTERNAL_LINK,
  inlineElements,
  INTERNAL_LINK,
  listElements,
  LIST_ITEM,
  PARAGRAPH,
} from "./elementsLists";

export type InternalLink = {
  type: string;
  id: string;
  model: string;
  new_tab: boolean;
  children: Element[];
};

export type ExternalLink = {
  type: string;
  url: string;
  new_tab: boolean;
  children: Element[];
};

export interface NewBaseElement extends BaseElement {
  type?: string;
}

export type CustomElement = NewBaseElement | InternalLink | ExternalLink;

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor;
    Element: CustomElement;
  }
}

export const PuxEditorHelper = {
  ...Editor,
  isInline: (_editor: BaseEditor) => (element: CustomElement) => {
    return inlineElements.includes(element.type ?? "");
  },
  isInlineElementActive: (editor: BaseEditor, elementType: string) => {
    const [match] = Editor.nodes(editor, {
      match: (n) => Element.isElement(n) && n.type === elementType,
    });

    return !!match;
  },

  getActiveInlineElement: (editor: BaseEditor, elementType: string) => {
    const [match] = Editor.nodes(editor, {
      match: (n) => Element.isElement(n) && n.type === elementType,
    });

    // eslint-disable-next-line no-extra-boolean-cast
    return !!match ? match[0] : null;
  },

  // setActiveInlineElementData: (
  //   editor: BaseEditor,
  //   elementType: string,
  //   data: any
  // ) => {
  //   Transforms.setNodes(editor, data, {
  //     match: (n) => Element.isElement(n) && n.type === elementType,
  //   });
  // },

  // getElementPath: (editor: ReactEditor, element: any) => {
  //   return ReactEditor.findPath(editor, element);
  // },

  isCollapsed: (editor: BaseEditor) => {
    const { selection } = editor;
    return selection === null || Range.isCollapsed(selection);
  },

  focusOnEditor: (_editor: BaseEditor) => {
    // console.log(editor);
    // ReactEditor.focus(editor);
  },

  toggleSemanticElement: (editor: BaseEditor, semanticElementType: string) => {
    const isActive = PuxEditorHelper.isInlineElementActive(
      editor,
      semanticElementType
    );

    PuxEditorHelper.focusOnEditor(editor);

    if (isActive) {
      Transforms.unwrapNodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          n.type === semanticElementType,
      });
    } else {
      if (!PuxEditorHelper.isCollapsed(editor)) {
        Transforms.wrapNodes(
          editor,
          {
            type: semanticElementType,
            children: [],
          },
          { split: true }
        );
      }
    }

    return;
  },

  moveFocusForward: (editor: BaseEditor) => {
    // PuxEditorHelper.focusOnEditor(editor);
    Transforms.move(editor, { distance: 1, unit: "offset" });
  },

  removeExternalLinkElement: (editor: BaseEditor) => {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && Element.isElement(n) && n.type === EXTERNAL_LINK,
    });
  },

  setExternalLinkElement: (
    editor: BaseEditor,
    url: string,
    new_tab: boolean
  ) => {
    const isActive = PuxEditorHelper.isInlineElementActive(
      editor,
      EXTERNAL_LINK
    );

    PuxEditorHelper.focusOnEditor(editor);

    if (isActive) {
      PuxEditorHelper.removeExternalLinkElement(editor);
    } else {
      if (!PuxEditorHelper.isCollapsed(editor)) {
        Transforms.wrapNodes(
          editor,
          {
            type: EXTERNAL_LINK,
            url,
            new_tab,
            children: [],
          },
          { split: true }
        );
      }
    }

    return;
  },

  removeInternalLinkElement: (editor: BaseEditor) => {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && Element.isElement(n) && n.type === INTERNAL_LINK,
    });
  },

  setInternalLinkElement: (
    editor: BaseEditor,
    model: string,
    id: string,
    new_tab: boolean
  ) => {
    const isActive = PuxEditorHelper.isInlineElementActive(
      editor,
      INTERNAL_LINK
    );

    PuxEditorHelper.focusOnEditor(editor);

    if (isActive) {
      // if (model !== null && id !== null) {
      //   PuxEditorHelper.setActiveInlineElementData(editor, INTERNAL_LINK, {
      //     type: INTERNAL_LINK,
      //     model,
      //     id,
      //     new_tab,
      //     children: [],
      //   });
      // } else {
      PuxEditorHelper.removeInternalLinkElement(editor);
      // }
    } else {
      if (
        !PuxEditorHelper.isCollapsed(editor) &&
        model !== null &&
        id !== null
      ) {
        Transforms.wrapNodes(
          editor,
          {
            type: INTERNAL_LINK,
            model,
            id,
            new_tab,
            children: [],
          },
          { split: true }
        );
      }
    }

    return;
  },

  isBlockActive: (editor: BaseEditor, format: string) => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === format,
      })
    );

    return !!match;
  },

  toggleBlock: (editor: BaseEditor, format: string) => {
    const isActive = PuxEditorHelper.isBlockActive(editor, format);
    const isList = listElements.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        listElements.includes(n.type ?? ""),
      split: true,
    });
    // let newProperties: Partial<Element>

    const newProperties = {
      type: isActive ? PARAGRAPH : isList ? LIST_ITEM : format,
    };

    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  },
};
