import {
  ComponentType,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import { getModelComponents, getModel } from "~/features/layout/layoutSlice";
import { Link, LinkProps } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab, { TabProps } from "@mui/material/Tab";
import { getDraftColumn } from "~/features/drafts/draftsSlice";

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  activeTab: string;
  baseUrl: string;
  activeModel: string;
  activeId: string;
};

type Props = OwnProps & PropsFromRedux;

const LinkTab: ComponentType<TabProps & LinkProps> = Tab as ComponentType<
  TabProps & LinkProps
>;

const ModelComponentsRaw = ({
  modelComponents,
  activeTab,
  baseUrl,
  model,
  typeValue,
}: Props) => {
  // const selected = modelComponents.
  const [value, setValue] = useState<number | boolean>(false);

  const handleChange = (_event: SyntheticEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const filteredModelComponents = useMemo(() => {
    if (
      typeof model === "undefined" ||
      typeof model.optionsByType === "undefined" ||
      !model.hasType
    )
      return modelComponents;

    const options = model.optionsByType[typeValue];

    if (typeof options === "undefined") return modelComponents;

    return modelComponents.filter(
      (modelComponent) =>
        !options.modelComponentsToExclude.includes(modelComponent.id)
    );
  }, [model, typeValue, modelComponents]);

  useEffect(() => {
    const index = modelComponents.findIndex(
      (component) => component.id === activeTab
    );
    setValue(index !== -1 ? index : false);
  }, [activeTab, modelComponents]);

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      scrollButtons="auto"
      variant="scrollable"
      // indicatorColor="secondary"
      // textColor="secondary"
      selectionFollowsFocus
      indicatorColor="secondary"
      textColor="inherit"
      // centered
    >
      {filteredModelComponents.map((component) => (
        <LinkTab
          label={component.displayTitle}
          key={component.id}
          to={`${baseUrl}/${component.id}/`}
          component={Link}
        />
      ))}
    </Tabs>
  );
};

const mapStateToProps = (
  state: RootState,
  { activeModel, activeId }: OwnProps
) => {
  const model = getModel(state, activeModel);

  return {
    model,
    modelComponents: getModelComponents(state, activeModel),
    // entity: selectEntity(state, activeModel, activeId) as any,
    typeValue:
      typeof model !== "undefined" &&
      model.hasType &&
      typeof model.typeField !== "undefined"
        ? getDraftColumn(state, activeModel, activeId, model.typeField)
        : undefined,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const ModelComponents = connector(ModelComponentsRaw);
export default ModelComponents;
