import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PuxadinhoHandledError } from 'types';

interface ErrorDialogState {
  errorDialogMessage: PuxadinhoHandledError;
  isErrorDialogOpen: boolean;
}

const initialState: ErrorDialogState = {
  errorDialogMessage: {},
  isErrorDialogOpen: false,
};

export const errorDialogSlice = createSlice({
  name: 'errorDialog',
  initialState,
  reducers: {
    showErrorDialog: (state, action: PayloadAction<PuxadinhoHandledError>) => {
      state.isErrorDialogOpen = true;
      state.errorDialogMessage = action.payload;
    },
    hideErrorDialog: (state) => {
      state.isErrorDialogOpen = false;
      state.errorDialogMessage = {};
    },
  },
});

export const getErrorDialogMessage = (state: ErrorDialogState) =>
  state.errorDialogMessage;
export const isErrorDialogOpen = (state: ErrorDialogState) =>
  state.isErrorDialogOpen;

export const { showErrorDialog, hideErrorDialog } = errorDialogSlice.actions;
export default errorDialogSlice.reducer;
