import { AnyAction } from "redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { getModel } from "~/features/layout/layoutSlice";
import { copyValues, setColumnValue } from "./draftsSlice";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import Divider from "@mui/material/Divider";
import StringField from "~/fields/StringField";
import SliderField from "~/fields/SliderField";
import QuantityField from "~/fields/QuantityField";
import TrackbackField from "~/fields/TrackbackField";
import ToggleField from "~/fields/ToggleField";
import RadioField from "~/fields/RadioField";
import CheckboxField from "~/fields/CheckboxField";
import TagField from "~/fields/TagField";
import MultilineField from "~/fields/MultilineField";
import NumberField from "~/fields/NumberField";
import DependantRadioField from "~/fields/DependantRadioField";
import DependantCheckboxField from "~/fields/DependantCheckboxField";
import DependantSelectField from "~/fields/DependantSelectField";
import DependantTagField from "~/fields/DependantTagField";
import DateField from "~/fields/DateField";
import TimeField from "~/fields/TimeField";
import DatetimeField from "~/fields/DatetimeField";
import ImageField from "~/fields/ImageField";
import SimpleSlateField from "~/fields/SimpleSlateField";
import SimpleSlateMultilineField from "~/fields/SimpleSlateMultilineField";
import SimpleSlateCompleteField from "~/fields/SimpleSlateCompleteField";
import MultipleDependantSelectField from "~/fields/MultipleDependantSelectField";
import IngredientListingField from "~/fields/IngredientListingField";
import FreeSoloField from "~/fields/FreeSoloField";
import DurationField from "~/fields/DurationField";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  entityModel: string;
  entityId?: string;
  locale?: string;
  fields: string[];
};

type Props = PropsFromRedux & OwnProps;

// type Props = {
//   fields: string[];
//   model: Model;
//   draft: any;
//   onChange: (column: string, value: any, locale?: string) => void;
//   locale?: string;
// };

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    // flexGrow: 1,
  },
  fieldContainer: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    // zIndex: 0,
  },
}));

const fieldTypes: { [key: string]: any } = {
  string: StringField,
  slider: SliderField,
  quantity: QuantityField,
  trackback: TrackbackField,
  toggle: ToggleField,
  multiline: MultilineField,
  number: NumberField,
  radio: RadioField,
  checkbox: CheckboxField,
  tag: TagField,
  dependantRadio: DependantRadioField,
  dependantCheckbox: DependantCheckboxField,
  dependantSelect: DependantSelectField,
  dependantTag: DependantTagField,
  date: DateField,
  time: TimeField,
  datetime: DatetimeField,
  image: ImageField,
  multipleDependantSelect: MultipleDependantSelectField,
  // slate: SlateField,
  simpleSlate: SimpleSlateField,
  simpleSlateMultiline: SimpleSlateMultilineField,
  simpleSlateComplete: SimpleSlateCompleteField,
  // slateMultiline: SlateMultilineField,
  ingredientListing: IngredientListingField,
  freeSolo: FreeSoloField,
  duration: DurationField,
};

const getComponent = (type: string): any => {
  return typeof fieldTypes[type] !== "undefined" ? fieldTypes[type] : undefined;
};

const getField = (fieldsList: any, fieldId: string) => {
  return fieldsList[fieldId];
};

const FieldsRaw = ({
  fields,
  model,
  entityModel,
  entityId,
  onChange,
  locale,
  copyValues,
}: Props) => {
  // console.log("render <fields>");
  const { classes } = useStyles();

  return typeof model !== "undefined" ? (
    <div
      className={classes.root}
      key={typeof entityId !== "undefined" ? entityId : "new-entity"}
    >
      {fields.map((fieldId) => {
        // console.log({ fieldsList: model?.fieldsList, fieldId });
        const field = getField(model.fieldsList, fieldId);
        const Component = getComponent(field.type);

        if (typeof Component === "undefined") {
          return null;
        }

        return (
          <div key={fieldId}>
            <div className={classes.fieldContainer}>
              <Component
                field={field}
                onChange={onChange}
                locale={locale}
                entityModel={entityModel}
                entityId={entityId}
                copyValues={copyValues}
              />
            </div>
            <Divider key={`divider-${fieldId}`} />
          </div>
        );
      })}
    </div>
  ) : null;
};

const mapStateToProps = (state: RootState, { entityModel }: OwnProps) => ({
  model: getModel(state, entityModel),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  ownProps: OwnProps
) => ({
  onChange: (column: string, value: any, locale?: string): void => {
    dispatch(
      setColumnValue({
        model: ownProps.entityModel,
        id: ownProps.entityId,
        column,
        value,
        locale,
      })
    );
  },
  copyValues: (
    column: string,
    value: any,
    copyFrom: [string, boolean][],
    callback: (copyFromValues: string[]) => string,
    locale?: string
  ): void => {
    dispatch(
      copyValues(
        {
          model: ownProps.entityModel,
          id: ownProps.entityId,
          column,
          value,
          locale,
        },
        copyFrom,
        callback
      )
    );
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const Fields = connector(FieldsRaw);
export default Fields;
