import { ListedImage } from "~/types";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import styles from "./ImageThumb.module.scss";
import { getBadgeColor } from "../../../ImageManagerUtils";

type OwnProps = {
  image: ListedImage;
  setSelectedImage: (id: string) => void;
  setDraft: (image: ListedImage) => void;
};

type Props = OwnProps;

const ImageThumb = ({ image, setSelectedImage, setDraft }: Props) => {
  return (
    <Card className={styles.ctn} variant="outlined">
      <Badge badgeContent=" " color={getBadgeColor(image.status)}>
        <CardActionArea
          onClick={() => {
            setDraft(image);
          }}
          className={styles.actionArea}
        >
          <CardMedia
            className={styles.media}
            component="img"
            alt={image.url.toString()}
            image={image.px_image_url}
          />
        </CardActionArea>
      </Badge>
      <CardActions className={styles.actions}>
        <IconButton
          size="small"
          color="primary"
          onClick={() => {
            setSelectedImage(image.id);
          }}
        >
          <BuildCircleIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default ImageThumb;
