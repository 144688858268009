import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import { selectEntityView } from "~/features/data/dataSlice";
import RecipeView from "~/views/RecipeView/RecipeView";
import HomeAndStoreView from "~/views/HomeAndStoreView/HomeAndStoreView";
import SubhomeAndShowcaseView from "~/views/SubhomeAndShowcaseView/SubhomeAndShowcaseView";
import PostView from "~/views/PostView/PostView";
import {
  EntityView,
  HomeType,
  RecipeType,
  ShowcaseType,
  StoreType,
  SubhomeType,
  PostViewType,
  DictionaryEntryType,
  ContainedBlockType,
  ContainedHomeOrStoreType,
} from "~/types/views";
import DictionaryEntryView from "~/views/DictionaryEntryView/DictionaryEntryView";
import Block from "~/views/shared/Block/Block";

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  entityModel: string;
  entityId: string;
};

type Props = PropsFromRedux & OwnProps;

const ModelViewRaw = ({ entityModel, entityId, entityView }: Props) => {
  return entityView ? getViewForModel(entityModel, entityId, entityView) : null;
};

const getViewForModel = (
  entityModel: string,
  entityId: string,
  entityView: EntityView
) => {
  switch (entityModel) {
    case "recipe":
      return (
        <RecipeView
          entityModel={entityModel}
          entityId={entityId}
          entityView={entityView as RecipeType}
        />
      );

    case "post":
      return (
        <PostView
          entityModel={entityModel}
          entityId={entityId}
          entityView={entityView as PostViewType}
        />
      );

    case "home":
      return (
        <HomeAndStoreView
          entityModel={entityModel}
          entityId={entityId}
          entityView={entityView as HomeType}
        />
      );

    case "store":
      return (
        <HomeAndStoreView
          entityModel={entityModel}
          entityId={entityId}
          entityView={entityView as StoreType}
        />
      );

    case "subhome":
      return (
        <SubhomeAndShowcaseView
          entityModel={entityModel}
          entityId={entityId}
          entityView={entityView as SubhomeType}
        />
      );

    case "showcase":
      return (
        <SubhomeAndShowcaseView
          entityModel={entityModel}
          entityId={entityId}
          entityView={entityView as ShowcaseType}
        />
      );

    case "dictionaryEntry":
      return (
        <DictionaryEntryView
          entityModel={entityModel}
          entityId={entityId}
          entityView={entityView as DictionaryEntryType}
        />
      );

    case "showcaseBlock":
    case "subhomeBlock":
    case "homeBlock":
    case "storeBlock": {
      const containedBlock = entityView as ContainedBlockType;
      return (
        <Block
          entityModel={containedBlock.entityModel}
          entityId={containedBlock.entityId}
          block={containedBlock.content}
        />
      );
    }

    case "homeHighlight": {
      const containedHome = entityView as ContainedHomeOrStoreType;

      return (
        <HomeAndStoreView
          entityModel={containedHome.entityModel}
          entityId={containedHome.entityId}
          entityView={containedHome.content}
        />
      );
    }

    case "storeHighlight": {
      const containedStore = entityView as ContainedHomeOrStoreType;

      return (
        <HomeAndStoreView
          entityModel={containedStore.entityModel}
          entityId={containedStore.entityId}
          entityView={containedStore.content}
        />
      );
    }

    default:
      return null;
  }
};

const mapStateToProps = (
  state: RootState,
  { entityModel, entityId }: OwnProps
) => {
  return {
    entityView: selectEntityView(state, entityModel, entityId),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const ModelView = connector(ModelViewRaw);
export default ModelView;
