import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ImageAssociationSelectField from "./ImageAssociationSelectField/ImageAssociationSelectField";

type OwnProps = {
  clearLoadedFiles: () => void;
  saveImages: () => void;
};

type Props = OwnProps;

const ImageUploaderToolbar = ({ clearLoadedFiles, saveImages }: Props) => {
  return (
    <AppBar position="static" color="secondary">
      <Toolbar disableGutters={true}>
        <Tooltip title="Salvar todas">
          <IconButton
            color="primary"
            onClick={() => {
              saveImages();
            }}
            size="large"
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remover todas / Voltar">
          <IconButton color="primary" onClick={clearLoadedFiles} size="large">
            <DeleteSweepIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <Toolbar>
        <ImageAssociationSelectField />
      </Toolbar>
    </AppBar>
  );
};

export default ImageUploaderToolbar;
