import { connect, ConnectedProps } from "react-redux";
import { RootState } from "~/app/store";
import { PostBlockType } from "~/types/views";
import styles from "./PostBlock.module.scss";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import Button from "@mui/material/Button";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import { pushEntityToDestination } from "~/features/interface/Copy/copySlice";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { isCopyDestinationSet } from "~/features/interface/interfaceSlice";
import RPBlockProducts from "~/views/shared/RPBlockProducts/RPBlockProducts";
import PostBlockComponents from "../PostBlockComponents/PostBlockComponents";

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  postBlock: PostBlockType;
  link: string;
  entityModel: string;
};

type Props = PropsFromRedux & OwnProps;

const PostBlockRaw = ({
  postBlock,
  link,
  entityModel,
  isCopyDestinationSet,
  pushEntityToDestination,
}: Props) => {
  return (
    <div className={styles.ctn}>
      <Link to={`${link}/postBlock/${postBlock.id}`}>
        {postBlock.title ? (
          <div className={styles.header}>
            <h3>{postBlock.title}</h3>
          </div>
        ) : null}
        {postBlock.title_aux ? <h4>{postBlock.title_aux}</h4> : null}
      </Link>
      {!postBlock.title && !postBlock.title_aux ? (
        <Link
          className={styles.editButton}
          to={`${link}/postBlock/${postBlock.id}`}
        >
          <IconButton aria-label="delete" size="small">
            <BuildCircleIcon fontSize="inherit" />
          </IconButton>
        </Link>
      ) : null}

      {isCopyDestinationSet ? (
        <Button
          variant="contained"
          aria-label="fingerprint"
          color="primary"
          sx={{ position: "absolute", top: 5, right: 5, zIndex: 2 }}
          size="small"
          onClick={() => {
            pushEntityToDestination(`${entityModel}Block`, postBlock.id);
          }}
        >
          <AddShoppingCartOutlinedIcon fontSize="inherit" />
        </Button>
      ) : null}

      <PostBlockComponents
        postBlockComponents={postBlock.blockComponents}
        link={`${link}/postBlock/${postBlock.id}`}
      />

      <RPBlockProducts
        blockProducts={postBlock.blockProducts}
        format={postBlock.format}
        link={`${link}/postBlock/${postBlock.id}`}
        modelPrefix={"post"}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isCopyDestinationSet: isCopyDestinationSet(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  pushEntityToDestination: (model: string, id: string) =>
    dispatch(pushEntityToDestination(model, id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const PostBlock = connector(PostBlockRaw);
export default PostBlock;
