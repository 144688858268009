import { Fragment, useEffect } from "react";
import {
  Descendant,
  RPBlockComponentType,
  RecipeImageType,
  RecipeStepType,
  RecipeTextType,
  // RecipeVideoType,
  // RecipeInstagramType,
  // RecipeTwitterType,
} from "~/types/views";
import styles from "./PostBlockComponent.module.scss";
import { getImageUrl } from "~/shared/ImageTools";
// import LiteYouTubeEmbed from "react-lite-youtube-embed";
// import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
// import TweetEmbed from "react-tweet-embed";

type Props = {
  postBlockComponent: RPBlockComponentType;
  link: string;
};

// type InstagramEmbedProps = {
//   data: RecipeInstagramType;
// };

// const InstagramEmbed = ({ data }: InstagramEmbedProps) => {
//   return (
//     <div>
//       <blockquote
//         className="instagram-media"
//         data-instgrm-captioned
//         data-instgrm-permalink={data.embed_ref}
//         data-instgrm-version="14"
//       ></blockquote>
//     </div>
//   );
// };

const PostBlockComponent = ({ postBlockComponent }: Props) => {
  useEffect(() => {
    // if (window.instgrm) {
    // window.instgrm.Embeds.process();
    // }
  }, [postBlockComponent]);

  switch (postBlockComponent.type) {
    case "step":
      return null;

    case "quote": {
      const quote = postBlockComponent.content as RecipeStepType;
      return (
        <blockquote className={styles.blockquote}>
          {parseNodes(quote.text)}
        </blockquote>
      );
    }

    case "text": {
      const recipeText = postBlockComponent.content as RecipeTextType;
      const h4Title = parseNodes(recipeText.title);
      return (
        <div>
          {h4Title.length ? <h4>{h4Title}</h4> : null}
          {parseNodes(recipeText.text)}
        </div>
      );
    }

    case "youtube":
      // const recipeVideo = postBlockComponent.content as RecipeVideoType;
      return (
        <div>
          Video
          {/* <h4>{recipeVideo.title}</h4> */}
          {/* <LiteYouTubeEmbed
            id={recipeVideo.embed_ref}
            title={recipeVideo.embed_title}
          /> */}
          {/* <p>{recipeVideo.video}</p> */}
          {/* <div
            dangerouslySetInnerHTML={{ __html: String(recipeVideo.video) }}
          /> */}
        </div>
      );

    case "twitter":
      // const recipeTwitter = postBlockComponent.content as RecipeTwitterType;
      return (
        <div>
          Tweet
          {/* <TweetEmbed tweetId={recipeTwitter.embed_ref} /> */}
        </div>
      );

    case "instagram":
      // const recipeInstagram = postBlockComponent.content as RecipeInstagramType;
      return (
        <div>
          Instagram
          {/* <InstagramEmbed data={recipeInstagram} /> */}
        </div>
      );

    case "image": {
      const recipeImage = postBlockComponent.content as RecipeImageType;

      if (recipeImage.url !== null) {
        return (
          <a
            href={recipeImage.url}
            target={recipeImage.newTab ? "_blank" : "_self"}
            rel="noreferrer"
          >
            {recipeImage.title !== "" ? <h4>{recipeImage.title}</h4> : null}
            {recipeImage.image ? (
              <img
                src={getImageUrl(`${recipeImage.image.url}`)}
                alt={recipeImage.image.alt}
                width={900}
                height={600}
              />
            ) : null}
          </a>
          // <>
          //   <h4>{title}</h4>
          //   <div dangerouslySetInnerHTML={{ __html: String(video) }} />
          // </>
        );
      }

      return (
        <>
          {recipeImage.title !== "" ? <h4>{recipeImage.title}</h4> : null}
          {recipeImage.image ? (
            <img
              src={getImageUrl(`${recipeImage.image.url}`)}
              alt={recipeImage.image.alt}
              width={900}
              height={600}
            />
          ) : null}
        </>
      );
    }

    default:
      return null;
  }
};

const parseNodes = (nodes: Descendant[]) =>
  nodes.map((node, nodeIndex) => {
    return <Fragment key={nodeIndex}>{getNodeChildren(node)}</Fragment>;
  });

const getNodeChildren = (node: any) => {
  if (typeof node.text !== "undefined") {
    return node.text
      .split("\n")
      .map((text: string, textIndex: number, texts: string[]) => (
        <Fragment key={textIndex}>
          {text}
          {typeof texts[textIndex + 1] !== "undefined" ? <br /> : null}
        </Fragment>
      ));
  }

  const children = node.children.map((n: any, nIndex: number) => (
    <Fragment key={nIndex}>{getNodeChildren(n)}</Fragment>
  ));

  //   return <div key={nodeIndex}>Node!!!</div>;
  switch (node.type) {
    case "span":
      return <>{children}</>;
    case "paragraph":
      return <p>{children}</p>;
    case "link-internal":
      return <a href={node.url}>{children}</a>;
    case "link-external":
      return <a href={node.url}>{children}</a>;
    case "subtitle":
      return <h6>{children}</h6>;
    case "stress":
      return <em>{children}</em>;
    case "strong":
      return <strong>{children}</strong>;
    case "idiomatic":
      return <i>{children}</i>;
    case "attention":
      return <b>{children}</b>;
    case "list-item":
      return <li>{children}</li>;
    case "unordered-list":
      return <ul>{children}</ul>;
    case "ordered-list":
      return <ol>{children}</ol>;
    default:
      return children;
  }
};

export default PostBlockComponent;
