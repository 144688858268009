import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  selectEntitiesFromList,
  selectEntity,
} from "~/features/data/dataSlice";
import { fetchList } from "~/features/data/Lists/listsSlice";
import { pushEntity, fetchEntity } from "~/features/data/Entity/entitiesSlice";
import { getDraft } from "~/features/drafts/draftsSlice";
import { RootState } from "~/app/store";
import SelectedImageCrop from "./SelectedImageCrop/SelectedImageCrop";
import { ImageCrop, ImageType } from "~/types";
import styles from "./SelectedImageDetails.module.scss";

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  model: string;
  parentModel: string;
  parentEntityId: string;
};

type Props = OwnProps & PropsFromRedux;

const SelectedImageDetailsRaw = ({
  imageCrops,
  image,
  model,
  parentModel,
  parentEntityId,
  fetchList,
  fetchEntity,
}: Props) => {
  useEffect(() => {
    fetchEntity({ model: parentModel }, parentEntityId);
  }, [parentModel, parentEntityId, fetchEntity]);

  useEffect(() => {
    fetchList({ model, parentModel, parentEntityId }, undefined, true);
  }, [model, parentModel, parentEntityId, fetchList]);

  return (
    <div className={styles.ctn}>
      {/* <Card className={styles.editContainer}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Editar
          </Typography>
          <FormFields entityModel={"image"} entityId={parentEntityId} />
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => {
              if (typeof draft !== "undefined") {
                pushEntity(
                  {
                    model: parentModel,
                  },
                  parentEntityId,
                  draft
                );
              }
            }}
          >
            Save
          </Button>
        </CardActions>
      </Card> */}

      {typeof image !== "undefined"
        ? imageCrops.map((imageCrop: ImageCrop, index) => (
            <div className={styles.ctnCrop} key={index}>
              <SelectedImageCrop image={image} imageCrop={imageCrop} />
            </div>
          ))
        : null}
    </div>
  );
};

const mapStateToProps = (
  state: RootState,
  { model, parentEntityId, parentModel }: OwnProps
) => {
  // const selectEntitiesList = makeEntitiesListSelector();

  return {
    draft: getDraft(state, parentModel, parentEntityId),
    imageCrops: selectEntitiesFromList(state, {
      model,
      parentModel,
      parentEntityId,
    }) as ImageCrop[],
    image: selectEntity(state, parentModel, parentEntityId) as ImageType,
  };
};

const mapDispatchToProps = {
  fetchList,
  fetchEntity,
  pushEntity,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const SelectedImageDetails = connector(SelectedImageDetailsRaw);
export default SelectedImageDetails;
