import { getSublistNamespace } from "~/features/data/Lists/listsSlice";
import {
  ModelListProps,
  ListedEntity,
  PuxadinhoResponse,
  Entity,
} from "~/types";
import { set, get } from "idb-keyval";

export const getList = async (
  modelList: ModelListProps
): Promise<ListedEntity[] | undefined> => {
  try {
    // const [cachedResult, cachedResultTime] = await getMany([
    //   getSublistNamespace(modelList),
    //   `fetch_time_${getSublistNamespace(modelList)}`,
    // ]);

    const response = await get<ListedEntity[]>(getSublistNamespace(modelList));

    return response;
  } catch (error) {
    throw error;
  }
};

export const setList = async (
  list: PuxadinhoResponse<Entity[]>,
  modelList: ModelListProps
): Promise<void> => {
  try {
    // const [cachedResult, cachedResultTime] = await getMany([
    //   getSublistNamespace(modelList),
    //   `fetch_time_${getSublistNamespace(modelList)}`,
    // ]);

    set(getSublistNamespace(modelList), list);

    //   return response;
  } catch (error) {
    throw error;
  }
};
